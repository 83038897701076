.catalog-category-view,
.catalogsearch-result-index,
.ambrand-index-index {
    .amshopby-overlay-block {
        & > .amshopby-loader {
            background: url('../images/loader.gif') center / 100px no-repeat;
        }
    }

    .amshopby-descr {
        .header-banner {
            position: relative;

            .overlay {
                position: absolute;
                bottom: 0;
                height: 100%;
                width: 100%;
                background: linear-gradient(
                        to bottom,
                        transparent 0%,
                        transparent 55%,
                        $very-dark-1 100%
                );

                .title {
                    position: absolute;
                    bottom: 0;
                    display: flex;
                    flex: 1;
                    align-items: center;
                    padding: 1.5rem;

                    & * {
                        @include pink-hover-shadow;

                        &.text {
                            margin-left: 1rem;
                            letter-spacing: 0.48px;
                            font-size: 3rem;
                            line-height: 2rem;
                            color: $white;
                        }
                    }

                    & > img {
                        width: 45px;
                    }
                }
            }
        }

        & > *:not(.header-banner) {
            padding: 15px;
            margin-top: 1.5rem;
            line-height: 1.5rem;
            font-size: 1.3rem;

            &:last-child {
                margin-bottom: 1.5rem;
            }
        }
    }

    input[type='checkbox'] {
        &:checked + label {
            &::before {
                color: $turquoise !important;
            }
        }
    }

    .filter-label {
        letter-spacing: 0.36px;
        color: $light;
        font-weight: 300;
        opacity: 1;
    }

    .page.messages {
        margin: 0 1rem;
    }

    .columns {
        align-items: center;
    }

    .columns .column.main {
        width: 100%;
        padding: 15px;

        // Page title display (ex: Playstation)
        .page-title-wrapper {
            display: flex;
            margin-top: 0;
            color: $light;
            letter-spacing: 0.48px;
            line-height: 2rem;
            text-transform: capitalize;
            font-size: 2rem;
            font-weight: 400;
            align-items: center;

            .category-image {
                margin-left: 0.5rem;
                margin-top: 0;
            }
        }

        // Pagination styles
        .toolbar.toolbar-products {
            text-align: center;
            display: block !important;

            .pages {
                ul.pages-items {
                    li {
                        a {
                            color: $grey;

                            &:active,
                            &:focus {
                                color: $grey;
                            }
                        }

                        &.current {
                            strong {
                                color: $cyan;
                            }
                        }

                        &.pages-item-previous,
                        &.pages-item-next {
                            a {
                                border: none;

                                &:before {
                                    color: $pink;
                                }
                            }
                        }
                    }
                }
            }
        }

        .message {
            margin-top: 1rem;
        }

        .amscroll-load-button {
            border: none;
            background: $very-dark-1;
            padding: 20px;
            float: right;
            display: block !important;

            &[amscroll_type='after'] {
                span {
                    color: $light !important;
                    pointer-events: none;

                    &::after {
                        @include font-awesome;
                        content: '\f078';
                        margin-left: 0.5rem;
                        color: $turquoise;
                    }
                }
            }

            svg {
                display: none;
            }

            &[amscroll_type='before'] {
                span {
                    &::after {
                        @include font-awesome;
                        content: '\f077';
                        margin-left: 0.5rem;
                        color: $pink;
                    }
                }
            }

            &:first-of-type {
                position: absolute;
                right: 0;
            }
        }

        #amasty-shopby-product-list {
            margin-top: 90px;

            &.opacity-overlay {
                opacity: 0.1;
            }

            .less_products {
                .amscroll-load-button {
                    margin-top: -3.5rem;
                }
            }

            .amscroll-top {
                float: right;
                display: none;
                font-size: 15px !important;
            }

            .toolbar-amount {
                display: none;
            }

            .product-items {
                justify-content: space-between;

                li.product-item {
                    @media (min-width: 576px) {
                        max-width: 48%;
                    }
                }

                .product-item {
                    margin-bottom: 1rem;

                    .product-item-info .product-item-inner .product-item-actions .actions-primary {
                        text-align: end;

                        button {
                            height: 2.5rem;
                            width: 148px;
                            padding: 0 0.5rem;
                        }

                        .stock.unavailable {
                            font-size: 1rem;
                            height: 100%;
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                        }
                    }
                }
            }

            .product-item-name {
                margin-top: 0.5rem;
            }

            .toolbar-sorter {
                // removed important
                display: none;
            }
        }

        .sub-search-for-content {
            display: none;
        }

        .bottomBar {
            width: max-content;
        }
    }

    .breadcrumbs {
        width: 100%;
        padding: 18px 0 18px 1rem;

        .item a,
        .item strong {
            font-size: $normal-font-size;
        }
    }

    .sidebar.sidebar-main {
        position: absolute;
        top: 100px;
        z-index: 100;
        width: 100%;
        padding: 0 15px;

        .block.filter {
            // Filter actions
            .block-title.filter-title {

                .block-actions {
                    display: flex;
                }

                // Filter by dropdown
                .action.filter {
                    flex: 0 1 auto;
                    color: $light;
                    font-size: $normal-font-size;
                    font-weight: 400;
                    letter-spacing: .36px;
                    cursor: pointer;

                    span {
                        white-space: nowrap;
                    }

                    i::before {
                        margin-left: 0.5rem;
                        font-size: 13px;
                        color: $turquoise;
                    }
                }

                // Action hidden on mobile
                .action.clear {
                    display: none;
                }

                // Sort by dropdown
                .action.sort {
                    flex: 1 0 auto;

                    .toolbar-sorter {
                        display: flex;
                        padding-left: 20px;
                        margin-top: 1px; // align dropdowns

                        .sorterby {
                            display: flex;
                            margin-top: -0.75rem;
                        }

                        .sorter-label {
                            margin-right: 0.25rem;
                            white-space: nowrap;
                            font-size: $normal-font-size;
                            margin-right: 6px;
                            line-height: 1.25rem;
                        }

                        i:before {
                            margin-left: 1rem;
                            color: $turquoise;
                        }

                        .select2-container {
                            margin-top: 0.25rem;

                            .select2-selection__rendered {
                                padding-right: 4px;
                                font-size: $normal-font-size;
                            }
                        }
                    }
                }
            }

            // Filter options wrapper
            .block-content.filter-content {
                display: none;
                @include background-color;
                margin: 0 -15px;
                padding: 15px;
                box-shadow: 0 10px 12px -10px $black;
                border-bottom: 3px solid $dark-1;

                .amshopby-button-wrap {
                    flex: 1 0 100%;
                }

                // Show more link
                .am-show-more {
                    padding-left: 0;
                    margin: 12px 0;
                    color: $light;
                    width: 100%;
                    font-size: $normal-font-size;

                    &:active,
                    &:focus,
                    &:hover {
                        text-decoration: none;
                    }

                    span {
                        opacity: 1;
                        color: $turquoise;
                    }

                    &:before {
                        @include font-awesome;
                        background: unset;
                        color: $turquoise;
                        content: '\f078';
                    }
                }

                // Clear all filters button
                .action.clear {
                    display: flex;
                    align-items: center;
                    color: $pink;
                    font-size: $normal-font-size;
                    line-height: $normal-font-size;

                    span {
                        font-family: $font-medium;
                        font-size: $normal-font-size;
                    }

                    i:before {
                        margin-left: 5px;
                        background-image: linear-gradient(90deg, $pink 0%, $pink 48%, $purple-light 100);
                        color: transparent;
                        -webkit-background-clip: text;
                    }
                }

                // Active filters badges
                .filter-result {
                    .amshopby-items {
                        display: flex;
                        flex-wrap: wrap;
                        padding: 0;
                        gap: 10px 5px;
                    }

                    .amshopby-item {
                        width: fit-content;
                        padding: 5px;
                        margin: 0;
                        border: $light 1px solid;
                        color: $light;
                    }

                    .amshopby-remove:before,
                    .amshopby-remove:after {
                        background-color: $light;
                    }

                    .amshopby-filter-name {
                        margin-left: 1rem;
                    }

                    .amshopby-remove,
                    .amshopby-filter-value {
                        margin-left: 0.25rem;
                    }
                }

                // Shopping options text
                .block-subtitle.filter-subtitle {
                    display: none;
                }

                .filter-options {

                    // Filter options section title
                    .filter-options-title {
                        color: $grey;
                        font-family: $font-light;
                        font-size: $normal-font-size;
                        margin: 24px 0 8px 0;
                        font-weight: 400;
                        letter-spacing: 0.36px;
                        pointer-events: none;
                    }

                    .filter-options-content {
                        margin-top: 12px;

                        // Filter search input
                        amshopby-search-box {
                            margin-bottom: 20px;
                        }

                        // Filter options
                        .items:not(.am-filter-items-price) {
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                            gap: 16px 0;
                        }

                        // Filter option wrapper
                        .item {
                            padding: 0;
                            color: $light;
                            font-family: $font-light;
                            font-size: $normal-font-size;
                        }

                        // Filter option label
                        .item .label {
                            &:hover {
                                text-shadow: 0px 0px 8px $cyan-turquoise;
                            }
                        }

                        // Filter option checkbox
                        .item .label::before {
                            margin-right: 5px;
                            font-size: 1.2rem;
                        }

                        // Filter option slider input
                        .amshopby-slider-wrapper {
                            .amshopby-slider-summary {
                                color: $grey-light;
                                font-size: $normal-font-size;
                                font-weight: 400;

                                .pink {
                                    color: $pink;
                                }

                                .turquoise {
                                    color: $turquoise;
                                }
                            }

                            .amshopby-slider-display {
                                display: none;
                            }

                            .ui-slider-horizontal {
                                height: 5px;
                                margin: 0;
                                background: $grey-dark;

                                &::before,
                                &::after {
                                    height: 0;
                                }

                                .ui-slider-handle {
                                    top: -5px;
                                    width: 5px;
                                    height: 15px;
                                    padding: 0;
                                    margin-left: 0;
                                    border: none;
                                    border-radius: unset;
                                    transform: none;

                                    .amshopby-slider-tooltip {
                                        bottom: -1.5rem;
                                        visibility: hidden;
                                        border: none;
                                        opacity: 0;
                                        font-size: 0.9rem;
                                        font-weight: 700;
                                        letter-spacing: 0.24px;

                                        &::before,
                                        &::after {
                                            content: none;
                                        }
                                    }

                                    &:nth-of-type(1) {
                                        z-index: 30;
                                        background: $turquoise;

                                        .amshopby-slider-tooltip {
                                            color: $turquoise !important;
                                        }
                                    }

                                    &:nth-of-type(2) {
                                        background: $pink;

                                        .amshopby-slider-tooltip {
                                            color: $pink !important;
                                        }
                                    }
                                }

                                .ui-slider-range {
                                    position: absolute;
                                    height: 5px !important;
                                    background: transparent linear-gradient(
                                            90deg,
                                            $blue 0%,
                                            $blue 10%,
                                            $purple-dark 30%,
                                            $pink 100%
                                    ) 0 0 no-repeat padding-box !important;
                                }
                            }
                        }
                    }
                }
            }

            &.open .block-title.filter-title .action.filter i::before {
                transform: rotate(-180deg);
            }
        }

        .am-filter-items-attr_price {
            margin-bottom: 2rem;
        }
    }
}

// Relative to result index page
.catalogsearch-result-index {
    .sidebar.sidebar-main {
        top: 50px;
    }

    .column.main {
        .page-title-wrapper {
            line-height: 2rem;
        }
    }
}

.amshopby-overlay-block {
    background: rgba(27, 27, 28, 0.75);
    z-index: 20 !important;
}

.ambrand-index-index {
    .column.main {
        .category-image {
            max-width: 50px;
        }
    }
}

body[class*='categorypath-hot-deals'] .filter .filter-content .filter-options {
    dt[data-type='stock'] {
        & + dd {
            display: none;
        }

        display: none;
    }
}

.cms-page-view {
    .breadcrumbs {
        margin: 20px;
    }
    .page-title-wrapper {
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 1rem;

        .page-title {
            color: $white;
            font-size: 1.5rem;
            font-weight: 600;
            margin: 1rem 0;
        }
    }
    .page-main .column.main {
        padding: 0 20px;

        h1 {
            color: $pink;
            font-size: 1.2rem;
            font-weight: 600;
            margin: 1rem 0;
            text-transform: uppercase;
        }
        h2 {
            color: $turquoise;
            font-size: 1.1rem;
        }
        p {
            font-size: 1.2rem;
            color: $light;
            line-height: 1.7rem;
            margin: 0.5rem 0;
        }
        ul,
        li {
            font-size: 1rem;
            color: $light;
            list-style: inherit;
            margin-left: 1rem;
            margin-top: 0.25rem;
        }
    }
}

.cms-no-route {
    @media (max-width: 992px) {
        .page-main {
            padding: 15px !important;
        }
    }
    .page-title {
        margin-top: 5rem;
        font-size: 2.5rem;
        color: $pink;
    }
    .main {
        color: $light;
        line-height: 1rem;
        dt {
            margin-bottom: 1rem;
            margin-top: 2rem;
        }
        dd {
            margin-bottom: 0.25rem;
            margin-top: 0.25rem;
        }
        a {
            color: $turquoise;
            font-size: 1rem;
            font-weight: 600;
        }
        ul > li,
        ol > li {
            margin-top: 0.5rem;
        }
    }
}

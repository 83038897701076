.block.newsletter {
    @include pink-dark-ellipse-gradient();
    height: 280px;
    padding-top: 50px;
    opacity: 0.9;

    .title {
        width: 80%;
        margin: 0 auto 12px;
        color: $light;
        letter-spacing: 0.36px;
    }

    .title-text {
        margin-bottom: 6px;
        font-size: 14px;
    }

    .subtitle-text {
        font-size: 12px;
    }

    .field-wrapper {
        margin: 0 auto;
        text-align: center;
        input[type='email'] {
            width: 80%;
            height: 40px;
            font-size: 14px;
            @include pink-purple-border-gradient-tlbr;

            &:hover {
                box-shadow: 0px 0px 4px $pink;
                -moz-box-shadow: 0px 0px 4px $pink;
                -webkit-box-shadow: 0px 0px 4px $pink;
            }
        }
        .newsletter-button {
            height: 40px;
            min-width: 136px;
            margin-top: 20px;
            box-shadow: 0 0 4px 0 $black;
            font-size: 16px;
        }
    }

    .follow-us {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 25px;
        p {
            opacity: 1;
            color: $light;
            font-size: 1rem;
        }
        .fa-instagram,
        .fa-facebook-f {
            display: block;
            margin-left: 1rem;
            &:before {
                display: block;
                font-size: 20px;
            }
        }
    }
}

@mixin google-font($font-name, $font-weights) {
    $first-wf: true;
    $url: 'https://fonts.googleapis.com/css?family=#{$font-name}';

    @each $font-weight in $font-weights {
        @if $first-wf {
            $first-wf: false;
            $url: $url + ':#{$font-weight}';
        } @else {
            $url: $url + ',#{$font-weight}';
        }
    }

    @import url($url+'&display=swap');
}
@include google-font('Roboto', [ '300', '400', '500', '700' ]);

@font-face {
    font-family: $fa;
    src: url('../fonts/icomoon.woff2') format('woff2'),
        url('../fonts/icomoon.eot') format('embedded-opentype'),
        url('../fonts/icomoon.ttf') format('truetype'), url('../fonts/icomoon.woff') format('woff');
}

@font-face {
    font-family: 'Roboto Condensed Bold';
    src: url('../fonts/RobotoCondensed-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: block;
    font-variant: normal;
}

@font-face {
    font-family: 'Roboto Condensed Bold Italic';
    src: url('../fonts/RobotoCondensed-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
    font-display: block;
    font-variant: normal;
}

@font-face {
    font-family: 'Roboto Condensed Italic';
    src: url('../fonts/RobotoCondensed-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
    font-display: block;
    font-variant: normal;
}

@font-face {
    font-family: 'Roboto Condensed Light';
    src: url('../fonts/RobotoCondensed-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: block;
    font-variant: normal;
}

@font-face {
    font-family: 'Roboto Condensed Light Italic';
    src: url('../fonts/RobotoCondensed-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: block;
    font-variant: normal;
}

@font-face {
    font-family: 'Roboto Condensed Regular';
    src: url('../fonts/RobotoCondensed-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: block;
    font-variant: normal;
}

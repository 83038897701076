.product-item-small {
    position: relative;
    @include border-block;
    .product-item-info {
        @include product-item-small;
    }
    .product-page-button {
        position: relative;
        display: none;
    }

    //Hover Action buttons

    &.hover-actions {
        .product-item-info .product-item-inner,
        .product-item-info .wishlist-button {
            display: none;
        }

        .product-item-info {
            .product.details {
                .price-cart-button {
                    .product-item-inner {
                        .product-item-actions {
                            .actions-primary {
                                .stock.unavailable.product-link-btn {
                                    display: none;
                                }
                                .stock.unavailable.disabled-btn {
                                    display: block;
                                    & button {
                                        background: $grey-dark;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        @media (hover: hover) {
            &:hover {
                .product-item-info .product-item-inner-small,
                .product-item-info .wishlist-button,
                .product-page-button {
                    display: block;
                    position: absolute;
                    z-index: 100;
                    top : calc( 85px - 30px ) // hauteur de l'image .product-item-photo - hauteur du bloc
                }

                .product.details
                    .price-cart-button
                    .product-item-inner
                    .product-item-actions
                    .actions-primary
                    button,
                .wishlist-button a.towishlist,
                .product-page-button {
                    height: 30px;
                    width: 30px;
                    @media (hover: hover) {
                        &:hover {
                            background: $very-dark-2;
                        }
                    }
                }

                .product-item-info .product.details,
                .product-item-info .product.details .price-cart-button {
                    position: initial;
                }

                .product.details .price-cart-button .product-item-inner {
                    left: 0;

                    button {
                        background: $pink;

                        & > span {
                            display: none;
                        }
                    }

                    button::before {
                        content: '\f290';
                        @include font-awesome;
                        @include absolute-centring;
                        font-size: 1rem;
                    }
                }

                .wishlist-button {
                    left: 30px;

                    a.towishlist {
                        @include purple-pink-gradient;
                    }

                    a.towishlist > i:before {
                        fill: $white;
                        margin: 0;
                        @include absolute-centring;
                    }
                }

                .product-page-button {
                    left: 60px;
                    @include cyan-blue-gradient-317deg;

                    a.product-link {
                        @include absolute-centring;
                        color: $white;
                        font-size: 1rem;
                    }
                }

                .product-item-photo {
                    opacity: 0.7;
                }
            }
        }
    }
}

//Page Checkout/cart

.checkout-cart-index {
    .page.messages {
        margin: 0px 2rem;
    }
    .page-main {
        .breadcrumbs {
            margin-left: 42px;
        }
        .page-title-wrapper {
            height: 3rem;
            display: flex;
            margin-top: 2rem;
            padding-bottom: 2rem;
            margin-bottom: 1rem;
            padding-left: 30px;
            font-size: 1.8rem;
            letter-spacing: 0.4px;
            line-height: 16px;
            &:after {
                @include font-awesome;
                content: '\f290';
                font-weight: 300;
                margin-left: 0.5rem;
                color: $turquoise;
                font-size: 1.6rem;
            }
        }
        .shipping-message {
            margin-top: 1.25rem;
            background: $turquoise-very-dark;
            .message-info {
                color: $turquoise;
                &:before {
                    content: '\f05a';
                    @include turquoise-blue-gradient;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    border-right: 1px solid $turquoise;
                }
            }
        }
        .main {
            .message.info {
                display: flex;
                .cart-empty {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    align-items: center;
                    @media (min-width: 768px) {
                        flex-direction: row;
                        .end-text {
                            display: flex;
                            align-items: center;
                            padding: 0px;
                        }
                    }
                    .message-text {
                        padding-left: 1rem;
                    }
                    .logo-gameo {
                        height: 2rem;
                        padding-left: 0.25rem;
                        padding-right: 0.25rem;
                    }
                    .second-row {
                        display: flex;
                    }
                }
            }
            .cart-container {
                .cart-summary {
                    padding-left: 2rem;
                    background: $very-dark-1;
                    border-left: solid $light-dark 1px;
                    .summary {
                        &.title {
                            display: none;
                        }
                    }
                    .shipping-message .text {
                        padding-left: 5px !important;
                    }
                    #block-shipping {
                        .shipping-main-title {
                            padding: 0;
                            font-size: 1.3rem;
                            color: $light;
                            letter-spacing: 0.28px;
                            line-height: 16px;
                            &:after {
                                top: -3px;
                                color: $turquoise;
                                font-size: 2.5rem;
                                position: relative;
                            }
                        }
                        &.active {
                            .shipping-main-title {
                                &:after {
                                    top: -3px;
                                    color: $pink;
                                    font-size: 2.5rem;
                                }
                            }
                        }
                        #block-summary {
                            .title {
                                &:after {
                                    color: $turquoise;
                                }
                            }
                            &:active {
                                .title {
                                    &:after {
                                        color: $pink;
                                    }
                                }
                            }
                        }
                    }
                    .content {
                        .delimiter {
                            border-top: $light-dark 1px solid;
                        }
                        #discount-coupon-form {
                            .field {
                                margin: 0.5rem 0;
                                display: flex;
                                justify-content: space-between;
                            }
                            .control {
                                width: 55%;
                                margin-right: 4px;
                                input {
                                    font-size: 1rem;
                                }
                            }
                            .btn-discount {
                                margin-left: 4px;
                                white-space: nowrap;
                            }
                        }
                        #co-shipping-method-form {
                            .item-title {
                                margin: 0 0 5px;
                            }
                            .field.choice.item {
                                margin: 0 0 16px;
                            }
                            input[type='checkbox']:checked + label:before,
                            input[type='radio']:checked + label:before {
                                margin-right: 8px;
                            }
                        }
                        #shipping-zip-form {
                            .field[name='shippingAddress.region'] {
                                div {
                                    input {
                                        margin-top: 10px;
                                    }
                                }
                            }
                            .message {
                                background: transparent;
                                box-shadow: none;
                            }
                            label {
                                color: $light;
                                font-size: 1.1rem;
                                letter-spacing: 0.28px;
                                line-height: 26px;
                                font-weight: 400;
                            }
                            input {
                                font-size: 1rem;
                            }
                            .select {
                                border-top: 0.5px solid $black;
                                border-left: 0.5px solid $black;
                                border-right: 0.5px solid $black;
                                border-bottom: none;
                                color: $grey;
                                letter-spacing: 0.36px;
                                opacity: 1;
                                width: 100%;
                                font-size: 1rem;
                                background: linear-gradient(
                                        to right,
                                        $pink 0%,
                                        #8b00e9 64%,
                                        $purple 100%
                                    )
                                    left bottom $very-dark-2 no-repeat;
                                background-size: 100% 1px;
                            }
                            .estimate {
                                .field:last-child {
                                    label {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                    .cart-totals {
                        .table-wrapper {
                            .totals {
                                &.shipping {
                                    &.incl {
                                    }
                                }
                            }
                            .totals-tax {
                            }
                        }
                        .price {
                            color: $light;
                        }
                    }
                }
                .cart-item-wrapper {
                    .shipping-message {
                        display: none;
                    }
                    .cart-items {
                        @media (min-width: 768px) and (max-width: 991px) {
                            display: flex;
                            flex-wrap: wrap;
                        }
                    }
                    .item-info {
                        display: flex;
                        margin-bottom: 2rem;
                        width: 100%;
                        position: relative;
                        @include border-block;
                        .item {
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            .product-image-container {
                                .product-image-wrapper {
                                    padding-bottom: 0 !important;
                                    width: 100%;
                                    height: 150px;
                                    img {
                                        height: 100%;
                                        width: 100%;
                                        object-fit: cover;
                                    }
                                }
                            }
                            .product-item-details {
                                position: relative;
                                overflow: hidden;
                                width: 100%;
                                padding: 1rem;
                                .product-item-name {
                                    padding: 0;
                                    display: inline-block;
                                    max-width: 100%;
                                    margin: 0 3px 0 0;
                                    a {
                                        font-size: 1rem;
                                    }
                                }
                                .platforms-icons {
                                    display: inline-block;
                                    margin: 0;
                                    color: $grey;
                                    .platforms-icon {
                                        i {
                                            font-size: 1rem;
                                            line-height: 1rem;
                                        }
                                    }
                                }
                                .cart.item.message.error {
                                    margin-bottom: 0;
                                    margin-top: 0.5rem;
                                    margin-left: 0.5rem;
                                    background: $very-dark-2;
                                    z-index: 30;
                                    color: $pink;
                                    &:first-child {
                                        display: none;
                                    }
                                }
                                .item-cart-actions {
                                    display: flex;
                                    flex-direction: column;
                                    width: 100%;
                                    justify-content: flex-end;
                                    padding: 0.5rem;
                                    .price-div {
                                        .cart-price,
                                        .old-price {
                                            display: flex;
                                            align-items: center;
                                            margin-right: 0.5rem;
                                        }
                                    }
                                    .item-cart-action-options {
                                        margin-top: 1rem;
                                        display: flex;
                                        justify-content: flex-end;
                                        .qty {
                                            display: flex;
                                            width: 100%;
                                            label {
                                                display: flex;
                                                text-transform: uppercase;
                                                font-size: 1.3rem;
                                                span {
                                                    margin-top: 0.75rem;
                                                    color: $light;
                                                }
                                                input {
                                                    margin-left: 0.5rem;
                                                    padding: 0.75rem;
                                                    text-align: center;
                                                    width: 3.5rem;
                                                    height: 3rem;
                                                    font-size: 1.1rem;
                                                }
                                                input::-webkit-outer-spin-button,
                                                input::-webkit-inner-spin-button {
                                                    -webkit-appearance: none;
                                                }
                                                input[type='number'] {
                                                    -moz-appearance: textfield;
                                                }
                                            }
                                            .actions {
                                                width: 100%;
                                                .tocart {
                                                    height: 3rem;
                                                    padding: 0.75rem 2.5rem;
                                                    @media (min-width: 768px) {
                                                        padding: 0.75rem 1rem;
                                                        margin-left: 0.5rem;
                                                        height: 2.5rem;
                                                    }
                                                }
                                            }
                                        }
                                        .item-actions {
                                            .action-edit {
                                                display: none;
                                            }
                                            .action-delete {
                                                margin-left: 0.5rem;
                                                a {
                                                    height: 3rem;
                                                    width: 3rem;
                                                    i:before {
                                                        margin: 0.85rem 0;
                                                    }
                                                }
                                            }
                                            .actions-toolbar {
                                                display: flex;
                                                .towishlist {
                                                    display: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .action.primary.checkout {
                        width: -webkit-fill-available;
                        margin: 0 15px;
                    }
                }
            }
            .block.crosssell {
                padding: 15px;
                @media (max-width: 769px) {
                    .block-title {
                        text-align: center;
                        #block-crosssell-heading {
                            font-size: 1.5rem;
                        }
                    }
                }
                .product-item {
                    padding: 15px;
                    .product-item-photo {
                        position: relative;

                        .image-ribbon {
                            margin: 0px;
                        }
                    }
                    .price-wrapper {
                        .price {
                            font-size: 1.8rem;
                            font-weight: 500;
                        }
                    }
                    .product-item-actions {
                        .actions-primary {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .stock.unavailable {
                                font-size: 1.4rem;
                                height: 100%;
                                display: flex;
                                justify-content: flex-end;
                                align-items: center;
                                @media (min-width: 768px) {
                                    font-size: 1rem;
                                }
                            }
                        }
                    }
                    .action-primary,
                    .primary.action {
                        height: 3.5rem;
                        font-size: 1.1rem;
                    }
                }
            }
        }
    }
}

// Minicart

.block-minicart {
    position: absolute;
    background-color: $very-dark-1;
    box-shadow: 0 24px 12px -24px $black;
    min-width: 100%;
    padding: 0 0 3px 0;
    margin-top: 15px;
    left: 50%;
    border-top: 1px solid transparent;
    width: auto;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);

    .block-content {
        padding: 51px 24px 0 !important;
        background-color: $very-dark-1;
        border-bottom: 3px solid $dark-1;
        #btn-minicart-close {
            margin: 10px;
            &:before {
                content: '';
            }
            i:before {
                font-size: 1.4rem;
                background: transparent
                    linear-gradient(90deg, #ff009c 0%, #ff009c 48%, #9700e2 100%) 0% 0% no-repeat
                    padding-box;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            &:hover {
                cursor: pointer;
            }
        }
        .subtotal {
            font-size: 1.1rem;
            color: $light;
            .price-container {
                margin-top: 0.25rem;
            }
            .price-wrapper {
                .price {
                    font-weight: 300;
                }
            }
        }
        .items-total {
            width: 50%;
            color: $light;
            font-size: 1.1rem;
            .count {
                font-weight: 300;
            }
        }
        .minicart-items-wrapper {
            height: auto !important;
            min-height: 276px !important;
            max-height: 51vh;
            letter-spacing: 0.6px;
            padding: 0 15px;
            .minicart-items {
                display: flex;
                flex-flow: wrap;
                gap: 18px;
                max-width: 100%;

                > li {
                    width: 100%;
                }
            }
            .product-item-small {
                @extend .product-item-small;
                .product-image-photo {
                    aspect-ratio: 37/21;
                }

                .product-price {
                    .price-container {
                        .price-including-tax {
                            display: flex;
                            flex-flow: wrap;
                            justify-content: initial;
                            margin: 0px;
                            .price,
                            .percent {
                                font-size: 21px;
                                font-family: $font-regular;
                                font-weight: normal;
                                line-height: initial;
                            }
                            .minicart-promo-percent {
                                padding: 0px;
                                &:after,
                                &:before {
                                    content: initial;
                                }
                                .percent {
                                    color: #ff009c;
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                }
                .second-line-product {
                    .product-item-pricing {
                        padding-top: 0.75rem;
                        padding-bottom: 0.75rem;
                        border-top: 3px solid $dark-1;

                        .details-qty {
                            text-align: center;
                            .label {
                                font-size: 15px;
                                text-transform: uppercase;
                                letter-spacing: 0.33px;
                            }
                        }
                        .cart-item-qty {
                            -moz-appearance: textfield;
                            width: 40px;
                            padding: 12px 0;
                            font-size: 15px;
                            margin-left: 3px;
                            &::-webkit-inner-spin-button {
                                -webkit-appearance: none;
                            }
                            &::-o-inner-spin-button {
                                -o-appearance: none;
                            }
                        }
                        .update-cart-item {
                            font-size: 15px;
                            padding: 12px 18px;
                            letter-spacing: 0.5px;
                            margin-left: 1rem;
                        }
                        .btn-round {
                            height: 43px;
                            width: 43px;
                            padding: 6px;
                            .delete::before {
                                padding: 0.15rem 0.5rem;
                                @media (min-width: 768px) {
                                    padding: 0.45rem 0.5rem;
                                }
                            }
                        }
                    }
                }
            }
        }
        .actions {
            &.view-cart {
                margin-top: 4px;
                padding-top: 28px;
                margin-bottom: 10px;
                border-top: 1px solid $light-dark;
            }
            .btn-cart {
                margin-bottom: 0.5rem;
                width: 100%;
                padding: 0;
                a {
                    padding: 0.75rem 2.5rem;
                    color: $turquoise;
                    font-size: 0.95rem;
                    width: 100%;
                    display: inline-block;
                    span {
                        &:after {
                            @include font-awesome;
                            content: '\f290';
                            font-weight: 300;
                            margin-left: 0.5rem;
                        }
                    }
                }
                i:before {
                    margin: 0 0.5rem;
                }
            }
            .checkout {
                width: 100%;
            }
        }
        .secondary {
            margin-left: 1rem;
            .delete::before {
                @include font-awesome;
                padding: 0.35rem 0.5rem;
                font-weight: 300;
                font-size: 15px;
                content: '\f1f8';
                color: $pink;
            }
        }
    }
}

.minicart-items-wrapper::-webkit-scrollbar {
    width: 6px;
    top: 2px;
}

.minicart-items-wrapper::-webkit-scrollbar-track {
    background: $very-dark-2;
}

.minicart-items-wrapper::-webkit-scrollbar-thumb {
    background-color: $grey-dark;
    border-radius: 20px; /* roundness of the scroll thumb */
}

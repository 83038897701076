.video-thumbnail {
    object-fit: cover;
}

.splide {
    visibility: visible;
    &__track {
        position: relative;
        &--nav {
            & > .splide__list {
                & > .splide__slide {
                    border: none;
                    &.is-active {
                        border: none;
                    }
                }
            }
        }
    }

    &__progress {
        background-color: $dark-2;
        display: none;

        &__bar {
            @include pink-blue-green-purple-gradient();
        }
    }

    &__arrow {
        opacity: 1;

        &:disabled {
            pointer-events: none;
            opacity: 0;
        }

        &--prev,
        &--next {
            @include transition(opacity 100ms linear);
        }
    }

    &__slide {
        &--end {
            width: 100%;
            height: 100%;
            -webkit-box-shadow: 0px 0px 12px #000;
            box-shadow: 0px 0px 12px #000;
            @include pink-purple-gradient-44deg;
            span {
                @include absolute-vertical-centring;
                font-size: 1.5rem;
                line-height: 2rem;
                color: $light;
                letter-spacing: 1.25px;
                width: 100%;
                text-align: center;
                padding: 0 30px;
                display: block;
            }
        }
    }

    &__thumb {
        .splide__slide {
            display: block;
            transition: opacity 0.2s ease;
            width: 100%;
            height: auto;
            max-height: 100px;
            overflow: hidden;
            opacity: 0.3;
            cursor: pointer;
            img {
                width: 100%;
                height: auto;
                display: block;
                aspect-ratio: 16/9;
            }
            &.is-active {
                opacity: 1;
            }
        }
    }

    &__pagination {
        margin: 2rem 0 2rem 0;
        position: relative;
        bottom: unset;
        text-align: center;
        line-height: 0;

        &__page {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: transparent;
            margin: 0 0.3rem;
            border: 1px solid;
            @include transition(opacity 100ms linear, background 100ms linear);

            &.is-active {
                background: $light;
                transform: scale(1);
            }
        }
    }

    &.is-initialized {
        .slider-products-preloader {
            display: none;
        }
    }
}

.slider-products-preloader {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 100;
    background-color: $very-dark-1;

    & > img {
        @include absolute-centring;
        width: 50px;
        height: 50px;
    }
}

.swiper-bullets {
    margin: 1rem 0 2rem 0;
    min-width: calc(100% - 20px);
    height: 10px;
    overflow: hidden;
    position: relative;
    text-align: center;
    .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $light-dark;
        opacity: 0;
        margin: 0 0.3rem;
        @include transition(opacity 100ms linear, background 100ms linear);
        left: 0 !important;
        position: absolute;
        &.swiper-pagination-bullet-active {
            background: $light;
        }
        &.swiper-pagination-bullet-active-prev-prev,
        &.swiper-pagination-bullet-active-prev,
        &.swiper-pagination-bullet-active-main,
        &.swiper-pagination-bullet-active-next,
        &.swiper-pagination-bullet-active-next-next {
            opacity: 1;
            position: relative;
        }
    }
}

body.amasty_affiliate-account-transaction {
    div.affiliate-price-field {
        @include input-text-disabled;
    }

    .table-wrapper {
        margin-top: 40px;
        tr {
            th:nth-child(1),
            td:nth-child(1) {
                max-width: 25%;
            }

            th:nth-child(2),
            td:nth-child(2) {
                max-width: 25%;
            }

            th:nth-child(3),
            td:nth-child(3) {
                max-width: 25%;
            }
        }
    }

    .chart {
        background: #dfded9;
        margin-top: 40px;
    }
}

body.amasty_affiliate-account-withdrawal {
    div.affiliate-price-field {
        @include input-text-disabled;
    }
}

body.amasty_affiliate-account-promo {
    span#affiliate-link {
        display: block;
        height: 60px;
        margin-right: 150px;
    }
}

body.amasty_affiliate-account-program {
    .table-order-items.history {
        tr {
            th:nth-child(1),
            td:nth-child(1) {
                max-width: 25%;
            }

            th:nth-child(2),
            td:nth-child(2) {
                max-width: 25%;
            }

            th:nth-child(3),
            td:nth-child(3) {
                max-width: 25%;
            }
        }
    }
}

body.account {
    .input-text:not(#search):not(#mobile-search):not(.qty) {
        @include input-text-normal;
    }
    .page.messages {
        margin: 0px 15px;
    }
    option:focus {
        background: $emerald-opacity;
    }
    .page-main {
        font-family: $font-light;

        .columns .column.main {
            width: 70%;
            padding: 15px;
            margin-top: 2rem;

            input#policies_text {
                display: none;
            }

            .amgdpr-checkbox-container {
                margin-top: 20px;
                position: relative;

                .amgdpr-block-content {
                    position: relative;
                    .field.control.checkbox {
                        position: relative;
                    }
                }

                .amgdpr-label {
                    margin-bottom: 20px;
                    display: block;

                    &.-policy {
                        display: none;
                    }
                }
            }

            .account-section-title {
                font-weight: 300;
                line-height: 1;
                font-size: 1.8rem;
                margin: 0 0 1rem;
                color: $light;
                i {
                    &:before {
                        @include font-awesome;
                        color: $cyan;
                        font-size: 20px;
                        margin-left: 10px;
                    }
                }
            }
            .block {
                padding-bottom: 0.5rem;
                border-bottom: 1px solid $light-dark;
                .block-title {
                    font-size: 1.4rem;
                    margin-top: 2rem;
                    margin-bottom: 30px;
                    strong {
                        font-size: 1.4rem;
                    }
                }
            }

            .box-title {
                font-size: 1.2rem;
                color: $light;
                span {
                    font-size: 1.2rem;
                    font-weight: 500;
                }
            }

            .box-content {
                letter-spacing: 0.5px;
                font-size: 1rem;
                address {
                    line-height: 26px;
                }
            }
            a {
                color: $green-dark;
                letter-spacing: 0.7px;
                font-size: 1rem;
            }
            .label::after {
                color: $pink;
            }

            .fieldset {
                margin-bottom: 3rem;
                .legend {
                    letter-spacing: 0.48px;
                    font-weight: 600;
                    color: $light;
                    margin-bottom: 40px;
                    @media (max-width: 769px) {
                        font-size: 1.7rem;
                    }
                }
            }

            .client-area-title {
                text-align: left;
                letter-spacing: 0.4px;
                text-transform: capitalize;
                font-size: 1.6667rem;
                color: $light;
                margin-bottom: 1rem;
            }

            .form-wishlist-items {
                .info-number-wishlist {
                    margin-bottom: 1rem;
                }
            }

            .block-content,
            .orders-history {
                margin-bottom: 2rem;
            }

            .box {
                margin: 2rem 0;
            }

            .orders-recent {
                .table-wrapper
                    .table:not(.cart):not(.totals):not(.table-comparison)
                    > tbody
                    > tr
                    th[data-th]:before {
                    color: $light;
                }
                th {
                    white-space: nowrap;
                    &.claim {
                        display: none;
                    }
                }
            }

            .order-details-items {
                #my-orders-table {
                    tbody {
                        th {
                            white-space: nowrap;
                        }
                        td:nth-last-child(1) {
                            display: none;
                        }
                    }
                    thead {
                        th {
                            white-space: nowrap;
                            &.claim {
                                display: none;
                            }
                        }
                    }
                }
            }

            fieldset.fieldset {
                width: initial;
            }

            .amaffiliate-coupon-code button {
                display: none;
            }

            .field.input_link > label > span {
                display: block;
                padding-bottom: 20px;
            }

            .button.action.primary.affiliate_button {
                padding: 10px 0;
            }

            @media (min-width: 768px) {
                .table-order-items.recent {
                    font-size: 0.8rem;
                    margin-left: -1rem;
                    .deliverydate {
                        padding-left: 7.5px;
                        padding-right: 7.5px;
                    }
                }
            }

            .orders-history {
                padding: 5px;
                tr {
                    display: flex;
                    th {
                        text-align: left;
                        &.total {
                            margin-left: -2rem;
                        }
                    }
                    td.id {
                        margin-top: -1.3rem;
                        &:before {
                            width: max-content;
                        }
                    }
                    td.date {
                        margin-top: 0;
                    }
                    td.status {
                        &:before {
                            content: none;
                        }
                    }
                    td.actions {
                        margin-top: 0;
                        display: flex;
                        flex-direction: column;
                        &:before {
                            content: none;
                        }
                        a:first-of-type {
                            margin-bottom: 10px;
                        }
                    }
                }
                @media (min-width: 411px) {
                    tr {
                        td.date {
                            &:before {
                                padding-right: 100%;
                            }
                        }
                    }
                }
                @media (min-width: 488px) {
                    tr {
                        td.id {
                            margin-top: -1rem;
                        }
                    }
                }
                @media (min-width: 640px) {
                    tr {
                        td.id {
                            margin-top: 0;
                        }
                        td.status {
                            margin-top: 0;
                        }
                    }
                }
            }

            .actions-toolbar {
                button {
                    margin-bottom: 15px;
                    @media (max-width: 767px) {
                        margin-top: 1.5rem;
                    }
                }
            }
        }
        .sidebar-main {
            margin-top: 0;
            width: 100%;

            #account-nav.active {
                margin-bottom: 20px;
            }

            .block-collapsible-nav.amasty-affiliate-collapsible {
                position: initial;

                .title {
                    &:after {
                        position: initial;
                    }
                }
            }

            .account-nav-title,
            .block-collapsible-nav-title {
                color: $grey;
                padding: 15px;
                font-size: 1.667rem;
                font-weight: 400;
                border-top: solid 1px $light-dark;
                border-bottom: solid 1px $light-dark;
                &.open,
                &.active {
                    &:after {
                        content: '\f077';
                        @include font-awesome;
                        color: $pink;
                        font-size: 16px;
                        margin-left: 20px;
                        @media (min-width: 769px) {
                            display: none;
                        }
                    }
                }
                &:after {
                    content: '\f078';
                    @include font-awesome;
                    color: $turquoise;
                    font-size: 16px;
                    margin-left: 20px;
                    @media (min-width: 769px) {
                        display: none;
                    }
                }
            }

            .content .account-nav-content {
                background: $very-dark-2;
            }

            #amasty-affiliate-collapsible {
                background: initial;
            }

            .content.block-collapsible-nav-content {
                border-bottom: none;
            }

            .account-nav .item,
            #amasty-affiliate-collapsible .item {
                &.current a,
                &.current strong {
                    border: none;
                    font-weight: 600;
                    color: #2ad2c9;
                    margin-left: 2px;
                }

                a {
                    font-size: 1rem;
                    color: $light;
                    text-align: left;
                    letter-spacing: 0.36px;
                    opacity: 1;
                    &:hover {
                        color: $dark-2;
                    }
                }

                .delimiter {
                    border-top: 1px solid $light-dark;
                    margin: 10px;
                }
            }
        }
    }
}

.customer-account-logoutsuccess {
    .page-title-wrapper {
        margin: 5rem auto 0 auto;
        color: $turquoise;
        width: 90%;
    }

    .logout-content {
        margin: 2rem auto;
        color: $light;
        width: 90%;
    }
}

.customer-account-forgotpassword {
    .page-main {
        padding: 15px !important;
        .control {
            margin-bottom: 1rem;
            margin-top: 0.5rem;
        }
        .page-title {
            margin-bottom: 0.5rem;
        }
    }
}

.sales-order-view {
    .page-title-wrapper {
        margin-bottom: 2rem;
        .page-title {
            margin-bottom: 1rem;
            text-decoration: underline;
        }
    }
    .order-links {
        display: none;
    }
    .order-details-items {
        margin-bottom: 3rem;

        .order-items {
            .old-price {
                .price {
                    font-size: 1.25rem;
                    margin-right: 0.5rem;
                    @media (min-width: 640px) {
                        margin-right: 0;
                    }
                    @media (min-width: 768px) {
                        font-size: 1rem;
                    }
                }
            }
            .cart-price {
                .price {
                    font-size: 1.25rem;
                    @media (min-width: 768px) {
                        font-size: 1rem;
                    }
                }
            }
            .percent {
                font-size: 1.25rem;
                @media (min-width: 768px) {
                    font-size: 1rem;
                }
            }
        }
    }
}

.customer-account-login {
    .block-title {
        margin-bottom: 2rem;
        border-bottom: none;
    }
    .page-title {
        display: none;
    }
    .block-customer-login,
    .block-new-customer {
        margin-top: 2rem;
    }
    .social-login-authentication-channel {
        margin-top: 1rem;
    }
    .actions-toolbar {
        padding: 0;
        .primary {
            margin-right: 1rem;
        }
        .secondary {
            margin-top: 1rem;
        }
    }
    .label::after,
    .fieldset::after {
        color: $pink;
    }
}

.gdpr-customer-settings {
    .column.main {
        .block {
            @media (max-width: 767px) {
                font-size: 1.1rem;
            }

            &:last-of-type {
                .block-title {
                    color: $pink;
                }
            }

            .block-title {
                margin-bottom: 1rem;
                @media (max-width: 767px) {
                    margin-bottom: 0.5rem;
                }
            }

            .amgdpr-actions-toolbar,
            .field {
                margin-top: 0.5rem;
            }

            .amgdpr-cookie-link {
                margin-top: 0.5rem;
            }
        }

        a {
            color: $light !important;
            font-size: 0.9rem;

            &:hover {
                text-decoration: none;
                @include cyan-shadow;
                transition: all 0.5s ease-out;
            }
        }
    }

    .fieldset {
        margin-bottom: 0 !important;
        .label::after {
            color: $pink;
        }
    }
}

.customer-account-create {
    .page-title-wrapper {
        display: flex;
        font-weight: 300;
        line-height: 1;
        font-size: 1.8rem;
        margin: 0 0 1rem;
        color: #f8f7f2;
        &::after {
            @include font-awesome;
            content: '\f007';
            font-style: normal;
            font-weight: 400;
            font-variant: normal;
            text-transform: none;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            color: $cyan;
            font-size: 22px;
            margin-left: 10px;
            display: flex;
            align-items: center;
        }
    }

    .create {
        margin-bottom: 2rem;

        legend {
            font-size: 1.4rem;
            margin: 0 0 25px;
            padding: 0;
            box-sizing: border-box;
            float: left;
            line-height: 1.2;
        }
    }
    .primary {
        margin-bottom: 0.5rem;
    }
}

.customer-address-form {
    .column.main {
        .adress-selects {
            display: flex;
            margin-bottom: 20px;
            @media (max-width: 992px) {
                flex-direction: column;
                label {
                    margin-top: 0.5rem !important;
                }
            }
            label {
                margin: 0 0 5px;
                display: inline-block;
                font-weight: 700;
            }
            .country {
                label {
                    &:after {
                        content: '*';
                        font-size: 1.2rem;
                        color: $pink;
                    }
                }
            }
            .region {
                margin-top: 3px;
            }
        }
        .fieldset {
            margin-bottom: 2rem;
            .legend {
                font-size: 1.5rem;
                color: $turquoise;
            }
        }
    }
}

.vault-cards-listaction {
    .no-method-message {
        margin-bottom: 1rem;
        padding-top: 1rem;
    }
    .table-credit-cards {
        th.expire {
            white-space: nowrap;
        }
        td {
            display: table-cell;
            vertical-align: middle;
            white-space: nowrap;
        }
        td.actions::before {
            display: none !important;
        }
        .form {
            .action.delete {
                padding: 0.5rem;
            }
        }
    }
    .table:not(.cart):not(.totals) > thead > tr > th,
    .table:not(.cart):not(.totals) > thead > tr > td {
        border-bottom: 1px solid $light-dark;
    }
}

body.account.customer-account-edit {
    fieldset.fieldset.info {
        margin-bottom: 20px !important;
    }
}
.customer-account-index {
    .block-content {
        @media (max-width: 769px) {
            a {
                font-size: 1rem;
            }
        }
    }
    .action.change-password {
        margin-left: 50px;
    }
}

.account {
    .fieldset {
        .field {
            input[type='text'],
            input[type='password'],
            input[type='url'],
            input[type='tel'],
            input[type='search'],
            input[type='number'],
            input[type='datetime'],
            input[type='email'],
            textarea {
                &:not(#current-password-download):not(.qty) {
                    background: $very-dark-2 no-repeat;
                }
            }
        }

        .control {
            margin-top: 8px;
        }
    }
}

.fieldset > .field.required > .label:after,
.fieldset > .fields > .field.required > .label:after,
.fieldset > .field._required > .label:after,
.fieldset > .fields > .field._required > .label:after {
    font-weight: 100;
}

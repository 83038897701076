.field-tooltip-content {
    background: #f4f4f4;
    border: 1px solid #999999;
    border-radius: 1px;
    color: $very-dark-2;
    font-size: 14px;
    padding: 12px;
    width: 270px;
    display: none;
    left: 38px;
    position: absolute;
    text-transform: none;
    top: -9px;
    word-wrap: break-word;
    z-index: 2;
}

.gameo-tab {
    position: relative;
    margin: 4rem 0;
    font-family: $font-light;
    font-size: 0.8rem;

    .tab-labels {
        position: absolute;
        top: -39px;
    }
    .tab-labels .tablinks {
        @include background-color;
        font-family: $font-light;
        border: none;
        cursor: pointer;
        font-size: 1rem;
        position: relative;
        z-index: 0;
        padding: 10px 20px;
        color: $grey-dark;
        float: left;
        outline: none;
        @include border-block;
        border-color: transparent;

        &.active {
            @include border-block;
            position: relative;
            color: $grey-light;
            border-color: $dark-1;
            border-bottom-color: $very-dark-1;
            z-index: 10;

            &::after {
                content: '';
                position: absolute;
                left: -10px;
                top: 39px;
                width: 120%;
                height: 20px;
                background: $very-dark-1;
            }
        }
        &:first-of-type.active::after {
            left: 0;
        }
    }

    @media (hover: hover) {
        .tab-labels .tablinks:hover {
            color: $grey;
        }
    }

    .tab-content {
        @include border-block;
        display: none;
        position: relative;
        padding: 2rem;
        z-index: 5;
        color: $grey-light;
        &.active {
            display: block;
        }
        &__wrapper {
            letter-spacing: 0.03rem;
        }
    }
}

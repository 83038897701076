.wishlist-index-index {
    .account-section-title {
        i:before {
            color: $pink !important;
        }
    }
    .wishlist-title {
        display: flex;
        @media (min-width: 768px) {
            margin-bottom: 3rem !important;
        }
        .toolbar-amount {
            padding: 0;
            margin-left: 2rem;
            font-size: 1rem;
            color: $grey;
        }
        i:before {
            margin-top: 0.25rem;
        }
    }
    .wishlist-toolbar {
        text-align: start !important;
    }
    .form-wishlist-items {
        @media (min-width: 768px) {
            margin-top: 2rem;
        }
    }
    .product-items {
        padding: 0;
        @media (min-width: 769px) {
            padding-left: 0;
            margin-left: -5px;
        }
        .product-item {
            .product-item-photo {
                margin-bottom: 0;
            }
            .product-item-name {
                padding-left: 10px;
                margin-top: 10px !important;
                a {
                    font-size: 1.3rem !important;
                }
            }

            .price-box {
                padding: 0 10px;
                span {
                    font-size: 1.1rem;
                    @media (max-width: 795px) {
                        font-size: 1.5rem;
                    }
                }
                .promo-percent {
                    padding: 0.66rem 0.5rem;
                }
            }

            .product-item-actions {
                margin-top: 12px;
                padding: 0 10px;
                justify-content: stretch;
                .stock {
                    font-size: 16px;
                    padding: 8px 0;
                }
                .stock.unavailable {
                    font-size: 16px;
                    flex-grow: 1;
                    padding: 0;
                }
                .tocart,
                .stock.unavailable button
                {
                    font-size: 16px;
                    width: 100%;
                    height: 40px;
                    min-height: 40px;
                }

            }
            @media (hover: hover) {
                .product-item-info:hover > .wishlist-item-submenu {
                    display: block;
                }
            }
            .product-item-info .product-item-photo {
                .image-ribbon {
                    margin-left: 5px;
                    margin-top: 22px;
                    @media (min-width: 768px) {
                        margin-top: 2px;
                    }
                }
                .product-image-container {
                    width: 100% !important;
                }
            }
            .wishlist-item-submenu {
                border-top: none;
                display: block;
                height: auto;
                opacity: 1;
                z-index: 10;
                position: relative;
                padding-top: 0.75rem;
                margin-top: 0.75rem;
                border-top: 3px solid $dark-1;
                .wishlist-item-cart {
                    display: flex;
                    justify-content: space-around;
                    height: 3.5rem;
                    padding: 0 10px;
                    .box-tocart {
                        display: flex;
                        @media (min-width: 768px) {
                            margin-right: 20px;
                        }
                        .fieldset {
                            height: 100%;
                        }
                        .field {
                            display: flex;
                            height: 100%;
                            .control {
                                margin-top: 0;
                            }
                            label {
                                text-transform: uppercase;
                                font-size: 1rem;
                            }
                            .input-text {
                                height: 100%;
                                width: 3rem;
                                padding: 0;
                                margin-left: 1rem;
                                overflow: hidden;
                                @media (max-width: 991px) {
                                    height: 3.5rem;
                                    width: 4rem;
                                    font-size: 1.3rem;
                                }
                            }
                            .label {
                                @media (max-width: 991px) {
                                    font-size: 1.3rem;
                                }
                                display: flex;
                                align-items: center;
                                font-size: 1rem;
                                span::after {
                                    content: '';
                                }
                            }
                        }
                    }
                    .button-edit {
                        background: #272729;
                        height: 2.5rem;
                        padding: 0.75rem 1rem;
                        width: 100%;
                        @media (max-width: 991px) {
                            height: 3.5rem;
                            margin-right: 1.75rem;
                            a {
                                font-size: 1.2rem;
                            }
                        }
                    }
                    .wishlist-remove-button {
                        order: 3;
                        @media (max-width: 991px) {
                            .btn-remove {
                                width: 3.45rem;
                                height: 3.45rem;
                                svg {
                                    font-size: 1.5rem;
                                    margin: 0.95rem 0;
                                }
                            }
                        }
                    }
                }
                .wishlist-item-comment {
                    padding: 0 0.5rem;
                    margin-bottom: 0;
                    .comment-box {
                        padding: 10px 0;
                    }
                    .product-item-comment {
                        overflow: hidden;
                        background: $very-dark-2 0% 0% no-repeat padding-box;
                        border: 0.5px solid $grey-dark;
                        font-size: 1rem;
                        color: $grey-dark;
                        padding: 0.7rem 1rem 0.75rem 1rem;
                    }
                    .control {
                        width: 100%;
                    }
                }
            }
        }
    }
    .primary {
        text-align: center;
        @media (min-width: 768px) {
            text-align: left;
        }
        &.bottom-actions {
            margin-bottom: 2rem;
        }
        .wishlist-share {
            @include background-color;
            box-shadow: none;
            a {
                color: $light !important;
            }
            i:before {
                color: $turquoise;
                margin-left: 0.5rem;
                font-size: 1rem;
            }
        }
    }
    .top-toolbar {
        @media (min-width: 768px) {
            .toolbar-amount {
                position: absolute;
                margin-top: -2.5rem;
                right: 15px;
            }
        }
        .limiter {
            display: none;
        }
    }
    .bottom-toolbar {
        .toolbar-amount {
            display: none;
        }
    }

    #wishlist-view-form {
        .btn-dark {
            border-radius: 0;
            padding: 11px;
            margin-top: 20px;
            margin-bottom: 20px;
            width: 100%;
        }

        .tocart {
            width: 100%;
        }

        .fieldset {
            margin-bottom: 0;
        }

        .wishlist-item-comment {
            padding: 0px 16px;
        }

        .actions-primary {
            max-width: 100%;

            button {
                width: 100%;
            }
        }

        .field {
            display: flex;
            align-items: center;

            .input-text {
                height: 32px !important;
                color: $grey-dark;
            }
        }

        .wishlist-item-cart {
            height: auto;
            -ms-flex-pack: justify;
            justify-content: space-between;
            padding: 12px 16px;
        }
        .product-item {
            margin-bottom: 50px !important;
        }
        .box-tocart label {
            margin-bottom: 0 !important;
            margin-right: 8px !important;
            font-size: 16px !important;
        }
    }
}

.wishlist-shared-index {
    .page-main {
        padding: 15px !important;
        .page-title {
            font-size: 1.7rem;
            margin-bottom: 2rem;
        }

        .table.wishlist {
            margin-top: 2rem;
            td {
                font-size: 1.1rem;
                &.col.product {
                    @media (min-width: 768px) {
                        display: flex;
                        margin-top: 1rem;
                    }
                }
            }
            th {
                white-space: nowrap;
                &.col.actions {
                    display: none;
                    @media (min-width: 768px) {
                        display: table-cell;
                    }
                }
            }
            tbody {
                @media (min-width: 768px) {
                    tr:not(:first-of-type) {
                        border-top: 1px $light-dark solid;
                    }
                }
            }
            .col.product:before {
                margin-bottom: 0.5rem;
            }
            .col.actions {
                display: flex;
                flex-direction: column;
                @media (min-width: 768px) {
                    display: table-caption;
                }
            }
            .product-image-wrapper {
                margin-bottom: 1rem;
                @media (min-width: 768px) {
                    height: auto;
                    padding: 0 !important;
                }
            }
            .product-details {
                @media (min-width: 768px) {
                    width: 100%;
                    margin-left: 15px;
                    padding-top: 5px;
                }
            }
            .product.name {
                a {
                    font-size: 1.4rem;
                    margin: 1rem 0;
                }
            }
            .price-box {
                margin-top: 1rem;
                display: flex;
                justify-content: flex-end;
                .promo-percent {
                    padding: 0.66rem 0.5rem;
                }
            }
            .tocart {
                margin: 0.5rem 0;
            }
        }
    }
}
.customer-popup-ajaxwishlist,
.modal-popup_ajaxwishlist_magepow {
    .modal-inner-wrap {
        max-width: 400px;
        margin: 20vh auto;
        .modal-content {
            overflow: hidden;
        }
        .ajax-wishlist-image {
            margin: 0px 0px 21px;
            .image-system-wishlist {
                width: 100%;
                margin-bottom: 15px;
            }
        }
        .wishlist.primary {
            float: right;
        }
        .modal-footer {
            display: none;
        }
    }
    .wishlist-pass {
        margin-top: 21px;
    }

    .product-image-container {
        display: inline-block;
        width: 100% !important; // Because inline css in the ajax Wishlist module
        margin-bottom: 15px;
        .product-image-wrapper {
            padding-bottom: 0% !important; // Because inline css in the ajax Wishlist module
            height: 100%;
            img {
                width: 100%;
                aspect-ratio: 616/353;
                object-fit: cover;
                max-height: 210px;
            }

            &.packshot-white-background {
                @include white-background-img;
                height: 210px;
            }
            &.packshot-dark-background {
                @include dark-background-img;
                height: 210px;
            }
        }
    }
}
.customer-popup-login._disabled {
    display: none;
}

.modal-popup_ajaxwishlist_magepow {
    .mgp-wishlist-close {
        width: 100%;
        text-transform: capitalize;
    }
    .action.wishlist {
        &.primary,
        &.action-secondary {
            width: 100%;
            margin-bottom: 21px;
        }

        &.action-secondary {
            margin: 6px 0px;
        }
    }
    .product-name {
        font-size: 1.3rem;
        line-height: 1.5rem;
    }
    .mgp-message-successs {
        font-size: 1.3rem;
    }
    .ajaxwishlist-buttons button {
        font-size: 1.3rem !important;
    }
}

.customer-popup-ajaxwishlist {
    .fieldset {
        .label {
            span {
                font-size: 1.3rem;
            }
        }
    }
    .wishlist-pass a {
        font-size: 1.3rem;
    }
    .action.remind {
        font-size: 1.3rem;
    }
    .primary {
        width: 100%;
        margin-bottom: 21px;
        span {
            font-size: 1.3rem;
        }
    }
}

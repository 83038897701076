body.contentmanager-contenttype-homepage {
    .page.messages {
        margin: 15px 20px 0px;
    }
    .homepage {
        padding-top: 15px;
        h1 {
            @include txt-hidden;
        }
        .slideshows {
            margin: 0;
            > div {
                z-index: 1;
            }
        }
        .slideshow {
            margin: 0 0 0 12px;
            width: 100%;

            .splide {
                &__track {
                    margin: 0;
                    overflow: visible;
                }

                &__pagination {
                    margin-top: 1.33rem;
                }
            }

            .slide {
                @include border-block;
                position: relative;
                width: 100%;
                height: auto;
                overflow: hidden;
                .slide-image {
                    position: relative;
                    img {
                        width: 100%;
                        height: auto;
                        display: block;
                        aspect-ratio: 16/9;
                    }
                    &.slide-image--with-description:after {
                        content: '';
                        width: 100%;
                        height: 30%;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        background: linear-gradient(
                            180deg,
                            rgba(255, 255, 255, 0) 30%,
                            rgba(0, 0, 0, 0.8) 100%
                        );
                        pointer-events: none;
                    }
                }
                .slide-info {
                    position: relative;
                    padding: 1.75rem 1.33rem;

                    &-surtitle {
                        font-family: $font-regular;
                        margin-bottom: 8px;
                        font-size: 12px;
                        line-height: 12px;
                        letter-spacing: 0.36px;
                    }
                    &-title {
                        h3 {
                            .platforms-icons {
                                i {
                                    font-size: 18px;
                                }
                            }
                            margin-bottom: 8px;
                            font-size: 18px;
                            line-height: 24px;
                            letter-spacing: 0.36px;
                            font-family: $font-regular;
                            font-weight: 400;
                        }
                    }
                    &-description {
                        margin-bottom: 20px;
                        p {
                            font-size: 16px;
                            line-height: 18px;
                            letter-spacing: 0.6px;
                            font-family: $font-regular;
                            font-weight: 400;
                        }
                    }
                    &.dark,
                    &.light {
                        color: $light;
                        text-shadow: none;
                    }
                    &-actions {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .action.primary {
                            grid-column-start: 2;
                            font-size: 16px;
                            line-height: 16px;
                            padding: 12px 18px;
                        }
                    }
                }
            }
        }

        .slideshow-thumbnail {
            display: none;
        }

        .hotdeals,
        .recommanded {
            margin-top: 6rem;
            position: relative;
            .slider-products-header {
                .title {
                    margin-left: 12px;
                }
            }

            .splide__slide {
                &:last-of-type {
                    a {
                        span {
                            @include big-slider-font;
                        }
                    }
                }
            }

            .splide__list {
                padding: 12px 20px 12px 10px !important; //Override calculated padding splide list to have shadow on elements
            }

            .see-more {
                .only-desktop {
                    display: none;
                }
            }
        }
        .bestsellers {
            margin-top: 3rem;
            margin-left: 8px;
            position: relative;
            .bestsellers-row {
                width: calc(100% - 20px);
                position: relative;
                margin-bottom: 1rem;
                &:nth-child(n + 6) {
                    display: none;
                }
                &:last-child {
                    display: block;
                }
                .bestsellers-see-more {
                    height: 14px;
                    width: 100%;
                    font-size: 1rem;
                    display: flex;
                    justify-content: center;
                    margin-top: 22px;
                    i {
                        margin-left: 5px;
                    }
                }
                .bestsellers-nb {
                    position: absolute;
                    top: 45px;
                    text-align: center;
                    display: block;
                    left: 10px;
                    z-index: 10;
                    span {
                        font-size: 3rem;
                        line-height: 3rem;
                        font-weight: 700;
                        background: -webkit-linear-gradient($blue-light, $turquoise);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
                .product-item-small {
                    .product-item-info {
                        .product-item-photo {
                            height: fit-content;
                        }
                        .product-item-details {
                            .price-cart-button {
                                padding-bottom: 0;
                            }
                            .product-name {
                                font-size: 1.1rem;
                                line-height: 1.1rem;
                                @media (min-width: 768px) {
                                    font-size: 0.8rem;
                                }
                            }
                        }
                    }
                }
            }
        }
        .availablenow {
            margin: 0;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            -webkit-box-align: stretch;
            -ms-flex-align: stretch;
            align-items: stretch;
            display: flex;
            flex-direction: column;
            .availablenow-items {
                margin: 0;
                width: 100%;
                min-height: auto;
                position: relative;
            }
            .availablenow-information {
                display: none;
                overflow-y: scroll;
                width: 30%;
                @include background-color;
                border-bottom: 2px solid $dark-1;
                border-top: 2px solid $dark-1;
                border-right: 2px solid $dark-1;
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                padding: 10px;
                box-sizing: border-box;
                @media (max-width: 1024px) {
                    border: 2px solid $dark-1;
                }
                .availablenow-information-hovered p {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    line-height: 16px;
                    max-height: 150px;
                    text-align: initial;
                    -webkit-line-clamp: 5;
                    -webkit-box-orient: vertical;
                    font-size: 15px;
                    font-family: $font-regular;
                }
            }
            .availablenow-row {
                width: calc(100% - 20px);
                position: relative;
                &:nth-child(n + 6) {
                    display: none;
                }
                &:last-child {
                    display: block;
                }
                .availablenow-see-more {
                    height: 14px;
                    width: 100%;
                    font-size: 1rem;
                    display: flex;
                    justify-content: center;
                    margin-top: 22px;
                    i {
                        margin-left: 5px;
                    }
                }
                .availablenow-product-item-small {
                    .product-item-info {
                        .product-item-photo {
                            height: fit-content;
                        }
                        .product-item-details {
                            .price-cart-button {
                                padding-bottom: 0;
                            }
                            .product-name {
                                font-size: 1.1rem;
                                line-height: 1.1rem;
                                @media (min-width: 768px) {
                                    font-size: 0.8rem;
                                }
                            }
                        }
                    }
                }
            }
        }
        .block-home {
            position: relative;
            opacity: 0.8;
            @include transition(opacity 100ms linear);
            margin-top: 21px;
            padding: 0 20px;
            &:first-of-type {
                margin-top: 30px;
            }
            img {
                width: 100%;
                height: auto;
                display: block;
            }
            &-title {
                position: absolute;
                width: 100%;
                text-align: center;
                left: 0;
                @include absolute-vertical-centring;
                letter-spacing: 0.48px;
                text-shadow: 0px 0px 5px $black;
                color: $light;
                font-size: 1.4rem;
                line-height: 1.6rem;
                padding: 0 2rem;
            }
            @media (hover: hover) {
                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}

.mobile-only {
    display: block;
    @media screen and (min-width: $screen-sm) {
        display: none;
    }
}
.desktop-only {
    display: none;
    @media screen and (min-width: $screen-sm) {
        display: block;
    }
}
.uppercase {
    text-transform: uppercase;
}
.hidden {
    display: none;
}
.full-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

.no-scroll {
    overflow: hidden;
}

div.mage-error,
.field-error,
.message.warning,
div .message.warning span {
    color: $pink;
    font-size: 1rem;
    margin-top: 12px;
    font-weight: 600;
    padding-left: 0px;
}

body.checkout-onepage-success {
    .page-wrapper {
        .page-main {
            padding: 15px;
            h1 {
                font-size: 1.6rem;
                line-height: 1.6rem;
                margin-bottom: 20px;
            }
            .checkout-success {
                p,
                a {
                    font-size: 1rem;
                    line-height: 1.2rem;
                }
                p {
                    margin-bottom: 10px;
                }
                .actions-toolbar {
                    margin-top: 50px;
                    .primary {
                        max-width: 400px;
                    }
                }
            }
        }
    }
    .message-error.error.message {
        display: none;
    }
}

.catalog-product-view {
    font-weight: normal;
    color: $grey;

    .page.messages {
        margin: 0px 2rem;
    }

    &.got-related-products {
        main.page-main {
            position: relative;
            z-index: 10;
        }
        footer.page-footer {
            margin-top: -160px;
        }
        footer .block.newsletter {
            padding-top: 70px;
        }
    }

    .column.main > *:not(:last-child) {
        margin-bottom: 4rem;
    }

    .breadcrumbs {
        margin: 2rem 2rem 1.3rem;
    }

    //General Product item small

    .product-item-small {
        .product-item-info .product.details {
            @media screen and (max-width: 992px) {
                .product-name {
                    font-size: 1rem;
                }
            }

            .price-cart-button,
            .price-cart-button .price-box {
                padding: 0;
                margin-bottom: 0px;
            }

            .product-item-name .product-item-link .product-name {
                white-space: initial;
                max-height: 36px;
            }
        }
    }

    .product-item .price-box {
        margin: 10px 0;
    }

    .page-title-wrapper,
    .product-info-main,
    .product.media > :not(.product-page-main-slider),
    .product_details_mobile,
    .block.upsell .block-title.title {
        padding: 0 2rem;
    }

    h1.page-title {
        color: $light;
        font-size: 1.6rem;
        line-height: 1.6rem;
        margin: 0 0.5rem 1.6rem 0;
        display: inline-block;
    }

    .page-title-wrapper {
        .base {
            padding-left: 6px;
        }

        .platforms-icons {
            display: inline-block;
            margin: 0 0 24px 0;
            height: 24px;
            .platforms-icon {
                height: 24px;
                i {
                    line-height: 24px;
                    font-size: 24px;
                }
            }
        }
    }

    h2 {
        color: $grey;
        font-size: 1.3rem;
        margin-bottom: 1.5rem;
    }

    h3 {
        color: $grey;
        font-size: 16px;
        margin-bottom: 1rem;
    }

    h1,
    h2 {
        font-weight: 300;
    }

    //a {
    //    font-size: initial;
    //}

    .product_section {
        margin: 0 0 3.5rem;
    }

    .product_section,
    .gameo-tab {
        color: $light;

        .info-label {
            color: $grey;
            margin-bottom: 5px;
        }
    }
    .grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        gap: 1rem;
    }
    .grid-child {
        display: flex;
        i:before {
            margin: 0 5px;
            font-size: 1rem;
        }
    }
    //Info main section

    .product-info-main {
        .price-box {
            margin-top: 21px;
            .price-wording {
                display: inline-block;
                font-size: 1.2rem;
            }
            .special-price .price-wording,
            .old-price .price-wording {
                display: none;
            }
        }
        & > * {
            margin-bottom: 1rem;
        }
        .base-picture {
            position: relative;
            & > img {
                height: auto;
                width: 100%;
            }

            &.packshot-white-background {
                @include white-background-img;
            }
            &.packshot-dark-background {
                @include dark-background-img;
            }

            //.image-ribbon span {
            //    font-size: 1.5rem;
            //}
            .ribbon-inner,
            .ribbon-dlc {
                padding: 0.3rem 1rem;
            }
        }

        .box-tocart {
            margin: 0;
        }

        #preparation-time {
            display: none;
        }

        .product-addto-links {
            margin: 0;
        }

        .stock.unavailable {
            width: 100%;
            margin-bottom: 3px;
            text-align: end;
            text-transform: revert;
            font-weight: 300;
            color: $pink;
            font-size: 1.2rem;
        }
        .unavailable-stock-message {
            text-align: right;
            width: 100%;
            display: block;
            font-size: 1.3rem;
            margin-bottom: 15px;
        }
    }
    .product-info-price {
        text-align: right;
        .promo-percent {
            padding: 0.75rem 0.5rem;
            @media (min-width: 769px) {
                padding: 0.5rem 10px;
            }
            vertical-align: super;
            span {
                vertical-align: -webkit-baseline-middle;
            }
        }
    }
    .product-platform-switcher {
        display: flex;
        flex-flow: wrap;
        gap: 9px;
        justify-content: flex-end;
        .platforms-icons {
            height: auto;
            .platforms-icon {
                height: auto;
                padding: 0px;
                span {
                    font-size: 15px;
                    border: 1px solid rgb(93, 93, 93);
                    color: $light;
                    display: flex;
                    align-items: center;
                    padding: 6px 9px;
                    line-height: initial;
                    i::before {
                        margin-right: 5px;
                    }
                    img {
                        margin-left: 5px;
                        max-height: 18px;
                    }
                }
                &.current-platform span {
                    color: $turquoise;
                    border-color: rgba($turquoise, 0.3);
                    i {
                        color: $turquoise;
                    }
                }
            }
        }
    }

    .product-platform-switcher .platforms-icon,
    .tags-button {
        a:hover span {
            background-color: rgb(93, 93, 93);
        }
    }

    //tags
    .product-info-main {
        .product_tags {
            .tags-button {
                display: none;
                margin-top: 0.5rem;
                .display-tags {
                    cursor: pointer;
                    span {
                        width: 100%;
                        font-size: 15px;
                        border: 1px solid $grey-dark;
                        color: $light;
                        display: block;
                        padding: 6px 9px;
                        text-align: center;
                    }
                }
            }
        }
    }
    .product.media {
        .product_tags {
            display: none !important;
        }
    }
    .tab-section__description {
        .product_tags {
            .display-tags {
                display: none;
            }
        }
    }

    .add-section {
        display: flex;
        align-items: flex-end;
        .product-social-links {
            order: 1;
            width: fit-content;
            margin-left: 0.5rem;
            margin-right: 0.5rem;
        }
        .product-add-form {
            order: 2;
            margin-left: auto;

            .box-tocart,
            .box-tocart > .fieldset {
                margin-bottom: 0.3rem;
            }
            .field.qty,
            .product-options-wrapper {
                display: none;
            }
        }
        .recommended-link-section {
            text-align: end;
            margin-top: 1rem;

            .recommended-link,
            i {
                transition: filter 0.3s ease;
            }
            @media (hover: hover) {
                &:hover {
                    .recommended-link {
                        @include white-hover-shadow;
                    }

                    i {
                        @include cyan-hover-shadow;
                    }
                }
            }
        }
        .recommended-link {
            font-size: 100%;
            margin-right: 0.5rem;
            color: $grey;
        }
    }
    @media screen and (max-width: 992px) {
        button.action.tocart,
        button.action.instant-purchase,
        .cisa-alert-action button.action.primary {
            font-size: 1.4rem;
            min-height: 48px;
        }
    }

    .cisa-alert-action button.action.primary {
        min-width: 210px;
    }

    button.action.instant-purchase {
        margin-top: 10px;
        padding: 1rem 0.5rem;
        width: 100%;
        min-width: 184px;
    }

    // Reassurance section
    .main_reassurance {
        @include border-top;
        @include border-bottom;
        margin: 24px 0;
        padding: 12px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        & > * {
            align-items: center;
            margin: 12px 0;
            color: $grey-light;
            display: flex;

            & > * {
                margin-left: 7px;
            }
        }
    }

    //Description section
    // mobile
    .section_description {
        display: flex;
        margin-top: 2rem;
        flex-direction: column;
        transition: height 0.2s ease-in-out;
        line-height: 1.5rem;
        & .see-more {
            margin-top: 0.5rem;
            margin-left: auto;
        }
        &-panel {
            letter-spacing: 0.03rem;
            font-size: 1.1rem;
        }
    }
    // desktop
    #description {
        h3.product-title {
            margin: 0 0.5rem 1rem 0;
            display: inline-block;
            font-family: $font-regular;
            font-weight: initial;
        }
        .platforms-icons {
            display: inline-block;
            margin: 0 0 0.3rem 0;
            height: 16px;
            .platforms-icon {
                font-family: inherit;
                height: 16px;
                i {
                    font-size: 16px;
                    line-height: 16px;
                }
            }
        }
    }

    .section_description-panel,
    .description_wrapper {
        ul {
            list-style: initial;
        }

        li {
            margin-left: 2rem;
        }
        ul,
        p {
            margin: revert;
        }
    }

    // Complete Package
    .block.upsell {
        &#upsell_section {
            margin-bottom: 0;
        }
        h2 {
            color: $cyan;
        }

        .product-item {
            padding: 0 2rem;
            margin-bottom: 12px;
            transform: translateY(-70%);
            .product-item-info {
                background: transparent;
                border: unset;
                box-shadow: unset;
                padding: 0;

                button {
                    width: 100%;
                    @include cyan-blue-gradient-127deg;
                }

                .bundle-final-price {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    margin-bottom: 1.5rem;
                }
            }
        }

        ol.products.list.product-items {
            margin: 80px 0 0;
            flex-direction: column;

            .bundle-item {
                max-height: 600px;
            }
        }

        fieldset {
            font-size: initial;
            @include dark-cyan-linear-gradient;
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            padding: 0 2rem 104px;

            .action.back.customization {
                display: none;
            }

            .field.option {
                position: relative;
                margin-bottom: 1.6rem;
                min-width: 272px;
                width: fit-content;
                @include background-color;
                @include border-block;

                &:last-of-type {
                    transform: translateY(-50%);
                    &::after {
                        content: '\f067';
                        @include font-awesome;
                        font-weight: 300;
                        position: absolute;
                        left: 50%;
                        transform: translate(-50%, 100%);
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                        color: $cyan;
                        font-size: 2rem;
                    }
                }

                .field.choice {
                    margin-bottom: 4px;
                }
            }

            .options-wrapper {
                border-top: 3px solid $dark-2;
                padding: 12px 20px;
                display: flex;
                align-items: center;

                .field.qty {
                    display: flex;
                    align-items: center;
                    margin-right: auto;

                    & > .control {
                        margin-left: 14px;
                    }

                    & > .control > input {
                        appearance: none;
                        font-size: 1.2rem;
                        text-align: center;
                        color: $light;
                        width: 48px;
                        height: 32px;
                    }
                }

                input[type='radio'] {
                    display: none;
                }

                label.checkbox-checked,
                label.checkbox-unchecked {
                    width: 20px;
                    height: 20px;
                    color: $cyan-turquoise;
                    display: none;
                }

                .checkbox-checked {
                    border: 2px solid $white;
                }

                .checkbox-unchecked {
                    border: 2px solid $cyan-turquoise;
                }

                input.input-check:checked ~ label.checkbox-unchecked {
                    display: block;
                }
                input.input-uncheck:checked ~ label.checkbox-checked {
                    display: block;
                }
            }

            .product-item-small .product-item-inner {
                display: none;
            }

            .product-item-small .product-item-info {
                box-shadow: unset;
                border-width: 0;
            }
        }
    }

    // Related section

    .block.related {
        position: relative;

        .see-more a {
            font-size: 1rem;
            i {
                font-size: 1.1rem;
            }
        }
    }

    // Product details section
    .section_details {
        .grid-child {
            flex-direction: column;
        }
    }

    #description p,
    #requirements p,
    .product_section.section_requirements p {
        margin: revert;
    }

    .product_details_mobile,
    #product_details,
    #description {
        .pegi-icons {
            display: flex;
            flex-wrap: wrap;
            margin-top: 1rem;

            .pegi-icon {
                height: 40px;
                width: 40px;
                margin-right: 15px;
                &:first-of-type {
                    width: 33px;
                }
            }
        }
        .pegi-option {
            border: solid;
            background-color: $very-dark-1;
            width: auto;
            display: inline-block;
            border: 1px solid $grey;

            .pegi-icons {
                padding: 9px;
                margin-top: 0;

                img {
                    border-top: 1px solid $black;
                }
            }
            .pegi-text {
                border: none;
                border-top: 1px solid $grey;
                color: $light;
                margin-top: 0;
                padding: 6px 9px;
                text-align: center;
                margin-top: 3px;
            }
        }
        .peg-itext {
            margin-top: 3px;
        }
        .langs-subtitle,
        .langs-supported {
            li {
                align-items: center;
                margin-right: 10px;
                .lang-label {
                    margin-left: 6px;
                }
            }
        }
    }

    // Additional Content
    .crosssell-wrapper {
        h2 {
            padding: 0 2rem;
        }

        .accordion-panel .product-list-items > li,
        .crosssell-product-list-items > li {
            max-width: 100%;
            flex: 1;
        }
    }

    .crosssell-accordion-wrapper {
        display: flex;
        flex-direction: column-reverse;

        .accordion-panel {
            .product-list-items {
                box-sizing: border-box;

                button.action.tocart {
                    min-height: auto;
                }
            }
        }

        .accordion-panel,
        .accordion-panel-always-visible {
            .product-list-items {
                display: grid;
                grid-template-columns: minmax(0, 1fr);
                grid-row-gap: 24px;
                grid-column-gap: 24px;
                padding: 12px 2rem;
            }
        }

        .accordion-title {
            margin-top: 12px;
            align-self: center;
        }
    }

    //Customer Item Stock Alert
    .cisa-alert-action {
        text-align: end;
        button {
            //min-width: 184px;
            //max-width: 196px;
            padding: 0.75rem 0.75rem;
            span {
                line-height: 1.3rem;
            }
        }

        & > p {
            margin-bottom: 1rem;
        }
    }

    .out-of-stock-modal {
        min-height: 311px;
        max-width: 450px;
        margin: auto;
        margin-top: calc((100vh - 311px) / 2);
        .modal-inner-wrap {
            background-color: $very-dark-1;
            margin: auto;
            @media (max-width: 991px) {
                overflow-x: scroll;
            }
            .action-close {
                @media (hover: hover) {
                    &:hover {
                        @include pink-shadow;
                    }
                }
                &:before {
                    color: $pink;
                }
            }
            .input-label {
                font-size: 1rem;
                margin-bottom: 0.25rem;
                display: block;
            }
            .cisa-product-name {
                margin: 1rem 0;
                font-size: 15px;
                letter-spacing: 0.5px;
                color: $light;
                font-weight: normal;
                line-height: 18px;
            }
            #cisa-succes {
                font-size: 14px;
                line-height: 30px;
                padding: 12px 0;
            }
        }
        .modal-footer {
            margin-top: 39px;
        }
    }

    //Slider
    .product-page-main-slider {
        margin: 3rem 0 2rem 0;

        .slide-image figure:not(.background-image) img {
            //width: 100%;
            aspect-ratio: 16/9;
            object-fit: cover;
        }

        //Avoid splider for being too big while loading
        &:not(.is-initialized) {
            .slide-image figure:not(.background-image) img {
                max-height: 150px;
            }
        }

        .slide.screen-white-background {
            background-color: #e8e8e8;
            @media (max-width: 1023px) {
                .slide-image figure picture {
                    @include white-background-img;
                }
            }

            @media (min-width: 1024px) {
                .slide-image {
                    background-color: #e8e8e8;

                    .background-overlay {
                        @include background-overlay;
                    }

                    figure.background-image {
                        height: 100%;
                        img {
                            height: 100%;
                            display: none;
                        }
                    }
                    figure:not(.background-image) img {
                        aspect-ratio: initial;
                        -o-object-fit: initial;
                        max-height: 90%;
                        max-width: 90%;
                    }
                }
            }
        }
        .slide-image {
            max-height: 10rem;
            figure.background-image {
                top: 0;
                left: 0;
                width: 100%;
                position: absolute;
                z-index: 1;
                overflow: hidden;
                display: none;
                img {
                    width: 100%;
                    height: auto;
                    filter: blur(10px);
                    transform: scaleY(-1);
                }
            }
        }
    }

    #gallery-prev-area,
    #gallery-next-area {
        display: none;
    }

    // Desktop component hide in mobile
    .product-info-main .product_tags,
    .thumb-wrapper,
    .crosssell-wrapper .crosssell-product-list-items,
    .gameo-tab {
        display: none;
    }
}

.platforms-icons {
    display: inline-block;
    height: 18px;
    margin: 0;
    vertical-align: middle;
    height: 20px;
    vertical-align: initial;
    color: $grey;
    .platforms-icon {
        display: inline-block;
        font-family: $font-regular;
        i {
            font-size: 14px;
            color: $grey;
            line-height: 14px;
            width: auto;
        }
    }
    .inline-product-name {
        color: $light;
    }
    .platform-separator {
        margin: 0px 0px 0px -3px;
    }
}

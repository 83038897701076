html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
input {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: $font-light;
    vertical-align: baseline;
}

html {
    scroll-behavior: smooth;
    scrollbar-gutter: stable;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
* {
    box-sizing: border-box;
}

h1 {
}

h2 {
}

h3 {
}

h4 {
}

a {
    text-decoration: none;
    font-size: 0.8rem;
    color: #f8f7f2;
    letter-spacing: 0.28px;
    line-height: 1rem;
    &:link,
    &:visited {
    }
    @media (max-width: 991px) {
        font-size: 1rem;
        letter-spacing: 0.5px;
    }
    @media (hover: hover) {
        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
        }

        &:active,
        &:focus {
            color: $grey;
        }
    }

    &.cyan {
        color: $cyan-turquoise;
        @include transition(all 0.3s ease);
        @media (hover: hover) {
            &:hover {
                text-shadow: 0px 0px 8px $green-dark;
            }
        }
    }

    &.pink {
        color: $pink;
        @include transition(all 0.3s ease);
        @media (hover: hover) {
            &:hover {
                text-shadow: 0px 0px 8px $pink-light;
            }
        }
    }

    &.white {
        color: $light;
        @include transition(all 0.3s ease);
        @media (hover: hover) {
            &:hover {
                text-shadow: 0px 0px 8px $light;
            }
        }
    }
}

p {
}

img {
    filter: brightness(0.9) contrast(1.02);
}

.modals-overlay {
    z-index: 1010 !important;
    background-color: rgba(0, 0, 0, 0.3);
}

.popin-overlay {
    display: block;
    position: fixed;
    visibility: hidden;
    opacity: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $black;
    z-index: 10;
    @include transition(visibility 0s 0.3s, opacity 0.3s ease);
    &.active {
        @include transition(opacity 0.3s ease);
        opacity: 0.3;
        visibility: visible;
        z-index: 999;
    }
}

html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

*:not(.counter) > .loading-mask {
    background: rgba(0, 0, 0, 0.6);
    .loader {
        > img {
            content: url('../images/O-Portal.svg');
            width: 30%;
            max-width: 230px;
            animation: spin 3s linear infinite;
        }
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

:root {
    color-scheme: dark;
}

::-webkit-scrollbar {
    width: 6px;
    top: 2px;
}

::-webkit-scrollbar-track {
    background: $very-dark-2;
}

::-webkit-scrollbar-thumb {
    background-color: $grey-dark;
    border-radius: 20px; /* roundness of the scroll thumb */
}

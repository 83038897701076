.page.tracking {
    margin: 0 1rem;
    .tracking-title {
        font-weight: 300;
        line-height: 1;
        font-size: 1.8rem;
        margin: 0 0 2rem;
        color: $light;
        i:before {
            @include fa-linear-gradient-pink;
        }
    }
    .order.subtitle.caption {
        font-weight: bold;
        margin-bottom: 20px;
    }
    .redirect {
        margin-top: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .redirect-text {
            font-size: 1.5rem;
            width: 90%;
            line-height: 2rem;
            text-align: center;
            a {
                font-size: 1.5rem;
                color: $cyan;
            }
        }
        i:before {
            font-size: 4rem;
            margin-top: 2rem;
            @include fa-linear-gradient-pink;
        }
    }

    .table-wrapper {
        margin-bottom: 0;
        &:last-child a.back {
            display: block;
        }
        &:not(:last-child) tr {
            @media (min-width: 640px) {
                border-bottom: 1px solid $grey-dark !important;
            }
        }
        .tracking-number {
            vertical-align: middle;
            padding: 15px 0;
            font-weight: normal;
            text-align: left;
            span {
                font-weight: bold;
            }
            @media (min-width: 640px) {
                width: 50%;
            }
        }
        .tracking-link {
            text-align: center;
            padding: 15px 0;
            & a.action.primary {
                font-size: 0.95rem;
                width: 100%;
                @media (min-width: 640px) {
                    width: auto;
                    float: right;
                }
            }
            @media (min-width: 640px) {
                width: 50%;
                text-align: left;
            }
        }
        a.back {
            margin-top: 2rem;
            display: none;
            color: $green-dark;
            letter-spacing: 0.5px;
            font-size: 100%;
        }
    }
}

.faq-page,
.loffaq-category-view {
    .breadcrumbs {
        display: none;
    }
    .page-main {
        .page-title {
            margin-top: 2rem;
            margin-bottom: 2rem;
            color: $turquoise;
            font-size: 1.5rem;
            font-weight: 600 !important;
        }
        h3 {
            color: $turquoise;
            font-size: 1rem;
            font-weight: 600 !important;
        }
        h4 {
            a,
            span {
                color: $light !important;
            }
        }
        p {
            color: $light !important;
            font-size: 0.8rem;
            line-height: 1rem;
        }
        #faq-page {
            a {
                color: $turquoise !important;
                font-size: 0.8rem;
            }
        }
        #faq-search .btn-submit {
            position: relative;
            @include pink-purple-gradient;
            cursor: pointer;
            border: 0;
            box-shadow: 0px 0px 12px $black;
            font-family: $font-light;
            font-size: 0.95rem;
            line-height: 0.95rem;
            padding: 0.75rem 2.5rem;
            max-width: 100%;
            letter-spacing: 0.4px;
            color: $light;
            opacity: 1;
            text-align: center;
            @include transition(all 100ms linear);
            @media (hover: hover) {
                &:hover,
                &:active,
                &:focus {
                    text-shadow: 0px 0px 8px $light;
                }
            }
            &[disabled],
            &[disabled='true'],
            &.disabled {
                cursor: default;
                pointer-events: none;
                background: $very-dark-2;
                color: $grey;
            }
        }
        .loffaq-search-wrapper {
            background: $very-dark-1;
            #faq-search {
                display: flex;
                .action-primary {
                    margin-left: 0.5rem;
                    height: 2.2rem;
                }
            }
            .loffaq-search {
                .faqkeyword {
                    border-top: 0.5px solid #000;
                    border-left: 0.5px solid #000;
                    border-right: 0.5px solid #000;
                    border-bottom: none;
                    color: $grey;
                    letter-spacing: 0.36px;
                    opacity: 1;
                    width: 100%;
                    font-size: 0.85rem;
                    padding: 0.75rem 1rem;
                    background: -webkit-gradient(
                            linear,
                            left top,
                            right top,
                            from(#00fff1),
                            color-stop(27%, #00fff1),
                            color-stop(49%, #03dcff),
                            to(#03dcff)
                        )
                        left bottom #161616 no-repeat;
                    background: linear-gradient(
                            to right,
                            #00fff1 0%,
                            #00fff1 27%,
                            #03dcff 49%,
                            #03dcff 100%
                        )
                        left bottom #161616 no-repeat;
                    background-size: 100% 1px;
                }
                .action-primary {
                    margin-left: 0.5rem;
                    height: 2.2rem;
                }
            }
        }
        .faqcat-title {
            margin-bottom: 1em;
            border-bottom: none !important;
            background: linear-gradient(
                    to right,
                    $turquoise 0%,
                    $turquoise 27%,
                    $cyan 49%,
                    $cyan 100%
                )
                left bottom $very-dark-1 no-repeat;
            background-size: 100% 1px;
            padding-bottom: 0.25em;
            .line {
                border-bottom: none !important;
            }
        }
        .panel-heading {
            border: $grey-dark solid 1px !important;
        }
        .panel-body {
            font-size: 0.8rem;
            color: $light;
            line-height: 1rem;

            .question-meta {
                display: none;
            }
            h4,
            li {
                font-size: 0.8rem;
                color: $light;
                line-height: 1rem;
            }
            li {
                margin: 5px 0;
            }
        }
    }
}

.fa,
.fal,
.far,
.fab,
.fas,
.fak {
    @include font-awesome();
    &:before {
        display: inline-block;
        line-height: initial;
        transform: rotate(0.001deg);
        filter: drop-shadow(0px 0px 0px transparent);
    }
}

.fa-nintendo:before {
    content: '\e000';
}
.fa-instagram:before {
    content: '\f16d';
}
.fa-apple:before {
    content: '\f179';
}
.fa-windows:before {
    content: '\f17a';
}
.fa-cc-visa:before {
    content: '\f1f0';
}
.fa-cc-mastercard:before {
    content: '\f1f1';
}
.fa-cc-paypal:before {
    content: '\f1f4';
}
.fa-facebook-f:before {
    content: '\f39e';
}
.fa-playstation:before {
    content: '\f3df';
}
.fa-xbox:before {
    content: '\f412';
}
.fa-search:before {
    content: '\f002';
}
.fa-heart:before {
    content: '\f004';
}
.fa-user:before {
    content: '\f007';
}
.fa-check:before {
    content: '\f00c';
}
.fa-times:before {
    content: '\f00d';
}
.fa-flag:before {
    content: '\f024';
}
.fa-chevron-left:before {
    content: '\f053';
}
.fa-chevron-right:before {
    content: '\f054';
}
.fa-check-circle:before {
    content: '\f058';
}
.fa-info-circle:before {
    content: '\f05a';
}
.fa-arrow-left:before {
    content: '\f060';
}
.fa-arrow-right:before {
    content: '\f061';
}
.fa-arrow-up:before {
    content: '\f062';
}
.fa-arrow-down:before {
    content: '\f063';
}
.fa-plus:before {
    content: '\f067';
}
.fa-eye:before {
    content: '\f06e';
}
.fa-eye-slash:before {
    content: '\f070';
}
.fa-exclamation-triangle:before {
    content: '\f071';
}
.fa-chevron-up:before {
    content: '\f077';
}
.fa-chevron-down:before {
    content: '\f078';
}
.fa-credit-card:before {
    content: '\f09d';
}
.fa-hdd:before {
    content: '\f0a0';
}
.fa-users:before {
    content: '\f0c0';
}
.fa-square:before {
    content: '\f0c8';
}
.fa-bars:before {
    content: '\f0c9';
}
.fa-angle-left:before {
    content: '\f104';
}
.fa-angle-right:before {
    content: '\f105';
}
.fa-angle-up:before {
    content: '\f106';
}
.fa-angle-down:before {
    content: '\f107';
}
.fa-check-square:before {
    content: '\f14a';
}
.fa-euro-sign:before {
    content: '\f153';
}
.fa-dollar-sign:before {
    content: '\f155';
}
.fa-share-alt:before {
    content: '\f1e0';
}
.fa-trash:before {
    content: '\f1f8';
}
.fa-shopping-bag:before {
    content: '\f290';
}
.fa-triangle:before {
    content: '\f2ec';
}
.fa-shipping-fast:before {
    content: '\f48b';
}
.fa-box-full:before {
    content: '\f49c';
}
.fa-tshirt:before {
    content: '\f553';
}
.fa-file-certificate:before {
    content: '\f5f3';
}
.fa-head-vr:before {
    content: '\f6ea';
}
.fa-user-headset:before {
    content: '\f82d';
}
.fa-mouse-alt:before {
    content: '\f8cd';
}
.fa-triangle1:before {
    content: '\f2ed';
}
.fa-heart1:before {
    content: '\f005';
}
.fa-check1:before {
    content: '\f00e';
}
.fa-times1:before {
    content: '\f00f';
}
.fa-fire:before {
    content: '\f06d';
}
.fa-caret-down:before {
    content: '\f0d7';
}
.fa-caret-up:before {
    content: '\f0d8';
}
.fa-caret-left:before {
    content: '\f0d9';
}
.fa-caret-right:before {
    content: '\f0da';
}
.fa-circle:before {
    content: '\f111';
}
.fa-microphone-alt:before {
    content: '\f3c9';
}

header.page-header {
    @include background-color;
    box-shadow: 0 0 12px 0 $black;
    border-bottom: 3px solid $dark-1;
    padding-bottom: 4px;
    position: fixed;
    width: 100vw;
    padding-right: 6px;
    z-index: 1000;

    > * {
        padding: 0px 16px;
    }

    .top-line-banner {
        background: $dark-1;
        padding: 12px 0px;
        height: 48px;
        border-bottom: 1px solid $light-dark;

        .top-line-banner-item-wrapper {
            margin: auto;
            justify-content: space-between;
            display: flex;
            align-items: center;
            flex: 1;
            height: 24px;
            line-height: 24px;

            .item {
                margin: 0 14px;
                i,
                span {
                    font-weight: 400;
                    font-size: 1rem;
                }

                span {
                    font-family: 'Segoe UI', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
                    margin-left: 9px;
                }
            }

            .item-reassurance {
                display: none;
            }

            .item-trustpilot-widget {
                flex: 4;
            }
        }
    }

    .navigation-container {
        @include background-color;
        @include transition(opacity 0.3s ease);
        .mobile.hamburger {
            align-self: center;
            margin-right: 30px;
            z-index: 1;
        }
        .link-search {
            display: block;
            flex: 1;
            .block-search {
                .block-content {
                    margin: 0 auto;
                    .control {
                        display: flex;
                        & > i:before {
                            display: none;
                        }
                        .search-autocomplete {
                            position: absolute;
                            top: 120px;
                            left: 0;
                            width: 100% !important;
                            @include background-color;
                            ul {
                                box-shadow: 0 24px 12px -24px $black;
                                border-bottom: 3px solid $dark-1;
                                padding: 5px 85px 0 85px;
                                li {
                                    margin-left: 7px;
                                    margin-bottom: 10px;
                                    font-size: 1.2rem;
                                    & span.amount {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                    input {
                        flex: 1;
                        padding: 5px 7px;
                        background: transparent;
                        color: $grey;
                        border-top: none;
                        border-left: none;
                        border-right: none;
                        border-bottom: 1px solid $grey;
                        font-size: 1.2rem;
                        opacity: 0.5;
                        transition: all 0.3s ease;
                        @media (hover: hover) {
                            &:hover {
                                box-shadow: none;
                            }
                        }
                        &:active,
                        &:focus {
                            opacity: 1;
                        }
                    }
                    button.action.search {
                        margin-left: 30px;
                        padding: 0;
                        background: transparent;
                        color: $light;
                        border: none;
                        font-size: 1.4rem;
                    }
                }
            }
        }
        .navigation {
            display: flex;
            align-content: center;
            flex-wrap: wrap;
            padding-bottom: 12px;
            @include transition(transform 0.5s ease);

            ul[role='menubar'] {
                display: none;
                list-style: none;
                align-items: center;
                width: 100%;
                padding-top: 48px;
                color: white;
                opacity: 0;
                transition: opacity 0.2s ease, transform 0.5s ease, max-height 0.5s ease;
                position: relative;
                height: 410px;
                max-height: 410px;
                overflow-y: scroll;
                overflow-x: hidden;
                &.open-nav-1 {
                    transform: translateX(-105%);
                    overflow: visible !important;
                }
                &.open-nav-2 {
                    transform: translateX(-210%);
                    overflow: visible !important;
                    & > li.open > ul {
                        overflow: visible !important;
                    }
                }

                &.mobile {
                    a {
                        display: block;
                        font-size: 1.2rem;
                        line-height: initial;
                        padding: 12px 0;
                        &:before {
                            position: absolute;
                            left: 8px;
                        }
                        span {
                            margin-left: 40px;
                        }
                    }
                }
                &.opened {
                    opacity: 1;
                }
                &.mobile li.parent:not(.back):not(.current-category):not(.see-all) > a:after {
                    @include font-awesome;
                    @include fa-linear-gradient-blue-turquoise;
                    content: '\f054';
                    position: absolute;
                    right: 5px;
                }
                & li.back {
                    position: absolute;
                    top: 23px;
                    & > a {
                        color: $pink;
                        font-weight: bold;
                        position: relative;
                        padding: 0;
                        &:before {
                            @include font-awesome;
                            @include fa-linear-gradient-pink;
                            content: '\f054';
                            transform: rotate(180deg);
                            font-size: 0.75rem;
                            top: 0.4rem;
                        }
                        & > span {
                            margin-left: 21px;
                            text-transform: capitalize;
                        }
                    }
                }
                & li.see-all {
                    & > a {
                        margin-top: 12px;
                        i {
                            &:before {
                                font-size: 1.2rem;
                            }
                            &:first-child {
                                margin-left: 40px;
                            }
                        }
                        & > span {
                            margin-left: 8px;
                            margin-right: 8px;
                            text-transform: none;
                        }
                    }
                }

                li.level0 {
                    margin-right: 2.5rem;
                    text-transform: uppercase;
                    background-clip: padding-box;
                    &:first-child {
                        & > a {
                            padding-top: 0;
                        }
                    }
                    & a.menu-item-active > span {
                        text-decoration: none;
                    }

                    &.open {
                        ul.level0 {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                    ul.level0 {
                        left: 105%;
                        position: absolute;
                        top: 0;
                        padding-top: 64px;
                        transform: translateX(0);
                        width: 100%;
                        opacity: 0;
                        visibility: hidden;
                        z-index: 100;
                        transition: all 0.3s ease;
                        max-height: 100%;
                        overflow: hidden scroll;
                        @media (hover: hover) {
                            &:hover {
                                & > a {
                                    text-shadow: none;
                                }
                            }
                        }

                        &.submenu {
                            position: fixed;
                        }

                        li.level1 {
                            transition: all 0.3s ease;
                            &:nth-child(2) {
                                a {
                                    padding-top: 0;
                                }
                            }
                            &.menu-products {
                                max-width: 275px;
                                list-style: none;
                                & > a {
                                    display: none;
                                }
                                & > ul {
                                    margin-top: 0;
                                }
                                a::before {
                                    content: none;
                                }
                                .product-item-small {
                                    &:not(:last-child) {
                                        margin-bottom: 38px;
                                    }
                                    .image-ribbon {
                                        .ribbon-inner {
                                            padding: 3px 8px;
                                        }
                                        & span {
                                            font-size: 0.7rem;
                                        }
                                    }
                                    .product-image-container {
                                        width: min-content !important;
                                        .product-image-wrapper {
                                            max-width: 115px;
                                            max-height: 75px;
                                        }
                                    }
                                    .product-item-inner {
                                        display: none;
                                    }
                                    .product-item-details {
                                        flex: 1;
                                        padding: 4px;
                                        .product-item-name {
                                            margin: 0;
                                        }
                                        .product-item-link {
                                            font-size: 0.7rem;
                                            text-decoration: none;
                                            @media (hover: hover) {
                                                &:hover {
                                                    text-decoration: none;
                                                }
                                            }
                                        }
                                        .price-cart-button {
                                            span.price {
                                                font-size: 0.9rem;
                                            }
                                        }
                                    }
                                }
                            }
                            & span {
                                text-transform: capitalize;
                            }
                            &.open {
                                ul.level1 {
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                            &.current-category {
                                & > a[role='menuitem'] {
                                    &:before {
                                        @include cyan-shadow;
                                    }
                                    & > span {
                                        margin-left: 31px;
                                        text-transform: uppercase;
                                    }
                                }
                            }
                            & ul.level1 {
                                position: absolute;
                                opacity: 0;
                                visibility: hidden;
                                z-index: 100;
                                transition: all 0.3s ease;
                                left: 105%;
                                top: 0;
                                padding-top: 64px;
                                transform: translateX(0);
                                width: 100%;
                                max-height: 100%;
                                overflow: hidden scroll;
                                &.submenu {
                                    position: fixed;
                                }
                                & li.level2 {
                                    &:nth-child(2) {
                                        a {
                                            padding-top: 0;
                                        }
                                    }
                                    &.current-category {
                                        & > a[role='menuitem'] {
                                            &:before {
                                                @include cyan-shadow;
                                            }
                                            & > span {
                                                margin-left: 31px;
                                                text-transform: uppercase;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .reassuring {
            display: none;
            flex: 1;
            justify-content: flex-end;
            padding-bottom: 5px;
            .item {
                display: flex;
                align-items: center;
                i:before {
                    max-height: 0.8rem;
                    margin-right: 5px;
                }
                span {
                    font-size: 0.7rem;
                }
            }
            .dot {
                margin: 0 10px;
                align-self: center;
                &:before {
                    font-size: 2px;
                }
            }
        }
    }
    .header.content {
        display: flex;
        flex: 1;
        padding-top: 15px;
        & > .switcher-language,
        & > .authorization-link,
        & > .action.help {
            display: none;
        }
        .action.nav-toggle {
            display: none;
        }
        .logo {
            height: fit-content;
            margin: 0;
            display: flex;
            align-items: center;
            align-self: center;
            padding: 0 0 1rem 0;
            @media (hover: hover) {
                &:hover {
                    .logo-o-portal {
                        transform: rotateZ(180deg);
                    }
                }
            }
            &.beta {
                &::after {
                    @include pink-gradient;
                    @include fa-common-gradient;
                    content: 'Beta!';
                    text-transform: uppercase;
                    display: block;
                    position: absolute;
                    top: calc(100% - 12px);
                    right: 0;
                    font-family: $font-bold;
                    letter-spacing: 0.5rem;
                    font-style: italic;
                }
            }
            .logo-game {
                width: 76px;
            }
            .logo-o-portal {
                width: 25.6px;
                height: auto;
                @include transition(transform 0.4s ease);
            }
        }
        .link-search {
            display: none;
            align-self: center;
            flex: 1;
            .block-search {
                .block-content {
                    .control {
                        input {
                            width: 290px;
                        }
                        button.action.search {
                            display: none;
                        }
                    }
                }
            }
        }
        .country-selector__wrapper {
            display: none;
        }
    }
    .minicart-wrapper {
        position: static;
        float: none;
        align-self: center;
        margin: 0;
        display: flex;
        flex: 1;
        justify-content: flex-end;
        .wishlist {
        }
        .action-wishlist,
        .action-minicart {
            padding-bottom: 1rem;
            width: fit-content;
            margin-right: 20px;
        }
        .action-minicart {
            border-bottom: 1px solid transparent;
            border-image-slice: 1;
        }
        &.active {
            .action-minicart {
                @include pink-purple-border-gradient-lr;
            }
        }
        .action.showcart ,
        .show_wishlist{
            position: relative;
            &:before {
                display: none;
            }
            .counter,.show_wishlist {
                display: flex;
                flex: 1;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: -4px;
                right: -4px;
                height: 20px;
                width: 20px;
                border-radius: 100%;
                font-size: 0.75rem;
                margin: 0;
                padding: 0;
                text-align: center;
                vertical-align: middle;
                color: $white;
                padding-left: 2px;
                font-weight: bold;
                @include pink-purple-gradient;
                & .loading-mask {
                    height: 20px;
                    width: 21px;
                    border-radius: 100%;
                    z-index: 1;
                    background: $black;
                    margin-left: -1px;
                    img {
                        margin-left: -1px;
                    }
                }
            }
            &.active {
                .minicart-bag-icon::before {
                    background: transparent
                        linear-gradient(90deg, #ff009c 0%, #ff009c 48%, #9700e2 100%) 0% 0%
                        no-repeat padding-box;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    filter: none;
                }
                @media (hover: hover) {
                    &:hover {
                        //@include pink-shadow;
                        text-shadow: 0 0 8px $pink;
                    }
                }
            }
        }
    }
    .action.customer {
        align-self: center;
        border-bottom: 1px solid transparent;
        border-image-slice: 0;
        border-image-source: none;
        padding: 0 0 1rem 0;
        &.opened {
            border-image-slice: 1;
            @include pink-purple-border-gradient-lr;
            @media (hover: hover) {
                &:hover {
                    //@include pink-shadow;
                    .btn-round {
                        text-shadow: $pink;
                    }
                }
            }
        }
        .popin {
            box-shadow: 0 24px 12px -24px $black;
            background-color: $dark-1;
            padding: 0 0 3px 0;
            position: absolute;
            display: flex;
            flex-direction: column;
            z-index: 10;
            width: 100%;
            margin-top: 15px;
            left: 0;
            height: 476px;
            @include pink-purple-border-gradient-lr;
            & i.close:before,
            & .back {
                position: absolute;
                cursor: pointer;
                top: 19px;
            }
            & .back {
                display: flex;
                font-size: 1.3rem;
                left: 26px;
                & i:before {
                    margin-right: 8px;
                }
                & span {
                    color: $pink;
                }
            }
            & i.close:before {
                font-size: 1.4rem;
                right: 26px;
            }
            & .popin-account,
            & .popin-customer {
                background-color: $very-dark-1;
                padding: 51px 24px 0;
                display: flex;
                flex: 1;
            }
            & .popin-signin {
                display: flex;
                flex: 1;
                flex-direction: column;
                & .btn.signin {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                }
                & .btn.signin {
                    margin: 21px 0;
                }
                & .register,
                & .signout {
                    & > span {
                        text-align: left;
                    }
                    & .btn-shadow {
                        display: flex;
                        flex: 1;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        & span {
                            margin-bottom: 0;
                            flex: 1;
                            text-align: center;
                        }
                        & i {
                            position: absolute;
                            right: 16px;
                        }
                    }
                }
                & .register {
                    & .btn-shadow {
                        margin-top: 13px;
                    }
                }
                & .signout {
                    display: flex;
                    flex: 1;
                    margin-bottom: 20px;
                    & .btn-shadow {
                        align-self: flex-end;
                    }
                    a {
                        height: 37px;
                        margin-bottom: 20px;
                    }
                }
                & .links {
                    display: flex;
                    flex-direction: column;
                    margin-top: 28px;
                    & a {
                        display: flex;
                        align-items: center;
                        align-self: flex-start;
                        margin-bottom: 18px;
                        position: relative;
                        font-size: 1.5rem;
                        @media (hover: hover) {
                            &:hover {
                                & .fa-shopping-bag,
                                & .fa-box-full,
                                & .fa-user {
                                    @include cyan-hover-shadow;
                                }

                                & .fa-heart {
                                    @include pink-hover-shadow;
                                }
                            }
                        }
                        & span {
                            flex: 1;
                            margin-left: 12px;
                        }
                        & .link-icon {
                            width: 20px;
                            display: flex;
                            justify-content: center;
                            & i:before {
                                align-self: center;
                                transition: filter 0.3s ease;
                            }
                        }
                    }
                }
                & .hello-user {
                    display: flex;
                    align-items: center;
                    height: 21px;
                    @media (hover: hover) {
                        &:hover {
                            img {
                                transform: rotateZ(180deg);
                            }
                        }
                    }

                    span {
                        font-size: 1.5rem;
                    }

                    img {
                        width: 20px;
                        height: 22px;
                        margin-right: 12px;
                        transition: transform 0.3s ease;
                    }
                    .name {
                        color: $cyan-turquoise;
                        margin-left: 4px;
                    }
                    .apostrophe {
                        white-space: pre;
                    }
                }
                & .language-switcher {
                    display: block;
                    & .country-selector__wrapper {
                        display: block;
                    }
                }
            }

            & .popin-login {
                flex: 1;
                & .block-customer-login {
                    margin: 0;

                    & fieldset {
                        margin: 0;
                    }

                    & .block-title {
                        margin: 18px 0 25px 0;
                        & strong {
                            font-size: 1.2rem;
                            font-weight: 300;
                        }
                    }

                    & .field.password .control {
                        flex-direction: row;
                        flex-wrap: wrap;
                        margin-bottom: 15px;
                        & input {
                            width: min-content;
                            flex: 1;
                        }
                        & .action.btn-round {
                            margin-left: 1rem;
                            & * {
                                transition: none;
                            }
                        }
                    }
                    & .actions-toolbar {
                        margin-bottom: 0;
                        & .secondary {
                            margin-bottom: 30px;
                            text-align: right;
                            & span {
                                color: $grey;
                                @media (hover: hover) {
                                    &:hover {
                                        color: $grey-dark;
                                    }
                                }
                            }
                        }
                    }
                }
                & .signin {
                    text-align: center;
                    font-size: 0.8rem;
                    margin-top: 17px;
                    & a {
                        & i {
                            &:before {
                                margin-left: 5px;
                                margin-right: 2px;
                            }
                        }
                    }
                }
            }

            & .reassurance {
                display: flex;
                flex-direction: column;
                place-content: flex-end;
                padding: 0 24px 16px;
                background-color: $very-dark-1;
                & span {
                    font-size: 1rem;
                    margin-bottom: 5px;
                }
                & a {
                    font-size: 1.4rem;
                    align-self: flex-start;
                }
            }
        }
    }
}

.amsearch-wrapper-block {
    position: absolute;
    top: 124px;
    left: 71px;
    width: 100%;

    .amsearch-result-section {
        position: absolute;
        margin-top: 1px;
        background: $very-dark-1;
        padding: 20px;
        z-index: 5;

        span.product-image-wrapper {
            padding-bottom: 0 !important;
        }
    }

    .amsearch-input-wrapper {
        .amsearch-button {
            display: none;
        }
    }
}



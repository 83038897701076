.pac-container {
    box-shadow: 0 0 12px $black;
    border-top: 1px solid transparent;
    background-color: $dark-1;
    margin-top: 9px;
    z-index: 99999;

    .pac-item,
    .pac-item-selected {
        color: $grey;
        border: none;
        padding: 9px;
        background-color: transparent;

        &:hover {
            background-color: transparent;
            color: #fff;
            font-weight: 400;
            cursor: pointer;
        }
        .pac-item-query {
            color: #fff;
            font-weight: initial;
            padding-right: 6px;

            .pac-matched {
                font-weight: initial;
                letter-spacing: 0.3px;
            }
        }
        span:last-child {
            font-size: 14px;
        }
    }
    &::after {
        display: none;
    }
}

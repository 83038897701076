// Modal
.modal-popup.confirm,
.modal-popup {
    .modal-inner-wrap {
        background-color: $very-dark-1;
        box-shadow: 0px 0px 12px $black;
        border: 3px solid $dark-1;
        opacity: 1;
        max-height: 80vh;
        padding: 15px;
        width: 80%;
        margin: 10vh auto;

        .modal-header {
            margin: 24px 0px 12px;

            .modal-title {
                border-bottom-color: $light-dark;
                padding: 15px 0px 15px;
                line-height: 18px;
                font-size: 15px;
            }
        }

        .modal-content {
            // overflow: hidden;
            margin-top: 15px;
            p,
            ul,
            li {
                font-size: 1rem;
                @media (min-width: 992px) {
                    font-size: 0.9rem;
                }
            }
            p {
                margin-bottom: 0.5rem;
                margin-top: 0.25rem;
            }
            li {
                list-style: disc;
            }
            ul {
                margin-left: 1.5rem;
            }
            strong {
                font-size: 1.1rem;
                margin-top: 0.5rem;
                @media (min-width: 992px) {
                    font-size: 1rem;
                }
            }
            label::after {
                color: $pink;
            }
            .block-title {
                margin-bottom: 0.5rem;
                font-weight: 600;
                color: $light;
            }
            .block-customer-login {
                &:before {
                    color: $very-dark-2;
                }
            }
            .primary {
                margin-top: 1rem;
            }
            input[type='email'] {
                font-size: 15px;
            }
        }
        .action-close {
            cursor: pointer;
            &:before {
                font-size: 36px;
                padding: 9px 6px;
                @include pink-gradient;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            &:hover {
                @include pink-shadow;
            }
        }
        .modal-footer {
            margin-top: 18px;
            display: flex;
            justify-content: flex-end;
            .action-primary {
                margin-left: 1rem;
            }
        }

        button.action.primary,
        button.action-primary,
        button.action-secondary {
            padding: 15px 30px;
            font-size: 14px;
        }
    }
}

.modal-inner-wrap {
    .modal-title {
        border-bottom: 1px solid $light-dark;
    }
}
.modals-overlay {
    background-color: rgba(0, 0, 0, 0.5);
}

.popin-overlay {
    &.active {
        opacity: 0.5;
    }
}

.page-layout-checkout {
    div.page-wrapper
        main#maincontent.page-main
        div.columns
        div.column.main
        div#checkout.checkout-container
        div.checkout-main-info
        div.opc-wrapper
        ol#checkoutSteps.opc
        li#shipping.checkout-shipping-address
        div#checkout-step-shipping.step-content
        form#co-shipping-form.form.form-shipping-address
        div#shipping-new-address-form.fieldset.address
        fieldset.field.street.admin__control-fields.required
        div.control
        div.field._required
        label.label
        div#opc-new-shipping-address {
        .control {
            margin-top: 5px;
        }
    }

    header.page-header {
        padding: 21px;
        box-shadow: initial;
        border: initial;

        .header.content {
            padding: 0;

            .logo {
                margin: 0;
            }
        }
    }
    main.page-main {
        margin: 90px auto 0;

        .checkout-agreements-block .amasty-gdpr-consent {
            margin-bottom: 20px;
            margin-left: 18px;

            &:after {
                color: #e02b27;
                font-size: 1.2rem;
                content: '*';
            }
        }
    }

    .checkout-title {
        margin: 20px 0 40px 0;
        h2 {
            display: inline-block;
            color: $light;
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 1.8rem;
        }
        i {
            display: inline-block;
            margin-left: 5px;
            color: $turquoise;
            font-size: 1.2rem;
            line-height: 1.8rem;
        }
    }

    #checkout-step-shipping_method button,
    .new-address-popup button,
    #checkout-payment-method-load button,
    .form.form-discount button,
    .modals-wrapper .modal-inner-wrap button,
    .authentication-wrapper button {
        padding: 12px 0;
        border: none;
        color: $light;
        font-size: 20px;
        line-height: 24px;
        &:hover {
            cursor: pointer;
        }
    }

    .payment-method-title {
        .expiration-date-start {
            margin-right: 5px;
        }
        .expiration-date {
            margin-left: 0 !important;
        }
    }

    .form .form-discount button,
    .checkout-billing-address button.action-cancel {
        @include cyan-gradient;
    }

    .checkout-billing-address button.action-update {
        @include pink-purple-gradient;
    }

    .payment-method._active button.checkout.disabled {
        display: none;
    }

    main#maincontent {
        padding: 0 21px;
    }

    .footer-nav-button {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto;
        margin: 0 2rem 2rem 0;
        padding-left: 36px;
    }

    .checkout-shipping-address {
        .field.newsletter {
            + .field.addresses {
                margin-top: 1.5rem;
            }
        }

        fieldset.field.street label.label {
            position: inherit;
            width: auto;
            height: auto;
            padding: 0;
            margin: 20px 0 10px 0;
        }
    }

    //------------- Progress Bar ------------------
    .opc-progress-bar {
        $opc-progress-bar-pos: 9px;
        $opc-progress-bar-height: 2px;

        position: relative;
        display: grid;
        grid-template-columns: 1fr repeat(2, 2fr) 1fr;
        height: 44px;
        max-width: 390px;
        margin-bottom: 20px;
        counter-reset: i;

        &:before {
            position: absolute;
            bottom: $opc-progress-bar-pos;
            display: block;
            width: 100%;
            height: $opc-progress-bar-height;
            box-shadow: 0px 0px 12px $black;
            background: $grey-dark;
            content: '';
        }

        .opc-progress-bar-item {
            position: relative;

            &:before {
                position: absolute;
                bottom: $opc-progress-bar-pos;
                width: 100%;
                height: $opc-progress-bar-height;
                content: '';
            }

            .opc-progress-bar-item-content {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-flow: column nowrap;
            }
            &:first-child .opc-progress-bar-item-content {
                align-items: flex-start;
                transform: translateX(0%);
            }
            &:last-child .opc-progress-bar-item-content {
                align-items: flex-end;
                transform: translateX(0%);
            }

            .opc-progress-bar-item-content-icon {
                @include font-awesome;
                width: 20px;
                color: $grey-dark;
                text-align: center;
                font-size: 16px;
                line-height: 20px;
            }
            &:nth-of-type(1) .opc-progress-bar-item-content-icon:before {
                content: '\f290';
            }
            &:nth-of-type(2) .opc-progress-bar-item-content-icon:before {
                content: '\f48b';
            }
            &:nth-of-type(3) .opc-progress-bar-item-content-icon:before {
                content: '\f153';
            }
            &:nth-of-type(4) .opc-progress-bar-item-content-icon:before {
                content: '\f00c';
            }

            .opc-progress-bar-item-content-text {
                visibility: hidden;
            }

            &:after {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                content: '';
            }
        }
        .opc-progress-bar-item._active {
            &:before {
                @include pink-purple-gradient;
                width: 50%;
            }
            &:last-child:before {
                width: 100%;
            }

            .opc-progress-bar-item-content-icon {
                color: $light;
                filter: drop-shadow(0 0 8px $pink-light);
            }

            &:after {
                bottom: $opc-progress-bar-pos;
                display: block;
                z-index: 10;
                width: 9px;
                height: $opc-progress-bar-height;
                box-shadow: 0 0 4px 1px $pink-light;
                background: $light;
                border-radius: $opc-progress-bar-height;
            }
            &:last-child:after {
                right: 0;
                left: auto;
            }
        }
        .opc-progress-bar-item._complete {
            &:before {
                background: $pink;
            }

            .opc-progress-bar-item-content-icon {
                color: $magenta;
            }

            &:after {
                width: 20px;
                height: 20px;
                background: $very-dark-1;
                border: solid $opc-progress-bar-height $magenta;
                border-radius: 50%;
                color: $magenta;
                @include font-awesome;
                bottom: 0;
                text-align: center;
                font-size: 10px;
                font-weight: 900;
                line-height: 16px;
                content: '\f00c';
            }
            &:first-child:after {
                left: 0;
                transform: translateX(0);
            }
            &:last-child:after {
                right: 0;
                left: auto;
                transform: translateX(0);
            }
        }
    }

    .checkout-main-info {
        display: flex;
        flex-direction: column-reverse;
        padding: 0;

        .msp-ui-payment-form {
            border: initial;

            .msp-ui-form-group {
                .msp-ui-form-label {
                    color: $white;
                }
            }
        }

        .msp-ui-method-header {
            display: flex;
            align-items: center;
            font-size: 1.2rem;

            img {
                width: 33px;
                padding: 0px;
            }
        }

        .msp-container-ui .msp-ui-payment-form {
            padding: 15px 0px;
        }

        .msp-container-ui .msp-ui-credit-card-form .msp-ui-form-group {
            height: auto;
            margin: 0 0 1.5em;
        }

        .msp-ui-form-group label {
            vertical-align: baseline;
            height: 25px;
            margin-bottom: 6px;
            line-height: 25px;
        }

        .msp-ui-inline-block {
            vertical-align: bottom;
            margin-bottom: 3px;
        }

        div.msp-ui-inline-block {
            display: none !important;
        }

        .adyen-checkout__input,
        .msp-ui-form-control {
            width: 100%;
            padding: 0.75rem 0.5rem !important;
            border: 0.5px solid #646465 !important;
            opacity: 1;
            color: #a7a7a8 !important;
            font-size: 1rem;
            letter-spacing: 0.36px;
            &:active,
            &:focus,
            &--focus,
            &:active:hover,
            &--focus:focus:hover,
            &--focus:hover {
                box-shadow: unset !important;
                border: 0.5px solid #646465 !important;
            }
        }

        .msp-ui-form-control {
            background-color: $white !important;
        }

        .msp-container-ui .msp-ui-form-control.msp-ui-control-error {
            box-shadow: unset !important;
            border-color: $pink !important;
        }

        .msp-container-ui .msp-ui-control-error-message {
            display: block;
            margin-top: 9px;
            color: $pink;
            font-size: inherit;
        }

        .msp-container-ui .msp-ui-col-2 {
            padding: 0px;
        }

        .msp-container-ui .msp-ui-col-2:nth-child(odd) {
            padding: 0 1em 0 0;
        }

        .adyen-checkout__label__text {
            color: #a7a7a8;
        }

        .adyen-checkout__checkbox__label {
            color: #a7a7a8;
        }

        .step-title {
            margin-bottom: 30px;
            color: $light;
            font-size: 1.7rem;
        }

        //------------ Checkout steps -----------------

        .opc-wrapper {
            .shipping-address-item {
                &.selected-item {
                    border-color: $cyan;
                    &:after {
                        bottom: 0;
                        background: $cyan;
                    }
                }
                &.not-selected-item {
                    border-bottom: 1px solid $light-dark;
                }
                button.action {
                    padding: 0.5rem 1rem;
                    cursor: pointer;
                }
                .edit-address-link {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }

            .billing-address-details {
                .action-edit-address {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }

        #checkoutSteps {
            #checkout-shipping-method-load {
                .shipping-method-item {
                    display: inline-block;
                    max-width: 100%;
                    min-width: 280px;
                    margin: 0 10px 10px 0;
                }
            }
        }

        // ---------- Cart Summary section ------------

        .opc-summary-wrapper {
            position: static;
            visibility: visible;
            opacity: 1;

            .modal-inner-wrap {
                transform: initial;
                overflow: visible;
            }
        }

        .opc-block-summary,
        .modal-inner-wrap {
            background-color: $very-dark-1;
        }

        .opc-estimated-wrapper,
        .opc-block-summary {
            padding: 0;
            margin: 0;
        }

        .opc-estimated-wrapper {
            position: absolute;
            top: 0;
            border-bottom: none;
        }

        //Don't show this section in payment section when table-totals is show
        table.data.table-totals ~ .opc-estimated-wrapper {
            display: none;
        }

        .opc-block-summary {
            @include border-top;
            @include border-bottom;
            position: relative;
            padding: 0;
            margin-bottom: 40px;
            min-height: 40px;

            .div-table-totals {
                @include border-block;
                position: relative;
                z-index: 1;
                padding: 1rem;
                .grand.totals {
                    color: $light;
                    font-size: 1.2rem;
                    line-height: 1.2rem;
                }
            }

            span.title,
            .minicart-wrapper {
                display: none;
            }

            .estimated-block {
                display: flex;
                height: 38px;
                align-items: center;

                .estimated-label {
                    font-size: 12px;
                    margin: 0 6px 0 0;
                }
                .estimated-price {
                    color: $light;
                    font-size: 18px;
                    font-weight: 400;
                }

                span {
                    font-weight: 300;
                }
            }

            .items-in-cart {
                & > .title {
                    display: flex;
                    align-items: center;
                    width: fit-content;
                    padding: 0;
                    height: 38px;
                    line-height: 38px;
                    margin-left: auto;
                    font-size: 1rem;

                    &:after {
                        color: $cyan;
                        transform: rotate(0deg);
                        @include transition(transform 0.3s ease);
                    }
                }

                &.active > .title:after {
                    color: $pink;
                    transform: rotate(180deg);
                }

                & > .summary {
                    padding: 11px 24px 24px;
                    box-shadow: 1px 1px 14px $black;
                    background: $very-dark-1;
                    border: solid 2px $dark-1;
                    .summary-title {
                        padding-bottom: 20px;
                        color: $grey-light;
                        text-transform: capitalize;
                        font-size: 14px;
                    }
                }
            }
        }
        // Step payment method - Shipping information

        .opc-block-shipping-information {
            padding: 0;

            .shipping-information-title {
                color: $light;
                font-size: 1.7rem;
                @include border-bottom;
            }

            .ship-to,
            .ship-via {
                margin-bottom: 40px;
            }

            .action-edit {
                top: -4px;
                cursor: pointer;
                @include transition(color 100ms linear);
            }
        }

        .checkout-payment-method {
            .payment-method {
                .payment-method-title {
                    padding: 15px;

                    label {
                        div.adyen-sprite,
                        img {
                            display: none;
                        }

                        span {
                            margin-left: 10px;
                        }
                    }
                }

                .payment-method-content {
                    display: none;
                }

                &._active {
                    border: solid 2px $cyan;
                    margin: 0 -2px;
                    position: relative;
                    &:after {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        width: 29px;
                        height: 27px;
                        padding-top: 2px;
                        background: #03dcff;
                        color: $white;
                        text-align: center;
                        font-family: 'icons-blank-theme';
                        font-size: 27px;
                        line-height: 21px;
                        content: '\e610';
                    }

                    .payment-method-content {
                        padding: 15px;
                        display: block;
                    }
                }
            }

            .action.action-show {
                cursor: pointer;
                color: $grey;
                &:after {
                    color: $pink;
                }
            }

            .payment-option-title .action-toggle {
                margin: 16px 0;
                color: $cyan;

                &:after {
                    color: $cyan;
                    transform: rotate(0);
                    @include transition(transform 0.3s ease);
                }
            }

            .payment-option._active {
                .payment-option-title .action-toggle:after {
                    color: $pink;
                    transform: rotate(180deg);
                }
            }
        }

        .payment-method-billing-address {
            margin-top: 20px;
        }

        // Checkout Minicart section
        .content.minicart-items {
            .minicart-items-wrapper {
                width: 100%;
                overflow: visible;
            }

            //Checkout product-item-small
            li.product-item-small {
                margin-top: 20px;
                @extend .product-item-small;

                &:first-of-type {
                    margin: 0;
                }

                .preorder_note {
                    display: none;
                }

                .product-item-info .product-item-details {
                    flex-direction: row;
                    flex-wrap: wrap;
                    padding: 10px 9px 4px;
                    gap: 0 6px;
                    .platforms-icons {
                        flex: 1 0 100%;
                        margin: 0;
                    }
                    .price-details {
                        justify-content: space-between;
                        height: 19px;
                        line-height: 19px;
                        margin: 0;
                        flex: 1 0 100%;

                        .subtotal {
                            color: $light;
                            font-family: $font-light;
                            font-size: 16px;
                        }
                        .product-item-info .product-item-details > *,
                        .details-qty {
                            font-size: 16px;
                            margin-right: 3px;
                            margin-top: 0;
                            color: $light;

                            span {
                                line-height: initial;
                            }
                        }
                    }
                }
            }
        }
    }

    .checkout-agreement {
        input[type='checkbox'] {
            position: relative;
        }
    }

    .modals-wrapper .modal-inner-wrap {
        background-color: $very-dark-1;
        .checkout-agreements-item-content {
            overflow: hidden !important;
        }
        .modal-footer {
            display: grid;
            grid-column-gap: 1rem;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
        }

        button {
            font-size: 1.3rem;
        }
    }

    .modal-custom {
        .modal-header {
            .action-close {
                right: initial;
                left: 0;

                &:hover:before {
                    cursor: pointer;
                    color: $blue-light;
                }
            }
        }
    }

    .select {
        width: 100%;
        background: linear-gradient(to right, $pink 0%, #8b00e9 64%, $purple 100%) left bottom
            $very-dark-2 no-repeat;
        background-size: 100% 1px;
        border-top: 0.5px solid $black;
        border-right: 0.5px solid $black;
        border-bottom: none;
        border-left: 0.5px solid $black;
        opacity: 1;
        color: $grey;
        font-size: 1rem;
        letter-spacing: 0.36px;
    }

    .authentication-wrapper {
        margin-bottom: 20px;

        .action-auth-toggle {
            width: 100%;
        }
        .custom-slide._show {
            visibility: visible;
            opacity: 1;
            transition: opacity 0.3s ease;
            -webkit-transition: opacity 0.3s ease;
        }
        .custom-slide._show .modal-inner-wrap {
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
        }
        .custom-slide .modal-inner-wrap {
            opacity: 1;
            pointer-events: auto;
        }
        .custom-slide._show .modal-inner-wrap {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
        .custom-slide .modal-inner-wrap {
            position: static;
            width: auto;
            transform: translateX(100%);
            transition: transform 0.3s ease-in-out;
            overflow-y: auto;
            -webkit-transform: translateX(100%);
            -webkit-transition: -webkit-transform 0.3s ease-in-out;
        }
        .custom-slide._show {
            -webkit-overflow-scrolling: touch;
            z-index: 1000 !important;
            overflow-x: hidden;
            overflow-y: auto;
        }
        aside.authentication-dropdown {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 44px;
            z-index: 900;
            min-width: 0;
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s 0.3s, opacity 0.3s ease;
            -webkit-transition: visibility 0s 0.3s, opacity 0.3s ease;
            .modal-inner-wrap {
                height: 100vh;
                padding: 90px 20px;
                background-color: $very-dark-1;

                .block-customer-login {
                    .block-title {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }

    .opc-summary-wrapper {
        .action-close {
            display: none;
        }
    }

    .checkout_copyright {
        padding: 0px 36px 28px;
    }

    .note {
        &:before {
            content: none !important;
        }
    }

    .field-tooltip {
        // (input height - tooltip height) / 2
        top: calc((36px - 24px) / 2);
    }
}
body._has-modal-custom .modal-custom-overlay {
    background-color: rgba(51, 51, 51, 0.55);
}

// Change input border on these pages
.sales-guest-form,
.checkout-index-index {
    input {
        background: $very-dark-2 no-repeat;
    }
}
.checkout-index-index {
    #opc-new-shipping-address {
        input {
            background: $very-dark-2 no-repeat;
        }
    }
}

.shipping-address-item {
    &:nth-child(odd) {
        margin-right: 0.5rem;
    }
}

.shipping-address-item.not-selected-item {
    padding: 0;
    margin-top: -0.1rem;
    .adress {
        @include border-block;
        padding: 20px 35px 20px 20px;
    }
}
.action-select-shipping-item {
    margin: 10px 0 !important;
}

// Todo
// Fix rapide en attendant de review la popin de nouvelle adresse dans le checkout
.modal-popup.modal-slide._inner-scroll .modal-inner-wrap {
    overflow: hidden;
}
.modal-popup._inner-scroll .modal-inner-wrap {
    max-height: 80vh;
}

#registration {
    p {
        margin-bottom: 20px;
    }
}

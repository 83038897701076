.coming-soon {
    @include border-block();
    height: 100%;
    width: calc(100% - 48px);
    position: relative;
    margin: 20px auto;
    &-image {
        max-height: 214px;
        min-height: 160px;
        overflow: hidden;
        position: relative;
        img {
            width: 100%;
            height: auto;
            display: block;
        }
        .image-ribbon {
            opacity: 0;
            @include transition(opacity 100ms linear);
            &.active {
                opacity: 1;
            }
        }
    }

    &-image.packshot-white-background {
        @include white-background-img;
    }
    &-image.packshot-dark-background {
        @include dark-background-img;
    }

    &-info {
        padding: 12px;
        &-countdown {
            margin-bottom: 8px;
            .cm-countdown {
                display: flex;
                flex-wrap: wrap;
                align-items: baseline;
                opacity: 0;
                color: $grey-light;
                font-size: 12px;
                letter-spacing: 0.36px;
                gap: 0 18px;
                @include transition(opacity 100ms linear);
                &.active {
                    opacity: 1;
                }

                .block-available {
                    font-size: 12px;
                    flex: 1 0 100%;
                    margin-bottom: 8px;
                }

                .block-available,
                .block-days,
                .block-hours,
                .block-minutes,
                .block-seconds {
                    &.highlighting {
                        display: inline-block;
                        color: $cyan;
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 12px;
                        letter-spacing: 0.24px;
                    }
                }

                .block-days,
                .block-hours,
                .block-minutes {
                    display: none;
                    &.highlighting {
                        text-transform: uppercase;
                        span:first-child {
                            font-size: 16px;
                            line-height: 16px;
                        }
                    }
                    &.active {
                        display: inline-block;
                    }
                }

                .cm-countdown-progress {
                    @include pink-purple-green-blue-gradient();
                    margin-top: 12px;
                    display: block;
                    position: relative;
                    width: 100%;
                    height: 2px;
                    border-radius: 1.5px;
                    .cm-countdown-progress-bar {
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 0;
                        height: 2px;
                        z-index: 5;
                        background-color: $black;
                        border-radius: 0 1.5px 1.5px 0;
                        @include transition(width 300ms linear);
                    }
                    .cm-countdown-progress-bar-revert {
                        @include transition(width 300ms linear);
                        display: block;
                        flex: 1;
                        position: absolute;
                        height: 2px;
                        width: 0;
                        z-index: -1;
                        border-radius: 1.5px 0 0 1.5px;
                        background: inherit;
                        background-position: 0;
                        &.active {
                            &:after {
                                opacity: 1;
                                @include transition(opacity 500ms linear);
                            }
                        }
                        &:after {
                            @include transition(opacity 0ms);
                            content: '';
                            position: absolute;
                            z-index: -1;
                            bottom: -25%;
                            height: 140%;
                            left: 0;
                            width: 100%;
                            opacity: 0;
                            border-radius: 0;
                            background: inherit;
                            filter: blur(5px);
                        }
                    }
                }
            }
        }
        &-title {
            margin-bottom: 8px;
            margin-top: 8px;
            padding-top: 4px;
            border-top: 0.5px solid rgba($grey, 0.07);

            h3 {
                color: $light;
                font-size: 16px;
                line-height: 16px;
                letter-spacing: 0.36px;
                font-weight: 500;
                display: flex;
                align-items: baseline;
                .platforms-icons {
                    display: inline-block;
                    margin: 0;
                    height: 1.4rem;
                    .platforms-icon {
                        i {
                            margin-right: 2px;
                            color: $light;
                            font-size: 16px;
                            line-height: 16px;
                        }
                        & > span {
                            display: none;
                        }
                    }
                    .title-separator {
                        display: none;
                    }
                }
            }
        }
        &-description {
            display: none;
            p {
                color: $grey;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.6px;
            }
        }
        &-price {
            .price-box {
                text-align: right;

                .weee .price,
                .promo-percent .percent {
                    font-size: 16px;
                    line-height: 18px;
                }
            }
        }
        &-actions {
            margin: 12px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .action.primary {
                font-size: 16px;
                line-height: 16px;
                padding: 12px 18px;
            }
        }
    }

    &.splide {

        display: grid;
        grid-template-rows: 1fr auto;

        .splide__slide {
            display: grid;
            grid-template-rows: auto 1fr;

            .coming-soon-info {
                display: grid;
                grid-template-rows: auto 1fr auto auto auto;
            }
        }

        &__track {
            flex: 1;
        }
    }

    .slider-footer {
        margin: 0 12px;
        padding: 12px 0;
        border-top: 0.5px solid rgba($grey, 0.15);
        position: relative;
        width: calc(100% - 24px);
        user-select: none;
        z-index: 1;
        display: flex;
        align-items: center;
        .slider-pagination-coming-soon,
        .splide__arrow--prev,
        .splide__arrow--next {
            position: relative;
            display: inline-block;
            top: auto;
            transform: none;
            user-select: none;
            left: 0;
            right: 0;
            &:after {
                display: none;
            }
        }
        .slider-pagination-coming-soon {
            font-size: 20px;
            line-height: 20px;
            text-align: center;
            margin: 0 1rem;
            min-width: 40px;
        }
    }
}

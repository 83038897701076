.trustpilot-widget {
    text-align: center;

    iframe {
        opacity: 0.8;
    }

    a {
        font-weight: 400;
        font-size: 1.1rem;
        color: #a7a7a8;
        font-family: 'Segoe UI', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
        margin-left: 9px;
    }
}

.trustpilot-widget iframe {
    color-scheme: normal;
}

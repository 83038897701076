.gdpr-cookie-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    cursor: pointer;
}
.amgdprjs-bar-template {
    z-index: 1001 !important;

    .amgdprcookie-bar-container {
        background: $very-dark-1;
        @include border-block;
        border-bottom: unset;
        border-left: unset;
        border-right: unset;
        padding: 2rem 2rem 2rem;
        z-index: 1002;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        p {
            font-size: 1rem;
            color: $grey-light;
            letter-spacing: 0.6px;
            line-height: 16px;
        }
        .amgdprcookie-policy {
            a {
                font-size: 1.1rem;
                color: $light;
                &:after {
                    @include font-awesome;
                    content: '\f06e';
                    color: $turquoise;
                    margin-left: 0.5rem;
                }
            }
        }
        .amgdprcookie-buttons-block {
            display: flex;
            flex-direction: column;
            margin-top: 2rem;
            gap: 13px;
            button:active {
                @include active-button;
            }
            .amgdprcookie-button.-allow {
                @include green-blue-gradient;
                cursor: pointer;
                border: 0;
                box-shadow: 0px 0px 12px $black;
                font-family: $font-light;
                padding: 12px 15px;
                max-width: 100%;
                color: $light;
                font-weight: 300;
                font-size: 1.4rem;
                letter-spacing: 0.32px;
                line-height: 1.6rem;
                text-transform: capitalize;
                opacity: 1;
                text-align: center;
                border-radius: unset;
                @include transition(all 100ms linear);
                @media (hover: hover) {
                    &:hover,
                    &:active,
                    &:focus {
                        text-shadow: 0px 0px 8px $light;
                    }
                }

                &:active {
                    @include active-button;
                }

                &[disabled],
                &[disabled='true'],
                &.disabled {
                    cursor: default;
                    pointer-events: none;
                    background: $very-dark-2;
                    color: $grey;
                }
            }
            .amgdprcookie-button.-decline {
                background-color: $very-dark-1;
                box-shadow: 1px 1px 15px #000, -1px -1px 6px rgba(248, 247, 242, 0.21);
                border: 1px solid #232323;
                border-radius: 6px;
                text-align: center;
                display: inline-block;
                vertical-align: top;
                cursor: pointer;
                color: $turquoise;
                font-weight: 300;
                font-size: 1.4rem;
                letter-spacing: 0.32px;
                line-height: 1.6rem;
                text-transform: capitalize;
                font-family: $font-light;
                opacity: 1;
                padding: 12px 15px;
                filter: drop-shadow(0px 0px 0px transparent);
                @media (hover: hover) {
                    &:hover {
                        text-shadow: 0 0 10px $turquoise;
                    }
                }

                &:active {
                    @include active-button;
                }
            }
            .amgdprcookie-button.-settings {
                @include pink-purple-gradient;
                cursor: pointer;
                border: 0;
                box-shadow: 0px 0px 12px $black;
                font-family: $font-light;
                padding: 12px 15px;
                max-width: 100%;
                color: $light;
                font-weight: 300;
                font-size: 1.4rem;
                letter-spacing: 0.32px;
                line-height: 1.6rem;
                text-transform: capitalize;
                opacity: 1;
                text-align: center;
                border-radius: unset;
                @include transition(all 100ms linear);
                @media (hover: hover) {
                    &:hover,
                    &:active,
                    &:focus {
                        text-shadow: 0px 0px 8px $light;
                    }
                }
                &[disabled],
                &[disabled='true'],
                &.disabled {
                    cursor: default;
                    pointer-events: none;
                    background: $very-dark-2;
                    color: $grey;
                }

                &:active {
                    @include active-button;
                }
            }
        }
    }
    @media (min-width: 768px) {
        .amgdprcookie-bar-block {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            margin: auto;
            gap: 27px;
            .amgdprcookie-policy {
                text-align: justify;
                font-size: 0.8rem;
                a {
                    font-size: 0.9rem;
                }
            }
            .amgdprcookie-buttons-block {
                margin-top: 0;
            }
        }
    }
}

.amgdprcookie-groups-modal {
    z-index: 1020;
    .modal-header {
        .action-close {
            cursor: pointer;
        }
    }
    .amgdprcookie-done {
        @include pink-purple-gradient;
        cursor: pointer;
        border: 0;
        box-shadow: 0px 0px 12px $black;
        font-family: $font-light;
        font-size: 0.95rem;
        line-height: 0.95rem;
        padding: 0.75rem 2.5rem;
        max-width: 100%;
        letter-spacing: 0.4px;
        color: $light;
        opacity: 1;
        text-align: center;
        @include transition(all 100ms linear);
        @media (hover: hover) {
            &:hover,
            &:active,
            &:focus {
                text-shadow: 0px 0px 8px $light;
            }
        }
        &[disabled],
        &[disabled='true'],
        &.disabled {
            cursor: default;
            pointer-events: none;
            background: $very-dark-2;
            color: $grey;
        }
        &.action-primary {
            margin-right: 0.5rem;
        }
    }
    .modal-inner-wrap {
        background-color: $very-dark-1;
        box-shadow: 0px 0px 12px $black;
        border: 3px solid $dark-1;
        opacity: 1;
        max-width: 768px;
        @media (max-width: 991px) {
            overflow-x: scroll;
        }

        .action-close {
            @media (hover: hover) {
                &:hover {
                    @include pink-shadow;
                }
            }
            &:before {
                color: $pink;
            }
        }
        .modal-component {
            .amgdprcookie-description {
                display: none;
            }
            .amgdprcookie-main-wrapper {
                .amgdprcookie-settings-form {
                    .amgdprcookie-form-container {
                        height: calc(65vh - 2 * (3px + 15px) - (1.2rem + 3rem) - 60px - 18px);
                        overflow: auto;

                        &::-webkit-scrollbar {
                            width: 3px;
                            top: 2px;
                        }

                        &::-webkit-scrollbar-track {
                            background: $very-dark-2;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: $grey-dark;
                            border-radius: 20px; /* roundness of the scroll thumb */
                        }

                        .amgdprcookie-cookie-container {
                            padding: 18px 0 0 0;
                        }

                        .amgdprcookie-cookie-container:first-child {
                            padding: 0px 0 0 0;
                        }

                        .amgdprcookie-header {
                            justify-content: flex-start;
                            display: flex;
                            padding: 0 15px;
                            @media (min-width: $screen-md) {
                                margin-left: 2.5rem;
                            }
                            .amgdprcookie-caption {
                                margin-left: 1rem;
                                font-size: 16px;
                                line-height: 30px;
                                letter-spacing: 0.32px;
                                color: $grey-light;
                                opacity: 1;
                            }
                            .amgdprcookie-label {
                                width: 45px;
                                height: 24px;
                                &:before {
                                    display: none;
                                }
                                &:after {
                                    width: 20px;
                                    height: 20px;
                                }
                            }
                            .amgdprcookie-toggle-cookie {
                                padding-left: initial;
                                position: relative;
                                @media (min-width: 768px) and (max-width: 1023px) {
                                    margin-left: 3.5rem;
                                }
                                .amgdprcookie-input {
                                    height: 0;
                                    width: 0;
                                    visibility: hidden;
                                }
                                .amgdprcookie-label {
                                    width: 45px;
                                    height: 24px;
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    border-radius: 40px;
                                    cursor: pointer;
                                    background: #a5a5a5;
                                    &:after {
                                        position: absolute;
                                        top: 2px;
                                        left: 2px;
                                        width: 20px;
                                        height: 20px;
                                        border-radius: 40px;
                                        transition: all 0.4s ease;
                                        @include font-awesome;
                                        content: '';
                                        font-size: 12px;
                                        font-weight: 600;
                                        padding: 0.4rem 0.5rem;
                                        background: #fff;
                                    }
                                }
                                .amgdprcookie-input:checked:disabled + .amgdprcookie-label {
                                    background: $emerald-light;
                                    &:after {
                                        left: calc(95.5%);
                                        transform: translateX(-100%);
                                        content: '\f00d';
                                        background: $turquoise;
                                        color: $emerald-opacity;
                                        @media (min-width: 992px) {
                                            padding: 0.25rem 0.4rem;
                                        }
                                    }
                                }
                                .amgdprcookie-input:checked:not([disabled]) + .amgdprcookie-label {
                                    background: $emerald-light;
                                    &:after {
                                        left: calc(95.5%);
                                        transform: translateX(-100%);
                                    }
                                }
                            }
                        }
                        .amgdprcookie-text {
                            letter-spacing: 0.5px;
                            color: $grey;
                            opacity: 1;
                            line-height: 24px;
                            font-size: 1.2rem;
                            margin-bottom: 0.5rem;
                            margin-top: 0.5rem;
                            @media (min-width: 991px) {
                                font-size: 0.8rem !important;
                            }
                        }
                        .amgdprcookie-option-div {
                            display: flex;
                            align-items: flex-end;
                            .amgdprcookie-options {
                                background: transparent;
                                text-align: left;
                                letter-spacing: 0.36px;
                                opacity: 1;
                                &:before {
                                    display: none;
                                }
                                &:after {
                                    display: none;
                                }
                                @media (hover: hover) {
                                    &:hover {
                                        text-decoration: none;
                                        @include cyan-shadow;
                                        transition: all 0.5s ease-out;
                                        color: $light;
                                    }
                                }
                            }
                            .fa-chevron-down {
                                margin-top: 15px;
                                padding-left: 1rem;
                                &:before {
                                    color: $turquoise;
                                    margin-left: -0.5rem;
                                    font-size: 14px;
                                    @media (max-width: 991px) {
                                        margin-top: 0.5rem;
                                    }
                                }
                            }
                        }
                    }
                    .amgdprcookie-buttons-block.-settings {
                        justify-content: flex-end;
                        margin-top: 18px;
                        padding-top: 18px;
                        border-top: 1px solid #373738;
                        display: flex;
                        flex-direction: column;
                        .amgdprcookie-done-button {
                            display: flex;
                            justify-content: flex-end;
                        }
                        .amgdprcookie-done {
                            margin-top: 1rem;
                        }
                        .amgdprcookie-policy-text {
                            display: flex;
                            flex-direction: column;
                            color: $grey;
                            span {
                                font-size: 1.1rem;
                                line-height: 1.1rem;
                                letter-spacing: 0.36px;
                            }
                            a span {
                                font-size: 1.2rem;
                                &:after {
                                    @include font-awesome;
                                    content: '\f06e';
                                    color: $turquoise;
                                    margin-left: 0.25rem;
                                }
                            }
                        }
                    }
                }
            }
            .amgdprcookie-table {
                .amgdprcookie-title {
                    font-size: 0.8rem;
                    white-space: nowrap;
                }
                .amgdprcookie-row {
                    font-size: 0.8rem;
                }
            }
        }
    }
    .modal-footer {
        margin-top: 1rem;
    }
}

.amgdpr-modal-container,
.amgdprcookie-groups-modal {
    .modal-content {
        overflow: auto;
    }
    &.gdpr-cookie-container_setup-modal {
        .modal-content {
            overflow: hidden;
        }
    }
    .modal-inner-wrap {
        // max-height: 80vh;

        .modal-title {
            border-bottom: 1px solid $light-dark;
        }
    }
}

.exclusive-deals {
    display: block;
    margin: -50px 0 46px;

    .exclusive-deals-container {
        .exclusive-deal {
            box-shadow: 0 0 4px $black;

            &:not(:first-child) {
                margin-top: 24px;
            }
        }
        .ed-teaser-countdown,
        .ed-revealed-countdown,
        .ed-quantity-countdown {
            opacity: 0;
            font-size: 1rem;
            line-height: 1.4rem;
            letter-spacing: 0.36px;
            color: $grey-light;
            @include transition(opacity 100ms linear);

            &.active {
                opacity: 1;
            }

            .block-seconds {
                span:first-child {
                    width: 18px;
                    display: inline-block;
                    text-align: center;
                }
            }

            .block-available,
            .block-days,
            .block-hours,
            .block-minutes,
            .block-seconds {
                padding-right: 2px;

                span:first-child {
                    margin-right: 2px;
                }
            }

            .block-days,
            .block-hours,
            .block-minutes,
            .block-current,
            .block-seconds {
                display: none;

                &.highlighting {
                    display: inline-block;
                    text-transform: uppercase;
                    color: $cyan;
                    font-weight: 700;
                    &.no {
                        color: $pink;
                    }

                    span:first-child {
                        font-size: 1.4rem;
                        line-height: 1.2rem;
                        letter-spacing: 0.32px;
                    }

                    span {
                        font-size: 1rem;
                        line-height: 1.2rem;
                        letter-spacing: 0.24px;
                    }
                }

                &.active {
                    display: inline-block;
                }
            }

            .block-available {
                &.highlighting {
                    display: inline-block;
                    color: $cyan;
                    font-weight: 700;
                    font-size: 1.4rem;
                    line-height: 1.2rem;
                    letter-spacing: 0.32px;
                }
            }

            .countdown-progress {
                margin: 0.7rem auto 0;
                display: block;
                position: relative;
                height: 3px;
                width: 275px;
                border-radius: 1.5px;

                .countdown-progress-bar {
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 3px;
                    z-index: 5;
                    background-color: $black;
                    @include transition(width 300ms linear);
                }

                .countdown-progress-bar-revert {
                    display: block;
                    flex: 1;
                    position: absolute;
                    height: 3px;
                    width: 0;
                    z-index: -1;
                    background: inherit;
                    @include transition(width 300ms linear);

                    &:after {
                        @include transition(width 300ms linear);
                        content: '';
                        position: absolute;
                        z-index: -1;
                        bottom: -25%;
                        height: 140%;
                        width: 100%;
                        opacity: 1;
                        border-radius: 0;
                        background: inherit;
                        filter: blur(5px);
                    }
                }
            }
        }

        .ed-teaser-countdown {
            .countdown-progress {
                @include pink-purple-green-blue-gradient;
                .countdown-progress-bar-revert {
                    left: 0;
                    border-radius: 1.5px;
                    background-position: 0;

                    &:after {
                        left: 0;
                    }
                }

                .countdown-progress-bar {
                    right: 0;
                    border-radius: 0 1.5px 1.5px 0;
                }
            }
        }

        .ed-revealed-countdown,
        .ed-quantity-countdown {
            margin-top: 26px;

            .countdown-progress {
                @include pink-blue-green-purple-gradient;
                .countdown-progress-bar-revert {
                    right: 0;
                    border-radius: 1.5px;
                    width: 100%;
                    background-position: 100%;

                    &:after {
                        right: 0;
                    }
                }

                .countdown-progress-bar {
                    left: 0;
                    border-radius: 1.5px 0 0 1.5px;
                    width: 0;
                }
            }
        }

        .ed-quantity-countdown {
            color: $light;
            font-size: 0.9rem;
            line-height: 0.95rem;
            letter-spacing: 0.45px;
        }

        .teaser-item,
        .revealed-item {
            display: flex;
            align-items: center;
            position: relative;
            flex-direction: column-reverse;
            justify-content: flex-end;

            .anchor {
                position: absolute;
                top: -15%;
            }

            .information {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;

                > :not(.background) {
                    z-index: 2;
                }
            }

            .subscribe {
                margin: 24px 0 0;
                form {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    input[type='email'] {
                        max-width: 256px;
                    }
                    .checkbox-area {
                        display: flex;
                        position: relative;
                        input[type='checkbox'] {
                            display: block;
                            opacity: 0;
                            position: absolute;
                            top: 50%;
                            left: 0;
                            margin: 0;
                            & + label {
                                font-size: 0.95rem;
                                margin-top: 10px;
                                transition: all 0.3s ease;
                                cursor: pointer;

                                &:before {
                                    @include font-awesome;
                                    @include fa-linear-gradient-turquoise-blue;
                                    transform: rotate(0.001deg);
                                    display: inline-block;
                                    content: '\f0c8';
                                    margin-right: 5px;
                                }
                            }

                            &:checked + label:before {
                                content: '\f14a';
                            }
                        }
                    }

                    button.action {
                        margin-top: 19px;
                    }
                }
                .success,
                .error {
                    .content {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 19px;
                        color: $grey-light;
                        padding: 17px;
                        span {
                            margin-left: 15px;
                        }
                    }
                }
                .success {
                    background-color: #03dcff73;
                }
                .error {
                    background-color: #ff009c73;
                    .content {
                        margin-bottom: 19px;
                    }
                }
            }

            .reveal,
            .promo {
                z-index: 1;
                margin-top: 64px;
                width: 90%;

                img {
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }
            }
        }

        .teaser-item {
            min-height: 460px;
            @include turquoise-dark-gradient-reverse;
            .information {
                margin-top: -18%;
                //@include turquoise-cyan-border-gradient-lr;

                .subtitle {
                    font-family: $font-regular;
                    font-size: 1.7rem;
                    letter-spacing: 0.32px;
                    line-height: 1.7rem;
                    color: $light;
                }

                .title {
                    font-size: 2rem;
                    letter-spacing: 0.36px;
                    line-height: 2rem;
                    color: $grey;
                    margin: 1px 0 0;
                }

                .ed-teaser-countdown {
                    margin-top: 14.5%;
                }

                .subscribe {
                    .action.secondary {
                        margin-bottom: 30px;
                    }
                }
            }
        }

        .revealed-item {
            min-height: 500px;
            @include pink-dark-gradient-revert;

            .information {
                //@include pink-purple-border-gradient-lr;
                margin: 13px 0 33px;

                .missed {
                    margin: 26px 0 0;
                }

                .title {
                    font-size: 1.7rem;
                    letter-spacing: 0.32px;
                    line-height: 1.7rem;
                    color: $light;
                    padding: 0 1rem;
                    text-align: center;
                }

                .subtitle {
                    margin: 18px 5% 0;
                    font-size: 2rem;
                    letter-spacing: 0.32px;
                    line-height: 2rem;
                    color: $grey;
                    text-align: center;

                    .platforms-icons .platforms-icon {
                        font-family: inherit;

                        i {
                            font-size: 22px;
                            color: $grey;
                        }
                    }
                }

                .product-info {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-top: 13px;
                    width: 100%;

                    .price-box {
                        display: flex;
                        align-items: center;

                        .prices {
                            display: flex;

                            .old-price,
                            .new-price {
                                font-size: 1.4rem;
                                line-height: 1.4rem;
                                padding: 0;
                            }

                            .old-price {
                                color: $grey;
                                letter-spacing: 0.48px;
                                margin-right: 6px;
                            }

                            .new-price {
                                color: $light;
                                letter-spacing: 0.32px;
                            }
                        }

                        .promo-percent {
                            margin-left: 2px;
                            padding: 4.5px 6.5px;

                            .percent {
                                font-size: 1.4rem;
                                letter-spacing: 0.32px;
                                line-height: 1.6rem;
                            }
                        }
                    }
                    .cart {
                        margin-top: 13px;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        .wishlist {
                            position: absolute;
                            display: block;
                            left: 9.5%;
                        }
                    }
                }

                .subscribe {
                    margin-bottom: 0;
                    form {
                        .checkbox-area {
                            justify-content: center;
                        }
                        input[type='email'] {
                            background: linear-gradient(
                                    to right,
                                    $pink 0%,
                                    $pink 27%,
                                    $purple 49%,
                                    $purple 100%
                                )
                                left bottom $very-dark-2 no-repeat;
                            background-size: 100% 1px;
                        }
                        input[type='checkbox'] {
                            & + label {
                                font-size: 1.25rem;
                                letter-spacing: 0.5px;
                                max-width: 80%;
                                margin: 12px;
                                color: $white;
                                text-align: center;
                                &:before {
                                    background: $white;
                                    @include fa-common-gradient;
                                }
                            }
                        }
                    }
                }
            }
            .promo {
                position: relative;
                display: flex;
                justify-content: center;
                > a {
                    display: block;
                    flex: 1;
                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: linear-gradient(
                            to bottom,
                            transparent 0%,
                            transparent 55%,
                            black 100%
                        );
                    }
                    img {
                        width: 100%;
                    }
                }
                .info {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    width: 100%;
                    position: absolute;
                    bottom: 0;
                    padding: 0 0 28px 16px;
                    img {
                        max-height: 32px;
                        width: auto;
                        object-fit: contain;
                        align-self: flex-start;
                        @include pink-hover-shadow;
                    }
                    .details {
                        margin-top: 5px;
                        display: flex;
                        > a {
                            align-self: center;
                        }
                        .wishlist {
                            display: none;
                        }
                        .product-name {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 95%;
                            align-self: center;
                            @include pink-hover-shadow;
                            font-size: 1.7rem;

                            & > a {
                                font-size: inherit;
                                line-height: 1.7rem;
                                letter-spacing: 0;
                                color: $light;
                            }

                            .platforms-icons .platforms-icon {
                                font-family: inherit;
                                color: $light;

                                i {
                                    font-size: 22px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .subscribe {
            .action:not(.important) {
                display: none;
            }
        }
    }
}

.accordion-title {
    display: flex;
    cursor: pointer;
    letter-spacing: 0.5px;
    font-size: 14px;

    @media (hover: hover) {
        &:hover {
            color: $light;

            .accordion-btn {
                color: $cyan;
            }
        }
    }

    .accordion-btn {
        margin-left: 1rem;
        color: $cyan;
        height: fit-content;
        background-color: transparent;
        transform: rotateZ(0);
        transition: transform 0.2s ease-in-out;
    }
}
.accordion-panel {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-in-out;
}

.accordion-title.active {
    .accordion-btn {
        color: $pink;
        transform: rotateZ(-180deg);
    }
}

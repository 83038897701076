.message {
    font-size: 1rem;
    display: flex;
    width: 100%;
    padding: 0.5rem;
    font-family: $font-light;
    position: relative;
    color: $white;
    line-height: 2rem;
    margin-bottom: 1rem;

    &:before {
        @include font-awesome;
        padding: 0px 1.5rem 0px 1rem;
        display: flex;
        align-items: center;
        font-size: 2rem;
    }
    span {
        padding-left: 20px;
        letter-spacing: 0.5px;
        .logo-gameo {
            width: auto;
            height: 25px;
            vertical-align: middle;
        }
    }
    div,
    p {
        padding-left: 20px;
    }
    a {
        text-decoration: underline;
    }
    &.info {
        color: $turquoise;
        background-color: $turquoise-very-dark;
        a {
            color: $turquoise;
            font-size: 1rem;
        }
        &:before {
            content: '\f05a';
            @include turquoise-blue-gradient;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            border-right: 1px solid $turquoise;
        }
    }
    &.success {
        color: $turquoise;
        background: $turquoise-very-dark;
        a {
            color: $turquoise;
            font-size: 1rem;
        }
        &:before {
            content: '\f058';
            @include turquoise-blue-gradient;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            border-right: 1px solid $turquoise;
        }
    }
    &.notice,
    &.error {
        color: $pink-light;
        background-color: $pink-very-dark;
        &:before {
            content: '\f071';
            @include font-awesome;
            @include pink-gradient;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            border-right: 1px solid $pink;
        }
    }
    &.warning {
        span {
            padding-left: 0;
            color: $pink;
            font-size: 1rem;

            &:before {
                @include font-awesome;
                content: '\f071';
                @include pink-gradient;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin-right: 9px;
                font-weight: 600;
            }
        }
    }
    &.empty-minicart {
        margin-top: 2rem;
        display: flex;
        &:before {
            content: '\f290';
            font-weight: 300;
            color: #00fff1;
            font-size: 22px;
            margin-right: 1rem;
        }
        .text-content {
            padding-left: 0;
            display: flex;
            flex-direction: column;
            .text {
                padding-left: 0;
                font-size: 1rem;
                border-left: none;
                @media (min-width: 769px) {
                    font-size: 0.8rem;
                }
            }
        }
        .second-row {
            padding-left: 0;
            display: flex;
            span {
                width: 45%;
                padding-left: 0;
                margin-right: 0.25rem;
                border-left: none;
                margin-top: 3px;
            }
            p.text {
                margin-top: 0.4rem;
            }
        }
    }
}

//Cart & Minicart
.checkout-cart-index {
    .product-item-details {
        .message.error {
            &:before {
                display: none !important;
            }
            &:last-of-type {
                display: none;
            }
        }
    }
    .message {
        padding: 0.75rem;
        &.error {
            &:before {
                content: '\f071';
                @include font-awesome;
                @include pink-gradient;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                border-right: 1px solid $pink;
            }
        }
        &.info {
            &:before {
                display: none !important;
            }
            .cart-empty {
                padding-left: 20px;
                justify-content: center;
                flex-direction: row !important;
                @media (max-width: 767px) {
                    justify-content: flex-start;
                    font-size: 1.2rem;
                }
                &:before {
                    @include font-awesome;
                    padding-right: 24px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    font-size: 2rem;
                    content: '\f05a';
                    @include turquoise-blue-gradient;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    border-right: 1px solid $turquoise;
                    margin-right: 21px;
                    @media (min-width: 768px) {
                        font-size: 1.4rem;
                        padding-right: 1rem;
                        margin-right: 1rem;
                    }
                }
            }
            .content {
                display: flex;
                align-items: center;
                flex-direction: column;
                padding: 0px;
                @media (min-width: 768px) {
                    flex-direction: row;
                }
                .message-text {
                    padding-left: 3px !important;
                }
                .second-row {
                    padding-left: 0.25rem;
                    display: flex;
                    a {
                        height: 1.8rem;
                        width: auto;
                        img {
                            height: 100%;
                            width: auto;
                            margin-top: -0.25rem;
                        }
                    }
                }
            }
        }
        p {
            padding: 0px;
        }
    }
}
.block-minicart {
    .message.info {
        &:before {
            display: flex !important;
        }
    }
    .message.notice {
        display: none;
    }
}

// Estimate Delivery Date
#preparation-time-cart {
    padding: 0.5rem;
    &:before {
        display: none;
    }
}
#estimated-delivery-date {
    margin-top: 1rem;
    &:before {
        display: none;
    }
}

// Wishlist
.form-wishlist-items {
    .message {
        &:before {
            content: none;
        }
        i:before {
            padding-right: 1rem;
            height: 100%;
            display: flex;
            align-items: center;
            @include turquoise-blue-gradient;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            border-right: 1px solid $turquoise;
            @media (max-width: 767px) {
                padding-left: 1rem;
                margin-right: 1rem;
                font-size: 1.5rem;
            }
        }
        span {
            letter-spacing: 0.5px;
        }
    }
}

body {
    margin: 0;
    padding: 0;
}
article,
aside,
.filter-options-content details,
figcaption,
figure,
main,
footer,
header,
nav,
section,
summary {
    display: block;
}
audio,
canvas,
video {
    display: inline-block;
}
audio:not([controls]) {
    display: none;
    height: 0;
}
nav ul,
nav ol {
    list-style: none none;
}
img {
    max-width: 100%;
    height: auto;
    border: 0;
}
video,
embed,
object {
    max-width: 100%;
}
svg:not(:root) {
    overflow: hidden;
}
figure {
    margin: 0;
}
abbr[title] {
    border-bottom: 1px dotted #d1d1d1;
    cursor: help;
}
b,
strong {
    font-weight: 700;
}
em,
i {
    font-style: italic;
}
mark {
    background: #f0f0f0;
    color: #000000;
}
small,
.small {
    font-size: 12px;
}
hr {
    border: 0;
    border-top: 1px solid #d1d1d1;
    margin-bottom: 20px;
    margin-top: 20px;
}
sub,
sup {
    font-size: 71.42857143000001%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sup {
    top: -0.5em;
}
sub {
    bottom: -0.25em;
}
dfn {
    font-style: italic;
}
a,
.alink {
    text-decoration: none;
}
a:visited,
.alink:visited {
    text-decoration: none;
}
a:hover,
.alink:hover {
    text-decoration: underline;
}
a:active,
.alink:active {
    text-decoration: underline;
}
ul,
ol {
    margin-top: 0rem;
    margin-bottom: 2.5rem;
}
ul > li,
ol > li {
    margin-top: 0rem;
}
ul ul,
ol ul,
ul ol,
ol ol {
    margin-bottom: 0;
}
dl {
    margin-bottom: 20px;
    margin-top: 0;
}
dt {
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 0;
}
dd {
    margin-bottom: 10px;
    margin-top: 0;
    margin-left: 0;
}
code,
kbd,
pre,
samp {
    font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
}
code {
    background: #f0f0f0;
    color: #111111;
    padding: 2px 4px;
    font-size: 1.2rem;
    white-space: nowrap;
}
kbd {
    background: #f0f0f0;
    color: #111111;
    padding: 2px 4px;
    font-size: 1.2rem;
}
pre {
    background: #f0f0f0;
    border: 1px solid #d1d1d1;
    color: #111111;
    line-height: 1.42857143;
    margin: 0 0 10px;
    padding: 10px;
    font-size: 1.2rem;
    display: block;
    word-wrap: break-word;
}
pre code {
    background-color: transparent;
    border-radius: 0;
    color: inherit;
    font-size: inherit;
    padding: 0;
    white-space: pre-wrap;
}
blockquote {
    border-left: 0 solid #d1d1d1;
    margin: 0 0 20px 40px;
    padding: 0;
    color: #333333;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: italic;
    font-weight: 400;
    line-height: 1.42857143;
    font-size: 1.4rem;
}
blockquote p:last-child,
blockquote ul:last-child,
blockquote ol:last-child {
    margin-bottom: 0;
}
blockquote footer,
blockquote small,
blockquote .small {
    color: #333333;
    line-height: 1.42857143;
    font-size: 1rem;
    display: block;
}
blockquote footer:before,
blockquote small:before,
blockquote .small:before {
    content: '\2014 \00A0';
}
blockquote cite {
    font-style: normal;
}
blockquote:before,
blockquote:after {
    content: '';
}
q {
    quotes: none;
}
q:before,
q:after {
    content: '';
    content: none;
}
cite {
    font-style: normal;
}
table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    max-width: 100%;
}
table th {
    text-align: left;
}

table > thead > tr > th,
table > thead > tr > td {
    vertical-align: bottom;
}
table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th,
table > thead > tr > td,
table > tbody > tr > td,
table > tfoot > tr > td {
    padding: 8px 10px;
}
button::-moz-focus-inner,
.cart.table-wrapper .actions-toolbar > .action::-moz-focus-inner {
    border: 0;
    padding: 0;
}

select {
    background: #ffffff;
    background-clip: padding-box;
    border: 1px solid #c2c2c2;
    border-radius: 1px;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    height: 32px;
    line-height: 1.42857143;
    padding: 5px 10px 4px;
    vertical-align: baseline;
    width: 100%;
    box-sizing: border-box;
}
select:disabled {
    opacity: 0.5;
}
select[multiple] {
    height: auto;
    background-image: none;
}
textarea {
    background: #ffffff;
    background-clip: padding-box;
    border: 1px solid #c2c2c2;
    border-radius: 1px;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    height: auto;
    line-height: 1.42857143;
    margin: 0;
    padding: 10px;
    vertical-align: baseline;
    width: 100%;
    box-sizing: border-box;
    resize: vertical;
}
textarea:disabled {
    opacity: 0.5;
}
textarea::-moz-placeholder {
    color: #c2c2c2;
}
textarea::-webkit-input-placeholder {
    color: #c2c2c2;
}
textarea:-ms-input-placeholder {
    color: #c2c2c2;
}
input[type='checkbox'] {
    margin: 2px 5px 0 0;
    position: relative;
    top: 2px;
}
input[type='checkbox']:disabled {
    opacity: 0.5;
}
input[type='radio'] {
    margin: 2px 5px 0 0;
}
input[type='radio']:disabled {
    opacity: 0.5;
}

input[type='button'],
input[type='reset'],
input[type='submit'] {
    cursor: pointer;
    -webkit-appearance: button;
}

input::-moz-focus-inner {
    border: 0;
    padding: 0;
}
form {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
address {
    font-style: normal;
}
*:focus {
    box-shadow: none;
    outline: 0;
}

/**
 * Copyright Â© Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */
.product-items,
.prices-tier,
.cart-container .checkout-methods-items,
.opc-wrapper .opc,
.opc-progress-bar,
.checkout-agreements-items,
.items,
.order-details-items .items-qty {
    margin: 0;
    padding: 0;
    list-style: none none;
}

.cart.table-wrapper .item-options dt,
.opc-block-summary .items-in-cart .product .item-options dt {
    clear: left;
    float: left;
    margin: 0 10px 5px 0;
}
.cart.table-wrapper .item-options dt:after,
.opc-block-summary .items-in-cart .product .item-options dt:after {
    content: ': ';
}
.cart.table-wrapper .item-options dd,
.opc-block-summary .items-in-cart .product .item-options dd {
    display: inline-block;
    float: left;
    margin: 0 0 5px;
}
.actions-toolbar > .primary .action,
.actions-toolbar > .secondary .action {
    width: 100%;
}
.products-list .product-image-wrapper,
.cart.table-wrapper .product-image-wrapper,
.minicart-items .product-image-wrapper {
    height: auto;
    padding: 0 !important;
}
.products-list .product-image-wrapper .product-image-photo,
.cart.table-wrapper .product-image-wrapper .product-image-photo,
.minicart-items .product-image-wrapper .product-image-photo {
    position: static;
}
.product.media .product.photo .photo.image {
    display: block;
    height: auto;
    margin: 0 auto;
    max-width: 100%;
}
.login-container .block .block-title {
    font-size: 1.8rem;
}
.login-container .block .block-title strong {
    font-weight: 500;
}

.abs-account-blocks .block-title > strong,
.block-compare .block-title > strong,
.block-reorder .block-title > strong,
.widget .block-title > strong,
.block-wishlist .block-title > strong,
.account .column.main .block:not(.widget) .block-title > strong,
[class^='sales-guest-'] .column.main .block:not(.widget) .block-title > strong,
.sales-guest-view .column.main .block:not(.widget) .block-title > strong {
    font-weight: 300;
    line-height: 1.1;
    font-size: 1.8rem;
}
.account .column.main .block:not(.widget) .block-title > .action,
[class^='sales-guest-'] .column.main .block:not(.widget) .block-title > .action,
.sales-guest-view .column.main .block:not(.widget) .block-title > .action {
    margin-left: 15px;
}
.account .column.main .block:not(.widget) .box-title,
[class^='sales-guest-'] .column.main .block:not(.widget) .box-title,
.sales-guest-view .column.main .block:not(.widget) .box-title {
    display: inline-block;
    margin: 0 0 5px;
}
.account .column.main .block:not(.widget) .box-title > span,
[class^='sales-guest-'] .column.main .block:not(.widget) .box-title > span,
.sales-guest-view .column.main .block:not(.widget) .box-title > span {
    font-weight: 700;
    line-height: 1.1;
    font-size: 1.4rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.account .column.main .block:not(.widget) .box-title > .action,
[class^='sales-guest-'] .column.main .block:not(.widget) .box-title > .action,
.sales-guest-view .column.main .block:not(.widget) .box-title > .action {
    font-weight: 400;
    margin-left: 10px;
}
.account .column.main .block:not(.widget) .block-content p:last-child,
[class^='sales-guest-'] .column.main .block:not(.widget) .block-content p:last-child,
.sales-guest-view .column.main .block:not(.widget) .block-content p:last-child {
    margin-bottom: 0;
}

.abs-dropdown-simple:before,
.abs-dropdown-simple:after {
    content: '';
    display: table;
}
.abs-dropdown-simple:after {
    clear: both;
}
.abs-dropdown-simple .action.toggle {
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
}
.abs-dropdown-simple .action.toggle:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: 22px;
    color: inherit;
    content: '\e607';
    font-family: 'icons-blank-theme';
    margin: 0 0 0 5px;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.abs-dropdown-simple .action.toggle:hover:after {
    color: inherit;
}
.abs-dropdown-simple .action.toggle:active:after {
    color: inherit;
}
.abs-dropdown-simple .action.toggle.active {
    display: inline-block;
    text-decoration: none;
}
.abs-dropdown-simple .action.toggle.active:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: 22px;
    color: inherit;
    content: '\e618';
    font-family: 'icons-blank-theme';
    margin: 0 0 0 5px;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.abs-dropdown-simple .action.toggle.active:hover:after {
    color: inherit;
}
.abs-dropdown-simple .action.toggle.active:active:after {
    color: inherit;
}
.abs-dropdown-simple ul.dropdown {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #ffffff;
    border: 1px solid #bbbbbb;
    margin-top: 4px;
    min-width: 200px;
    z-index: 100;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
}
.abs-dropdown-simple ul.dropdown li {
    margin: 0;
    padding: 5px 5px 5px 23px;
}
.abs-dropdown-simple ul.dropdown li:hover {
    background: #e8e8e8;
    cursor: pointer;
}
.abs-dropdown-simple ul.dropdown:before,
.abs-dropdown-simple ul.dropdown:after {
    border-bottom-style: solid;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0;
}
.abs-dropdown-simple ul.dropdown:before {
    border: 6px solid;
    border-color: transparent transparent #ffffff transparent;
    z-index: 99;
}
.abs-dropdown-simple ul.dropdown:after {
    border: 7px solid;
    border-color: transparent transparent #bbbbbb transparent;
    z-index: 98;
}
.abs-dropdown-simple ul.dropdown:before {
    left: 10px;
    top: -12px;
}
.abs-dropdown-simple ul.dropdown:after {
    left: 9px;
    top: -14px;
}
.abs-dropdown-simple.active {
    overflow: visible;
}
.abs-dropdown-simple.active ul.dropdown {
    display: block;
}
.cart.table-wrapper .col.qty .input-text,
.account .table-return-items .qty .input-text,
.account .table-wrapper .data.table.wishlist .box-tocart .qty,
.products-grid.wishlist .product-item .box-tocart input.qty {
    text-align: center;
    width: 47px;
}
.sidebar .widget.block:not(:last-child),
.sidebar .widget:not(:last-child) {
    margin-bottom: 40px;
}
.sidebar .action.delete,
.table-comparison .cell.remove .action.delete,
.block-compare .action.delete,
.filtered .item .action.remove {
    display: inline-block;
    text-decoration: none;
}
.abs-remove-button-for-blocks > span,
.sidebar .action.delete > span,
.table-comparison .cell.remove .action.delete > span,
.block-compare .action.delete > span,
.filtered .item .action.remove > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.abs-remove-button-for-blocks:before,
.sidebar .action.delete:before,
.table-comparison .cell.remove .action.delete:before,
.block-compare .action.delete:before,
.filtered .item .action.remove:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 26px;
    line-height: 15px;
    color: #303030;
    content: '\e616';
    font-family: 'icons-blank-theme';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.abs-remove-button-for-blocks:hover:before,
.sidebar .action.delete:hover:before,
.table-comparison .cell.remove .action.delete:hover:before,
.block-compare .action.delete:hover:before,
.filtered .item .action.remove:hover:before {
    color: #303030;
}
.abs-remove-button-for-blocks:active:before,
.sidebar .action.delete:active:before,
.table-comparison .cell.remove .action.delete:active:before,
.block-compare .action.delete:active:before,
.filtered .item .action.remove:active:before {
    color: #303030;
}
.product-item-name,
.product.name a {
    font-weight: 400;
}
.product-item-name > a,
.product.name a > a {
    text-decoration: none;
}
.abs-product-link > a:visited,
.product-item-name > a:visited,
.product.name a > a:visited {
    text-decoration: underline;
}
.abs-product-link > a:hover,
.product-item-name > a:hover,
.product.name a > a:hover {
    text-decoration: underline;
}
.abs-product-link > a:active,
.product-item-name > a:active,
.product.name a > a:active {
    text-decoration: underline;
}
.form.wishlist.items .actions-toolbar {
    margin-left: 0;
}
.abs-add-fields .action.remove {
    left: 10px;
    margin-left: 70%;
    position: absolute;
    top: 31px;
    width: auto;
}
.fieldset > .field.no-label > .label,
.fieldset > .fields > .field.no-label > .label,
.product-item-actions .actions-secondary > .action span,
.table-comparison .cell.label.remove span,
.table-comparison .cell.label.product span,
.cart.table-wrapper .col.qty .label,
.minicart-wrapper .action.showcart .text,
.minicart-wrapper .action.showcart .counter-label,
.checkout-index-index .page-title-wrapper,
.checkout-payment-method .field-select-billing > .label,
.checkout-payment-method .payments .legend,
.checkout-payment-method .ccard .legend,
.fieldset .fullname.field > .label,
.account .column.main .limiter > .label,
.field.street .field .label,
.filter-options-content .filter-count-label,
.block-reorder .product-item .label,
.opc-wrapper .form-discount .field .label,
.action.skip:not(:focus),
.page-header .panel.wrapper .switcher .label,
.page-footer .switcher .label,
.products-grid.wishlist .product-item .comment-box .label {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.shipping-policy-block.field-tooltip .field-tooltip-action span {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}
.abs-add-clearfix:before,
.abs-add-clearfix:after,
.abs-add-fields .fieldset .actions-toolbar:not(:first-child):before,
.abs-add-fields .fieldset .actions-toolbar:not(:first-child):after,
.toolbar:before,
.toolbar:after,
.toolbar-products:before,
.toolbar-products:after,
.sidebar .product-items-images:before,
.sidebar .product-items-images:after,
.cart.table-wrapper .actions-toolbar:before,
.cart.table-wrapper .actions-toolbar:after,
.cart.table-wrapper .item-options:before,
.cart.table-wrapper .item-options:after,
.minicart-items .product-item > .product:before,
.minicart-items .product-item > .product:after,
.checkout-container:before,
.checkout-container:after,
.checkout-onepage-success:before,
.checkout-onepage-success:after,
.opc-estimated-wrapper:before,
.opc-estimated-wrapper:after,
.opc-wrapper .field.addresses:before,
.opc-wrapper .field.addresses:after,
.opc-block-summary .items-in-cart .product .item-options:before,
.opc-block-summary .items-in-cart .product .item-options:after,
.fieldset .fullname .field:before,
.fieldset .fullname .field:after,
.header.content:before,
.header.content:after {
    content: '';
    display: table;
}
.abs-add-clearfix:after,
.abs-add-fields .fieldset .actions-toolbar:not(:first-child):after,
.toolbar:after,
.toolbar-products:after,
.sidebar .product-items-images:after,
.cart.table-wrapper .actions-toolbar:after,
.cart.table-wrapper .item-options:after,
.minicart-items .product-item > .product:after,
.checkout-container:after,
.checkout-onepage-success:after,
.opc-estimated-wrapper:after,
.opc-wrapper .field.addresses:after,
.opc-block-summary .items-in-cart .product .item-options:after,
.fieldset .fullname .field:after,
.header.content:after {
    clear: both;
}
.abs-field-date .control,
.abs-field-tooltip,
.columns .column.main,
.columns .sidebar-main,
.columns .sidebar-additional,
.product-item,
.sidebar .product-items-images .product-item,
.cart-summary,
.opc-wrapper .shipping-address-item,
.shipping-policy-block.field-tooltip .field-tooltip-content,
.opc-block-summary,
.field.date .control,
.field .control._with-tooltip {
    box-sizing: border-box;
}
.abs-navigation-icon:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 34px;
    line-height: 1.2;
    color: inherit;
    content: '\e622';
    font-family: 'icons-blank-theme';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.abs-split-button:before,
.abs-split-button:after {
    content: '';
    display: table;
}
.abs-split-button:after {
    clear: both;
}
.abs-split-button .action.split {
    float: left;
    margin: 0;
}
.abs-split-button .action.toggle {
    float: right;
    margin: 0;
}
.abs-split-button button.action.split,
.abs-split-button .cart.table-wrapper .actions-toolbar > .action.action.split {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}
.abs-split-button button + .action.toggle,
.abs-split-button .cart.table-wrapper .actions-toolbar > .action + .action.toggle {
    border-bottom-left-radius: 0;
    border-left: 0;
    border-top-left-radius: 0;
}
.abs-split-button .action.toggle {
    padding: 4px 5px;
    display: inline-block;
    text-decoration: none;
}
.abs-split-button .action.toggle > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.abs-split-button .action.toggle:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: 22px;
    color: inherit;
    content: '\e607';
    font-family: 'icons-blank-theme';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.abs-split-button .action.toggle:hover:after {
    color: inherit;
}
.abs-split-button .action.toggle:active:after {
    color: inherit;
}
.abs-split-button .action.toggle.active {
    display: inline-block;
    text-decoration: none;
}
.abs-split-button .action.toggle.active > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.abs-split-button .action.toggle.active:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: 22px;
    color: inherit;
    content: '\e618';
    font-family: 'icons-blank-theme';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.abs-split-button .action.toggle.active:hover:after {
    color: inherit;
}
.abs-split-button .action.toggle.active:active:after {
    color: inherit;
}
.abs-split-button .items {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #ffffff;
    border: 1px solid #bbbbbb;
    margin-top: 4px;
    min-width: 100%;
    z-index: 100;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
}
.abs-split-button .items li {
    margin: 0;
    padding: 3px 5px;
}
.abs-split-button .items li:hover {
    background: #e8e8e8;
    cursor: pointer;
}
.abs-split-button .items:before,
.abs-split-button .items:after {
    border-bottom-style: solid;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0;
}
.abs-split-button .items:before {
    border: 6px solid;
    border-color: transparent transparent #ffffff transparent;
    z-index: 99;
}
.abs-split-button .items:after {
    border: 7px solid;
    border-color: transparent transparent #bbbbbb transparent;
    z-index: 98;
}
.abs-split-button .items:before {
    right: 10px;
    top: -12px;
}
.abs-split-button .items:after {
    right: 9px;
    top: -14px;
}
.abs-split-button.active {
    overflow: visible;
}
.abs-split-button.active .items {
    display: block;
}

.abs-action-remove,
.cart.table-wrapper .action.help.map,
.opc-wrapper .edit-address-link,
.opc-block-shipping-information .shipping-information-title .action-edit,
.action-auth-toggle,
.checkout-payment-method .checkout-billing-address .action-cancel,
.checkout-agreements-block .action-show,
.abs-add-fields .action.remove {
    line-height: 1.42857143;
    padding: 0;
    text-decoration: none;
    background: none;
    border: 0;
    display: inline;
    border-radius: 0;
    font-size: inherit;
    font-weight: 400;
}
.abs-action-button-as-link:visited,
.abs-action-remove:visited,
.cart.table-wrapper .action.help.map:visited,
.opc-wrapper .edit-address-link:visited,
.opc-block-shipping-information .shipping-information-title .action-edit:visited,
.action-auth-toggle:visited,
.checkout-payment-method .checkout-billing-address .action-cancel:visited,
.checkout-agreements-block .action-show:visited,
.abs-add-fields .action.remove:visited {
    color: #1979c3;
    text-decoration: none;
}

.abs-action-button-as-link:hover,
.abs-action-button-as-link:active,
.abs-action-button-as-link:focus,
.abs-action-remove:hover,
.abs-action-remove:active,
.abs-action-remove:focus,
.cart.table-wrapper .action.help.map:hover,
.cart.table-wrapper .action.help.map:active,
.cart.table-wrapper .action.help.map:focus,
.opc-wrapper .edit-address-link:hover,
.opc-wrapper .edit-address-link:active,
.opc-wrapper .edit-address-link:focus,
.opc-block-shipping-information .shipping-information-title .action-edit:hover,
.opc-block-shipping-information .shipping-information-title .action-edit:active,
.opc-block-shipping-information .shipping-information-title .action-edit:focus,
.action-auth-toggle:hover,
.action-auth-toggle:active,
.action-auth-toggle:focus,
.checkout-payment-method .checkout-billing-address .action-cancel:hover,
.checkout-payment-method .checkout-billing-address .action-cancel:active,
.checkout-payment-method .checkout-billing-address .action-cancel:focus,
.checkout-agreements-block .action-show:hover,
.checkout-agreements-block .action-show:active,
.checkout-agreements-block .action-show:focus,
.abs-add-fields .action.remove:hover,
.abs-add-fields .action.remove:active,
.abs-add-fields .action.remove:focus {
    background: none;
    border: 0;
}
.abs-action-button-as-link.disabled,
.abs-action-button-as-link[disabled],
fieldset[disabled] .abs-action-button-as-link,
.abs-action-remove.disabled,
.abs-action-remove[disabled],
fieldset[disabled] .abs-action-remove,
.cart.table-wrapper .action.help.map.disabled,
.cart.table-wrapper .action.help.map[disabled],
fieldset[disabled] .cart.table-wrapper .action.help.map,
.opc-wrapper .edit-address-link.disabled,
.opc-wrapper .edit-address-link[disabled],
fieldset[disabled] .opc-wrapper .edit-address-link,
.opc-block-shipping-information .shipping-information-title .action-edit.disabled,
.opc-block-shipping-information .shipping-information-title .action-edit[disabled],
fieldset[disabled] .opc-block-shipping-information .shipping-information-title .action-edit,
.action-auth-toggle.disabled,
.action-auth-toggle[disabled],
fieldset[disabled] .action-auth-toggle,
.checkout-payment-method .checkout-billing-address .action-cancel.disabled,
.checkout-payment-method .checkout-billing-address .action-cancel[disabled],
fieldset[disabled] .checkout-payment-method .checkout-billing-address .action-cancel,
.checkout-agreements-block .action-show.disabled,
.checkout-agreements-block .action-show[disabled],
fieldset[disabled] .checkout-agreements-block .action-show,
.abs-add-fields .action.remove.disabled,
.abs-add-fields .action.remove[disabled],
fieldset[disabled] .abs-add-fields .action.remove {
    color: #1979c3;
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline;
}

.product-options-wrapper .field .price-notice .price-including-tax,
.product-options-wrapper .field .price-notice .price-excluding-tax,
.product-options-wrapper .field .price-notice .weee,
.cart-summary .price-including-tax,
.cart-summary .price-excluding-tax,
.cart-summary .weee {
    font-size: 1.4rem;
    display: inline-block;
    white-space: nowrap;
}
.product-options-wrapper .field .price-notice .price-including-tax + .price-excluding-tax,
.cart-summary .price-including-tax + .price-excluding-tax {
    display: inline-block;
    font-size: 1.1rem;
}
.product-options-wrapper .field .price-notice .price-including-tax + .price-excluding-tax:before,
.cart-summary .price-including-tax + .price-excluding-tax:before {
    content: '(' attr(data-label) ': ';
}
.product-options-wrapper .field .price-notice .price-including-tax + .price-excluding-tax:after,
.cart-summary .price-including-tax + .price-excluding-tax:after {
    content: ')';
}
.price-including-tax .cart-tax-total,
.price-excluding-tax .cart-tax-total {
    cursor: pointer;
    padding-right: 12px;
    position: relative;
    display: inline-block;
    text-decoration: none;
}
.abs-tax-total:after,
.price-including-tax .cart-tax-total:after,
.price-excluding-tax .cart-tax-total:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 26px;
    line-height: 10px;
    color: inherit;
    content: '\e622';
    font-family: 'icons-blank-theme';
    margin: 3px 0 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.price-including-tax .cart-tax-total:after,
.price-excluding-tax .cart-tax-total:after {
    position: absolute;
    right: -10px;
    top: 3px;
}
.abs-tax-total-expanded:after {
    content: '\e621';
}
.abs-tax-total-expanded:after,
.price-including-tax .cart-tax-total-expanded:after,
.price-excluding-tax .cart-tax-total-expanded:after {
    content: '\e621';
}
.abs-dropdown-items-new .action.new {
    display: inline-block;
    text-decoration: none;
}
.abs-icon-add:before,
.abs-dropdown-items-new .action.new:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 10px;
    line-height: 10px;
    color: inherit;
    content: '\e61c';
    font-family: 'icons-blank-theme';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.abs-sidebar-totals .table-caption,
.sidebar .subtitle,
.cart-summary .block .fieldset.estimate > .legend,
.cart-summary .block .fieldset.estimate > .legend + br,
.minicart-items .product-item-details .weee[data-label] .label,
.block-reorder .subtitle,
.sidebar-additional .block-reorder .subtitle,
.no-display,
.cart-totals .table-caption,
.opc-block-summary .table-totals .table-caption {
    display: none;
}
.order-status {
    display: inline-block;
    margin-bottom: 20px;
}
.block-compare .counter,
.block-wishlist .counter {
    color: #7d7d7d;
    font-size: 1.2rem;
    white-space: nowrap;
}
.cart-container .form-cart .action.continue {
    border-radius: 3px;
    background-image: none;
    background: #f2f2f2;
    border: 1px solid #cdcdcd;
    color: #333333;
    cursor: pointer;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 700;
    margin: 0;
    padding: 7px 15px 7px 0;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-block;
    text-decoration: none;
}
.abs-shopping-cart-items .action.continue:hover,
.abs-shopping-cart-items .action.continue:active,
.abs-shopping-cart-items .action.continue:focus,
.cart-container .form-cart .action.continue:hover,
.cart-container .form-cart .action.continue:active,
.cart-container .form-cart .action.continue:focus {
    text-decoration: none;
}
.abs-shopping-cart-items .action.continue:before,
.cart-container .form-cart .action.continue:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 32px;
    line-height: 16px;
    color: inherit;
    content: '\e617';
    font-family: 'icons-blank-theme';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.abs-shopping-cart-items .action.continue:hover:before,
.cart-container .form-cart .action.continue:hover:before {
    color: inherit;
}
.abs-shopping-cart-items .action.continue:active:before,
.cart-container .form-cart .action.continue:active:before {
    color: inherit;
}
.abs-shopping-cart-items .action.continue:focus,
.abs-shopping-cart-items .action.continue:active,
.cart-container .form-cart .action.continue:focus,
.cart-container .form-cart .action.continue:active {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: #333333;
}
.abs-shopping-cart-items .action.continue:hover,
.cart-container .form-cart .action.continue:hover {
    background: #e2e2e2;
    border: 1px solid #cdcdcd;
    color: #555555;
}
.abs-shopping-cart-items .action.continue.disabled,
.abs-shopping-cart-items .action.continue[disabled],
fieldset[disabled] .abs-shopping-cart-items .action.continue,
.cart-container .form-cart .action.continue.disabled,
.cart-container .form-cart .action.continue[disabled],
fieldset[disabled] .cart-container .form-cart .action.continue {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
}
.cart-container .form-cart .action.continue:active {
    box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.12);
}
.cart-container .form-cart .action.update {
    display: inline-block;
    text-decoration: none;
    padding-left: 5px;
}
.abs-shopping-cart-items .action.update:before,
.cart-container .form-cart .action.update:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 32px;
    line-height: 16px;
    color: inherit;
    content: '\e603';
    font-family: 'icons-blank-theme';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.abs-shopping-cart-items .action.update:hover:before,
.cart-container .form-cart .action.update:hover:before {
    color: inherit;
}
.abs-shopping-cart-items .action.update:active:before,
.cart-container .form-cart .action.update:active:before {
    color: inherit;
}
.field.date .control {
    position: relative;
}
.abs-field-date input,
.field.date input {
    margin-right: 10px;
    width: calc(100% - 50px);
}
.field .control._with-tooltip {
    position: relative;
}
.field .control._with-tooltip input {
    margin-right: 10px;
    width: calc(100% - 39px);
}
.checkout-index-index .modal-popup .field-tooltip .field-tooltip-content,
.shipping-policy-block.field-tooltip .field-tooltip-content {
    right: -10px;
    top: 40px;
    left: auto;
}
.checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:before,
.checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:after,
.shipping-policy-block.field-tooltip .field-tooltip-content:before,
.shipping-policy-block.field-tooltip .field-tooltip-content:after {
    border: 10px solid transparent;
    height: 0;
    width: 0;
    margin-top: -21px;
    right: 10px;
    left: auto;
    top: 0;
}
.checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:before,
.shipping-policy-block.field-tooltip .field-tooltip-content:before {
    border-bottom-color: #999999;
}
.checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:after,
.shipping-policy-block.field-tooltip .field-tooltip-content:after {
    border-bottom-color: #f4f4f4;
    top: 1px;
}
.opc-wrapper .step-title,
.opc-block-shipping-information .shipping-information-title,
.opc-block-summary > .title,
.checkout-agreements-items .checkout-agreements-item-title {
    border-bottom: 1px solid $light-dark;
    padding-bottom: 10px;
    color: #333333;
    font-weight: 300;
    font-size: 2.6rem;
}
.cart-totals .mark,
.opc-block-summary .table-totals .mark {
    font-weight: 400;
    padding-left: 4px;
}
.cart-totals .mark strong,
.opc-block-summary .table-totals .mark strong {
    font-weight: 400;
}
.cart-totals .amount,
.opc-block-summary .table-totals .amount {
    padding-right: 4px;
    text-align: right;
    white-space: nowrap;
}
.cart-totals .amount strong,
.opc-block-summary .table-totals .amount strong {
    font-weight: 400;
}
.cart-totals .grand .mark,
.cart-totals .grand .amount,
.opc-block-summary .table-totals .grand .mark,
.opc-block-summary .table-totals .grand .amount {
    padding-top: 20px;
}
.cart-totals .grand .amount,
.opc-block-summary .table-totals .grand .amount {
    padding-right: 4px;
    text-align: right;
}
.cart-totals .grand .amount strong,
.opc-block-summary .table-totals .grand .amount strong {
    font-weight: 700;
}
.cart-totals .msrp,
.opc-block-summary .table-totals .msrp {
    margin-bottom: 10px;
}

.cart-totals .totals-tax-summary .amount .price,
.opc-block-summary .table-totals .totals-tax-summary .amount .price {
    display: block;
    text-decoration: none;
    padding-right: 25px;
    position: relative;
}
.abs-sidebar-totals .totals-tax-summary .amount .price > span,
.cart-totals .totals-tax-summary .amount .price > span,
.opc-block-summary .table-totals .totals-tax-summary .amount .price > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.abs-sidebar-totals .totals-tax-summary .amount .price:after,
.cart-totals .totals-tax-summary .amount .price:after,
.opc-block-summary .table-totals .totals-tax-summary .amount .price:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 30px;
    line-height: inherit;
    color: inherit;
    content: '\e622';
    font-family: 'icons-blank-theme';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.cart-totals .totals-tax-summary .amount .price:after,
.opc-block-summary .table-totals .totals-tax-summary .amount .price:after {
    position: absolute;
    right: -5px;
    top: -12px;
}
.cart-totals .totals-tax-summary.expanded .mark,
.cart-totals .totals-tax-summary.expanded .amount,
.opc-block-summary .table-totals .totals-tax-summary.expanded .mark,
.opc-block-summary .table-totals .totals-tax-summary.expanded .amount {
    border-bottom: 0;
}
.abs-sidebar-totals .totals-tax-summary.expanded .amount .price:after,
.cart-totals .totals-tax-summary.expanded .amount .price:after,
.opc-block-summary .table-totals .totals-tax-summary.expanded .amount .price:after {
    content: '\e621';
}
.cart-totals .totals-tax-details,
.opc-block-summary .table-totals .totals-tax-details {
    border-bottom: 1px solid #d1d1d1;
    display: none;
}
.cart-totals .totals-tax-details.shown,
.opc-block-summary .table-totals .totals-tax-details.shown {
    display: table-row;
}
.cart-summary .block > .title {
    cursor: pointer;
    font-weight: 600;
    display: block;
    text-decoration: none;
    margin-bottom: 0;
    overflow: hidden;
    padding: 7px 30px 7px 5px;
    position: relative;
}
.abs-discount-block > .title:after,
.cart-summary .block > .title:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 30px;
    line-height: inherit;
    color: inherit;
    content: '\e622';
    font-family: 'icons-blank-theme';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.cart-summary .block > .title:after {
    position: absolute;
}
.column.main .cart-summary .block > .title strong {
    font-weight: 400;
}
.cart-summary .block > .content {
    display: none;
}
.abs-discount-block.active > .title:after,
.cart-summary .block.active > .title:after {
    content: '\e621';
}
.cart-summary .block.active > .content {
    display: block;
}
@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/opensans/light/opensans-300.woff2') format('woff2'),
        url('../fonts/opensans/light/opensans-300.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/opensans/regular/opensans-400.woff2') format('woff2'),
        url('../fonts/opensans/regular/opensans-400.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/opensans/semibold/opensans-600.woff2') format('woff2'),
        url('../fonts/opensans/semibold/opensans-600.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/opensans/bold/opensans-700.woff2') format('woff2'),
        url('../fonts/opensans/bold/opensans-700.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
.items {
    margin: 0;
    padding: 0;
    list-style: none none;
}
.columns {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    box-sizing: border-box;
}
.columns:after {
    clear: both;
    content: ' ';
    display: block;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}
.columns .column.main {
    padding-bottom: 40px;
    -webkit-flex-basis: auto;
    flex-basis: auto;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
    width: 100%;
}

.columns .sidebar-additional {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
}
.table-wrapper {
    margin-bottom: 20px;
}
table > caption {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.table:not(.cart):not(.totals) {
    border: none;
}
.table:not(.cart):not(.totals) > thead > tr > th,
.table:not(.cart):not(.totals) > tbody > tr > th,
.table:not(.cart):not(.totals) > tfoot > tr > th,
.table:not(.cart):not(.totals) > thead > tr > td,
.table:not(.cart):not(.totals) > tbody > tr > td,
.table:not(.cart):not(.totals) > tfoot > tr > td {
    border: none;
}
.table:not(.cart):not(.totals) > thead > tr > th,
.table:not(.cart):not(.totals) > thead > tr > td {
    border-bottom: 1px solid $light-dark;
}
.table:not(.cart):not(.totals) tfoot > tr:first-child th,
.table:not(.cart):not(.totals) tfoot > tr:first-child td {
    border-top: 1px solid $light-dark;
    padding-top: 20px;
}
.table:not(.cart):not(.totals) tfoot .mark {
    font-weight: 400;
    text-align: right;
}
.message.info {
    position: relative;
}
.message.info a {
    color: #1979c3;
}
.message.info a:hover {
    color: #006bb4;
}
.message.info a:active {
    color: #006bb4;
}
.message.error {
    position: relative;
}
.message.error a {
    color: #1979c3;
}
.message.error a:hover {
    color: #006bb4;
}
.message.error a:active {
    color: #006bb4;
}
.message.error > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: #b30000;
    content: '\e602';
    font-family: 'icons-blank-theme';
    margin: -14px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    left: 0;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center;
}
.message.warning {
    margin: 0 0 10px;
    padding: 10px 20px;
    display: block;
    line-height: 1.2em;
    font-size: 1.3rem;
    color: #6f4400;
    padding-left: 40px;
    position: relative;
}
.message.warning a {
    color: #1979c3;
}
.message.warning a:hover {
    color: #006bb4;
}
.message.warning a:active {
    color: #006bb4;
}
.message.notice {
    position: relative;
}
.message.notice a {
    color: #1979c3;
}
.message.notice a:hover {
    color: #006bb4;
}
.message.notice a:active {
    color: #006bb4;
}
.panel.header .links,
.panel.header .switcher {
    display: none;
}
.nav-sections {
    background: #f5f5f5;
}
.nav-toggle {
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    display: block;
    font-size: 0;
    left: 15px;
    position: absolute;
    top: 15px;
    z-index: 14;
}
.nav-toggle:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: inherit;
    color: #8f8f8f;
    content: '\e609';
    font-family: 'icons-blank-theme';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.nav-toggle:hover:before {
    color: #333333;
}
.nav-toggle > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.tooltip.wrapper {
    position: relative;
}
.tooltip.wrapper .tooltip.content {
    background: #ffffff;
    max-width: 360px;
    min-width: 210px;
    padding: 12px 16px;
    z-index: 100;
    display: none;
    position: absolute;
    text-align: left;
    color: #333333;
    line-height: 1.4;
    border: 1px solid #bbbbbb;
    margin-top: 5px;
    left: 0;
    top: 100%;
}
.tooltip.wrapper .tooltip.content:after,
.tooltip.wrapper .tooltip.content:before {
    border: solid transparent;
    content: '';
    height: 0;
    position: absolute;
    width: 0;
}
.tooltip.wrapper .tooltip.content:after {
    border-width: 5px;
    border-color: transparent;
}
.tooltip.wrapper .tooltip.content:before {
    border-width: 6px;
    border-color: transparent;
}
.tooltip.wrapper .tooltip.content:after,
.tooltip.wrapper .tooltip.content:before {
    bottom: 100%;
}
.tooltip.wrapper .tooltip.content:after {
    border-bottom-color: #ffffff;
    left: 15px;
    margin-left: -5px;
}
.tooltip.wrapper .tooltip.content:before {
    border-bottom-color: #bbbbbb;
    left: 15px;
    margin-left: -6px;
}
.tooltip.wrapper .tooltip.toggle {
    cursor: help;
}
.tooltip.wrapper .tooltip.toggle:hover + .tooltip.content,
.tooltip.wrapper .tooltip.toggle:focus + .tooltip.content,
.tooltip.wrapper:hover .tooltip.content {
    display: block;
}
.tooltip.wrapper .tooltip.content dl {
    margin-bottom: 0;
}
.tooltip.wrapper .tooltip.content dd {
    white-space: normal;
}
.tooltip.wrapper .tooltip.content .subtitle {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
}
.tooltip.wrapper .tooltip.content .label {
    margin-top: 10px;
}
.tooltip.wrapper .tooltip.content .label:first-child {
    margin-top: 0;
}
.tooltip.wrapper .tooltip.content .values {
    margin: 0;
}
.ui-tooltip {
    position: absolute;
    z-index: 9999;
}
.load.indicator {
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 9999;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    position: absolute;
}
.load.indicator:before {
    background: transparent url('../images/loader-2.gif') no-repeat 50% 50%;
    border-radius: 5px;
    height: 160px;
    width: 160px;
    bottom: 0;
    box-sizing: border-box;
    content: '';
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
}
.load.indicator > span {
    display: none;
}
.loading-mask {
    bottom: 0;
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
    background: rgba(255, 255, 255, 0.5);
}
.loading-mask .loader > img {
    bottom: 0;
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
}
.loading-mask .loader > p {
    display: none;
}
body > .loading-mask {
    z-index: 9999;
}
._block-content-loading {
    position: relative;
}
[data-role='main-css-loader'] {
    display: none;
}
.fieldset {
    border: 0;
    padding: 0;
    letter-spacing: -0.31em;
}
.fieldset > * {
    letter-spacing: normal;
}

.fieldset > .legend + br {
    clear: both;
    display: block;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}
.fieldset:last-child {
    margin-bottom: 20px;
}
.fieldset > .field,
.fieldset > .fields > .field {
    margin: 0 0 20px;
}

.fieldset > .field:last-child,
.fieldset > .fields > .field:last-child {
    margin-bottom: 0;
}
.fieldset > .field > .label,
.fieldset > .fields > .field > .label {
    font-weight: 700;
}
.fieldset > .field > .label + br,
.fieldset > .fields > .field > .label + br {
    display: none;
}
.fieldset > .field .choice input,
.fieldset > .fields > .field .choice input {
    vertical-align: top;
}
.fieldset > .field .fields.group:before,
.fieldset > .fields > .field .fields.group:before,
.fieldset > .field .fields.group:after,
.fieldset > .fields > .field .fields.group:after {
    content: '';
    display: table;
}
.fieldset > .field .fields.group:after,
.fieldset > .fields > .field .fields.group:after {
    clear: both;
}
.fieldset > .field .fields.group .field,
.fieldset > .fields > .field .fields.group .field {
    box-sizing: border-box;
    float: left;
}
.fieldset > .field .fields.group.group-2 .field,
.fieldset > .fields > .field .fields.group.group-2 .field {
    width: 50% !important;
}
.fieldset > .field .fields.group.group-3 .field,
.fieldset > .fields > .field .fields.group.group-3 .field {
    width: 33.3% !important;
}
.fieldset > .field .fields.group.group-4 .field,
.fieldset > .fields > .field .fields.group.group-4 .field {
    width: 25% !important;
}
.fieldset > .field .fields.group.group-5 .field,
.fieldset > .fields > .field .fields.group.group-5 .field {
    width: 20% !important;
}
.fieldset > .field .addon,
.fieldset > .fields > .field .addon {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding: 0;
    width: 100%;
}
.fieldset > .field .addon textarea,
.fieldset > .fields > .field .addon textarea,
.fieldset > .field .addon select,
.fieldset > .fields > .field .addon select,
.fieldset > .field .addon input,
.fieldset > .fields > .field .addon input {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    display: inline-block;
    margin: 0;
    width: auto;
}
.fieldset > .field .addon .addbefore,
.fieldset > .fields > .field .addon .addbefore,
.fieldset > .field .addon .addafter,
.fieldset > .fields > .field .addon .addafter {
    background: #ffffff;
    background-clip: padding-box;
    border: 1px solid #c2c2c2;
    border-radius: 1px;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    height: 32px;
    line-height: 1.42857143;
    padding: 0 9px;
    vertical-align: baseline;
    width: 100%;
    box-sizing: border-box;
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
}
.fieldset > .field .addon .addbefore:disabled,
.fieldset > .fields > .field .addon .addbefore:disabled,
.fieldset > .field .addon .addafter:disabled,
.fieldset > .fields > .field .addon .addafter:disabled {
    opacity: 0.5;
}
.fieldset > .field .addon .addbefore::-moz-placeholder,
.fieldset > .fields > .field .addon .addbefore::-moz-placeholder,
.fieldset > .field .addon .addafter::-moz-placeholder,
.fieldset > .fields > .field .addon .addafter::-moz-placeholder {
    color: #c2c2c2;
}
.fieldset > .field .addon .addbefore::-webkit-input-placeholder,
.fieldset > .fields > .field .addon .addbefore::-webkit-input-placeholder,
.fieldset > .field .addon .addafter::-webkit-input-placeholder,
.fieldset > .fields > .field .addon .addafter::-webkit-input-placeholder {
    color: #c2c2c2;
}
.fieldset > .field .addon .addbefore:-ms-input-placeholder,
.fieldset > .fields > .field .addon .addbefore:-ms-input-placeholder,
.fieldset > .field .addon .addafter:-ms-input-placeholder,
.fieldset > .fields > .field .addon .addafter:-ms-input-placeholder {
    color: #c2c2c2;
}
.fieldset > .field .addon .addbefore,
.fieldset > .fields > .field .addon .addbefore {
    float: left;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
}
.fieldset > .field .additional,
.fieldset > .fields > .field .additional {
    margin-top: 10px;
}
.fieldset > .field.required > .label:after,
.fieldset > .fields > .field.required > .label:after,
.fieldset > .field._required > .label:after,
.fieldset > .fields > .field._required > .label:after {
    content: '*';
    font-size: 1.2rem;
    margin: 0 0 0 5px;
}
.fieldset > .field .note,
.fieldset > .fields > .field .note {
    font-size: 1.2rem;
    margin: 3px 0 0;
    padding: 0;
    display: inline-block;
    text-decoration: none;
}
.fieldset > .field .note:before,
.fieldset > .fields > .field .note:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 24px;
    line-height: 12px;
    content: '\e618';
    font-family: 'icons-blank-theme';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.fieldset > .field.choice .label,
.fieldset > .fields > .field.choice .label {
    display: inline;
    font-weight: normal;
}
.column:not(.main) .fieldset > .field .label,
.column:not(.main) .fieldset > .fields > .field .label {
    font-weight: normal;
}
.fieldset > .field .field.choice:last-child,
.fieldset > .fields > .field .field.choice:last-child {
    margin-bottom: 0;
}
.fieldset > .field input[type='file'],
.fieldset > .fields > .field input[type='file'] {
    margin: 5px 0;
}
.legend + .fieldset,
.legend + div {
    clear: both;
}
.legend strong {
    margin-left: 5px;
}
fieldset.field {
    border: 0;
    padding: 0;
}
.field.date .time-picker {
    display: inline-block;
    margin-top: 10px;
    white-space: nowrap;
}
.field .message.warning {
    margin-top: 10px;
}

.field-error {
    color: #e02b27;
    font-size: 1.2rem;
}
.product-options-wrapper .date.required div[for*='options'].mage-error {
    display: none !important;
}
.field .tooltip {
    position: relative;
}
.field .tooltip .tooltip-content {
    background: #ffffff;
    max-width: 360px;
    min-width: 210px;
    padding: 12px 16px;
    z-index: 100;
    display: none;
    position: absolute;
    text-align: left;
    color: #333333;
    line-height: 1.4;
    border: 1px solid #bbbbbb;
    margin-left: 5px;
    left: 100%;
    top: 0;
}
.field .tooltip .tooltip-content:after,
.field .tooltip .tooltip-content:before {
    border: solid transparent;
    content: '';
    height: 0;
    position: absolute;
    width: 0;
}
.field .tooltip .tooltip-content:after {
    border-width: 5px;
    border-color: transparent;
}
.field .tooltip .tooltip-content:before {
    border-width: 6px;
    border-color: transparent;
}
.field .tooltip .tooltip-content:after,
.field .tooltip .tooltip-content:before {
    right: 100%;
}
.field .tooltip .tooltip-content:after {
    border-right-color: #ffffff;
    margin-top: -5px;
    top: 15px;
}
.field .tooltip .tooltip-content:before {
    border-right-color: #bbbbbb;
    margin-top: -6px;
    top: 15px;
}
.field .tooltip .tooltip-toggle {
    cursor: help;
}
.field .tooltip .tooltip-toggle:hover + .tooltip-content,
.field .tooltip .tooltip-toggle:focus + .tooltip-content,
.field .tooltip:hover .tooltip-content {
    display: block;
}
.field .tooltip .tooltip-content {
    min-width: 200px;
    white-space: normal;
}
input:focus ~ .tooltip .tooltip-content,
select:focus ~ .tooltip .tooltip-content {
    display: block;
}
._has-datepicker ~ .ui-datepicker-trigger {
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-shadow: none;
    font-weight: 400;
    display: block;
    text-decoration: none;
    display: inline-block;
    vertical-align: middle;
}
._has-datepicker ~ .ui-datepicker-trigger:focus,
._has-datepicker ~ .ui-datepicker-trigger:active {
    background: none;
    border: none;
}
._has-datepicker ~ .ui-datepicker-trigger:hover {
    background: none;
    border: none;
}
._has-datepicker ~ .ui-datepicker-trigger.disabled,
._has-datepicker ~ .ui-datepicker-trigger[disabled],
fieldset[disabled] ._has-datepicker ~ .ui-datepicker-trigger {
    pointer-events: none;
    opacity: 0.5;
}
._has-datepicker ~ .ui-datepicker-trigger > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
._has-datepicker ~ .ui-datepicker-trigger:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 40px;
    line-height: 40px;
    color: #7d7d7d;
    content: '\e612';
    font-family: 'icons-blank-theme';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
._has-datepicker ~ .ui-datepicker-trigger:focus {
    box-shadow: none;
    outline: 0;
}
.sidebar .fieldset {
    margin: 0;
}
.sidebar .fieldset > .field:not(.choice):not(:last-child),
.sidebar .fieldset .fields > .field:not(:last-child) {
    margin: 0 0 20px;
}
.sidebar .fieldset > .field:not(.choice) .label,
.sidebar .fieldset .fields > .field .label {
    margin: 0 0 4px;
    padding: 0 0 5px;
    text-align: left;
    width: 100%;
}
.sidebar .fieldset > .field:not(.choice) .control,
.sidebar .fieldset .fields > .field .control {
    width: 100%;
}
@font-face {
    font-family: 'icons-blank-theme';
    src: url('../fonts/Blank-Theme-Icons/Blank-Theme-Icons.woff2') format('woff2'),
        url('../fonts/Blank-Theme-Icons/Blank-Theme-Icons.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
button:active,
.cart.table-wrapper .actions-toolbar > .action:active {
    box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.12);
}
a.action.primary {
    display: inline-block;
    text-decoration: none;
}
a.action.primary:hover,
a.action.primary:active,
a.action.primary:focus {
    text-decoration: none;
}
.product.data.items {
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
}
.product.data.items > .item.title {
    box-sizing: border-box;
    float: none;
    width: 100%;
}
.product.data.items > .item.title > .switch {
    display: block;
}
.product.data.items > .item.content {
    box-sizing: border-box;
    display: block;
    float: none;
    margin: 0;
}
.product.data.items > .item.content:before,
.product.data.items > .item.content:after {
    content: '';
    display: table;
}
.product.data.items > .item.content:after {
    clear: both;
}
.product.data.items > .item.content.active {
    display: block;
}
.product.data.items > .item.title {
    margin: 0 0 5px;
}
.product.data.items > .item.title > .switch {
    background: #f0f0f0;
    border-bottom: 1px solid #d1d1d1;
    border-left: 1px solid #d1d1d1;
    border-right: 1px solid #d1d1d1;
    border-top: 1px solid #d1d1d1;
    height: 40px;
    padding: 5px 20px 5px 20px;
    font-weight: 600;
    line-height: 40px;
    font-size: 1.8rem;
    color: #7d7d7d;
    text-decoration: none;
}
.product.data.items > .item.title > .switch:visited {
    color: #7d7d7d;
    text-decoration: none;
}
.product.data.items > .item.title > .switch:hover {
    color: #7d7d7d;
    text-decoration: none;
}
.product.data.items > .item.title > .switch:active {
    color: #333333;
    text-decoration: none;
}
.product.data.items > .item.title:not(.disabled) > .switch:focus,
.product.data.items > .item.title:not(.disabled) > .switch:hover {
    background: #fcfcfc;
}
.product.data.items > .item.title:not(.disabled) > .switch:active,
.product.data.items > .item.title.active > .switch,
.product.data.items > .item.title.active > .switch:focus,
.product.data.items > .item.title.active > .switch:hover {
    background: #ffffff;
    color: #333333;
    text-decoration: none;
    padding-bottom: 5px;
}
.product.data.items > .item.content {
    background: #ffffff;
    border: 1px solid #d1d1d1;
    margin: 0 0 5px;
    padding: 20px 20px 20px 20px;
}
.pages > .label {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.pages .items {
    font-size: 0;
    letter-spacing: -1px;
    line-height: 0;
    white-space: nowrap;
    margin: 0;
    padding: 0;
    list-style: none none;
    display: inline-block;
    font-weight: 700;
}
.pages .item {
    font-size: 1.2rem;
    line-height: 3.2rem;
    letter-spacing: normal;
    margin: 0 2px 0 0;
    display: inline-block;
}
.pages .item .label {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.pages a.page {
    color: #1979c3;
    display: inline-block;
    padding: 0 4px;
    text-decoration: none;
}
.pages a.page:visited {
    color: #1979c3;
}
.pages a.page:hover {
    color: #006bb4;
    text-decoration: none;
}
.pages a.page:active {
    color: #ff5501;
}
.pages strong.page {
    font-size: 1.2rem;
    line-height: 3.2rem;
    letter-spacing: normal;
    color: #333333;
    display: inline-block;
    font-weight: 700;
    padding: 0 4px;
}
.pages .action {
    border: 1px solid #d1d1d1;
    color: #7d7d7d;
    display: inline-block;
    padding: 0;
    text-decoration: none;
}
.pages .action:visited {
    color: #7d7d7d;
}
.pages .action:hover {
    color: #7d7d7d;
    text-decoration: none;
}
.pages .action:active {
    color: #7d7d7d;
}
.pages .action.next {
    display: inline-block;
    text-decoration: none;
}
.pages .action.next:visited:before {
    color: #7d7d7d;
}
.pages .action.next:active:before {
    color: #7d7d7d;
}
.pages .action.next > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.pages .action.next:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 46px;
    line-height: inherit;
    color: #7d7d7d;
    content: '\e608';
    font-family: 'icons-blank-theme';
    margin: 0 0 0 -6px;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.pages .action.next:hover:before {
    color: #7d7d7d;
}
.pages .action.next:active:before {
    color: #7d7d7d;
}
.pages .action.previous {
    display: inline-block;
    text-decoration: none;
}
.pages .action.previous:visited:before {
    color: #7d7d7d;
}
.pages .action.previous:active:before {
    color: #7d7d7d;
}
.pages .action.previous > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.pages .action.previous:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 46px;
    line-height: inherit;
    color: #7d7d7d;
    content: '\e617';
    font-family: 'icons-blank-theme';
    margin: 0 0 0 -6px;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.pages .action.previous:hover:before {
    color: #7d7d7d;
}
.pages .action.previous:active:before {
    color: #7d7d7d;
}
.pages .action {
    width: 34px;
}
.pages .action.previous {
    margin-right: 12px;
}
.pages .action.next {
    margin-left: 12px;
}
.actions-toolbar > .primary .action,
.actions-toolbar > .secondary .action {
    margin-bottom: 10px;
}

.breadcrumbs .items {
    margin: 0;
    padding: 0;
    list-style: none none;
}
.breadcrumbs .items > li {
    display: inline-block;
}
.breadcrumbs .item {
    margin: 0;
}
.breadcrumbs .item:not(:last-child) {
    display: inline-block;
    text-decoration: none;
}
.breadcrumbs .item:not(:last-child):after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\e608';
    font-family: 'icons-blank-theme';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.ui-dialog.popup .action.close {
    position: absolute;
    height: 40px;
    right: 0;
    top: 0;
    width: 40px;
    z-index: 1001;
    display: inline-block;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
}
.ui-dialog.popup .action.close > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.ui-dialog.popup .action.close:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: 22px;
    color: inherit;
    content: '\e616';
    font-family: 'icons-blank-theme';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.ui-dialog.popup .action.close:hover:before {
    color: inherit;
}
.ui-dialog.popup .action.close:active:before {
    color: inherit;
}
.ui-dialog.popup .action.close:focus,
.ui-dialog.popup .action.close:active {
    background: none;
    border: none;
}
.ui-dialog.popup .action.close:hover {
    background: none;
    border: none;
}
.ui-dialog.popup .action.close.disabled,
.ui-dialog.popup .action.close[disabled],
fieldset[disabled] .ui-dialog.popup .action.close {
    pointer-events: none;
    opacity: 0.5;
}
.popup-pointer {
    margin-left: -14px;
    position: absolute;
    top: -14px;
}
.popup-pointer:before,
.popup-pointer:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-bottom-style: solid;
}
.popup-pointer:before {
    left: 2px;
    top: 2px;
    border: solid 6px;
    border-color: transparent transparent #ffffff transparent;
    z-index: 99;
}
.popup-pointer:after {
    left: 1px;
    top: 0px;
    border: solid 7px;
    border-color: transparent transparent #aeaeae transparent;
    z-index: 98;
}
.price-tier_price .price-excluding-tax,
.price-tier_price .price-including-tax {
    display: inline;
}
.price-including-tax,
.price-excluding-tax {
    display: block;
    font-size: 1.8rem;
    line-height: 1;
}
.price-including-tax .price,
.price-excluding-tax .price {
    font-weight: 700;
}
.price-including-tax + .price-excluding-tax,
.weee[data-label] {
    display: block;
    font-size: 1.8rem;
}
.price-including-tax + .price-excluding-tax:before,
.weee[data-label]:before {
    content: attr(data-label) ': ';
    font-size: 1.1rem;
}
.price-including-tax + .price-excluding-tax .price,
.weee[data-label] .price {
    font-size: 1.1rem;
}
body._has-modal {
    height: 100%;
    overflow: hidden;
    width: 100%;
}
.modal-slide,
.modal-popup {
    bottom: 0;
    left: 0;
    min-width: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: visibility 0s 0.3s, opacity 0.3s ease;
    transition: visibility 0s 0.3s, opacity 0.3s ease;
}
.modal-slide._show,
.modal-popup._show {
    visibility: visible;
    opacity: 1;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
}
.modal-slide._show .modal-inner-wrap,
.modal-popup._show .modal-inner-wrap {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}
.modal-slide .modal-inner-wrap,
.modal-popup .modal-inner-wrap {
    box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.35);
    opacity: 1;
    pointer-events: auto;
}
.modal-slide {
    left: 44px;
    z-index: 900;
}
.modal-slide._show .modal-inner-wrap {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}
.modal-slide .modal-inner-wrap {
    height: 100%;
    overflow-y: auto;
    position: static;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    width: auto;
}
.modal-slide._inner-scroll {
    overflow-y: visible;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}
.modal-slide._inner-scroll .modal-header,
.modal-slide._inner-scroll .modal-footer {
    -webkit-flex-grow: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
}
.modal-slide._inner-scroll .modal-content {
    overflow-y: auto;
}
.modal-slide._inner-scroll .modal-footer {
    margin-top: auto;
}

.modal-popup {
    z-index: 900;
    left: 0;
    overflow-y: auto;
}
.modal-popup.confirm .modal-inner-wrap {
    max-width: 50rem;
}
.modal-popup._show .modal-inner-wrap {
    -webkit-transform: translateY(0);
    transform: translateY(0);
}
.modal-popup .modal-inner-wrap {
    margin: 5rem auto;
    width: 75%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    box-sizing: border-box;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
    -webkit-transition: -webkit-transform 0.2s ease;
    transition: transform 0.2s ease;
}
.modal-popup._inner-scroll {
    overflow-y: visible;
}
.ie11 .modal-popup._inner-scroll {
    overflow-y: auto;
}
.modal-popup._inner-scroll .modal-inner-wrap {
    max-height: 90%;
}
.ie11 .modal-popup._inner-scroll .modal-inner-wrap {
    max-height: none;
}
.modal-popup._inner-scroll .modal-content {
    overflow-y: auto;
}
.modal-popup .modal-header,
.modal-popup .modal-footer {
    -webkit-flex-grow: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
}

.modal-popup .modal-footer {
    margin-top: auto;
}
.modal-popup .modal-footer-actions {
    text-align: right;
}
.modal-custom .action-close,
.modal-popup .action-close,
.modal-slide .action-close {
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-shadow: none;
    font-weight: 400;
    display: inline-block;
    text-decoration: none;
    position: absolute;
    right: 0;
    top: 0;
}
.modal-custom .action-close:focus,
.modal-popup .action-close:focus,
.modal-slide .action-close:focus,
.modal-custom .action-close:active,
.modal-popup .action-close:active,
.modal-slide .action-close:active {
    background: none;
    border: none;
}
.modal-custom .action-close:hover,
.modal-popup .action-close:hover,
.modal-slide .action-close:hover {
    background: none;
    border: none;
}
.modal-custom .action-close.disabled,
.modal-popup .action-close.disabled,
.modal-slide .action-close.disabled,
.modal-custom .action-close[disabled],
.modal-popup .action-close[disabled],
.modal-slide .action-close[disabled],
fieldset[disabled] .modal-custom .action-close,
fieldset[disabled] .modal-popup .action-close,
fieldset[disabled] .modal-slide .action-close {
    pointer-events: none;
    opacity: 0.5;
}
.modal-custom .action-close > span,
.modal-popup .action-close > span,
.modal-slide .action-close > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.modal-custom .action-close:before,
.modal-popup .action-close:before,
.modal-slide .action-close:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 32px;
    line-height: 32px;
    color: #8f8f8f;
    content: '\e616';
    font-family: 'icons-blank-theme';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.modal-custom .action-close:hover:before,
.modal-popup .action-close:hover:before,
.modal-slide .action-close:hover:before {
    color: inherit;
}
.modal-custom .action-close:active:before,
.modal-popup .action-close:active:before,
.modal-slide .action-close:active:before {
    color: inherit;
}
.modal-custom .action-close:hover:before,
.modal-popup .action-close:hover:before,
.modal-slide .action-close:hover:before {
    color: #1a1a1a;
}
.modal-custom .action-close {
    margin: 25px;
}
.modal-popup {
    pointer-events: none;
}
.modal-popup .modal-title {
    border-bottom: 1px solid #c1c1c1;
    font-weight: 300;
    font-size: 26px;
    margin-bottom: 0;
    min-height: 1em;
    word-wrap: break-word;
}

.modal-slide .page-main-actions {
    margin-bottom: -12.9rem;
    margin-top: 2.1rem;
}
.modals-overlay {
    background-color: rgba(51, 51, 51, 0.55);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
}
body._has-modal-custom .modal-custom-overlay {
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 899;
}
/**
 * Copyright Â© Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */
.lac-notification-sticky {
    position: relative;
    z-index: 999;
}
.lac-notification-sticky .lac-notification {
    background-color: #373330;
    color: #ffffff;
    font-size: 16px;
}
.lac-notification-sticky .lac-notification .lac-notification-icon {
    float: left;
    margin: 10px 25px 10px 10px;
}
.lac-notification-sticky .lac-notification .lac-notification-icon .logo-img {
    display: block;
}
.lac-notification-sticky .lac-notification .lac-notification-text {
    float: left;
    padding: 15px 0;
}
.lac-notification-sticky .lac-notification .lac-notification-links {
    float: right;
    padding: 15px 0;
}
.lac-notification-sticky .lac-notification .lac-notification-links a {
    color: #ffffff;
    font-size: 14px;
}
.lac-notification-sticky
    .lac-notification
    .lac-notification-links
    .lac-notification-close-link:after {
    background: url('../Magento_LoginAsCustomerFrontendUi/images/close.svg');
    content: ' ';
    display: inline-block;
    height: 12px;
    margin-left: 5px;
    vertical-align: middle;
    width: 12px;
}
/**
 * Copyright Â© Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */
.login-container .g-recaptcha,
.form-login .g-recaptcha,
.form-edit-account .g-recaptcha {
    margin-bottom: 10px !important;
}
/**
 * Copyright Â© Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */
.required-captcha.checkbox {
    position: absolute;
    display: block;
    visibility: visible;
    overflow: hidden;
    opacity: 0;
    width: 1px;
    height: 1px;
}
/**
 * Copyright Â© Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */
.block.newsletter .field-recaptcha .field .control:before {
    content: none;
}
/**
 * Copyright Â© Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */
.review-form .field-recaptcha {
    margin-bottom: 10px;
}
.klarna-payments-method-cell {
    display: table-cell;
    vertical-align: middle;
}
label.klarna-payments-method-cell > span {
    padding-left: 5px;
}
span.klarna-payments-method-cell > img {
    display: block;
}
/**
 * @copyright  Vertex. All rights reserved.  https://www.vertexinc.com/
 * @author     Mediotype                     https://www.mediotype.com/
 */
.form-address-edit .actions-toolbar .vertex-update-address {
    margin-top: 10px;
}
.vertex__address-suggestion {
    margin: 5px 0;
    padding-left: 10px;
    font-weight: 500;
    border-left: 2px solid gray;
}
//.products {
//    margin: 30px 0;
//}
.product-items {
    font-size: 0;
}
.product-item {
    font-size: 1.4rem;
    vertical-align: top;
}
.products-grid .product-item {
    display: inline-block;
    padding: 0;
}
.product-item:nth-child(2n + 1) {
    margin-left: 0;
}
.product-item-actions .actions-secondary {
    display: inline-block;
    font-size: 1.4rem;
    vertical-align: middle;
    white-space: nowrap;
}
.product-item-actions .actions-secondary > button.action,
.product-item-actions .actions-secondary > .cart.table-wrapper .actions-toolbar > .action.action {
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
}
.product-item-actions .actions-secondary > button.action:focus,
.product-item-actions .actions-secondary > button.action:active,
.product-item-actions
    .actions-secondary
    > .cart.table-wrapper
    .actions-toolbar
    > .action.action:focus,
.product-item-actions
    .actions-secondary
    > .cart.table-wrapper
    .actions-toolbar
    > .action.action:active {
    background: none;
    border: none;
}
.product-item-actions .actions-secondary > button.action:hover,
.product-item-actions
    .actions-secondary
    > .cart.table-wrapper
    .actions-toolbar
    > .action.action:hover {
    background: none;
    border: none;
}
.product-item-actions .actions-secondary > button.action.disabled,
.product-item-actions .actions-secondary > button.action[disabled],
fieldset[disabled] .product-item-actions .actions-secondary > button.action,
.product-item-actions
    .actions-secondary
    > .cart.table-wrapper
    .actions-toolbar
    > .action.action.disabled,
.product-item-actions
    .actions-secondary
    > .cart.table-wrapper
    .actions-toolbar
    > .action.action[disabled],
fieldset[disabled]
    .product-item-actions
    .actions-secondary
    > .cart.table-wrapper
    .actions-toolbar
    > .action.action {
    pointer-events: none;
    opacity: 0.5;
}

.product-item-actions .actions-secondary > .action:before {
    margin: 0;
}
.product-item-description {
    margin: 25px 0;
}
.product-item .product-reviews-summary .rating-summary {
    margin: 0 4px 0 0;
}
.product-item .product-reviews-summary .reviews-actions {
    font-size: 12px;
    margin-top: 5px;
}
.product-item .price-box {
    margin: 10px 0 25px;
}
.product-item .price-box .price-label {
    font-size: 12px;
}
.product-item .price-box .price-label:after {
    content: ':';
}

.product-item .special-price .price-wrapper,
.product-item .minimal-price .price-wrapper {
    display: inline-block;
}
.product-item .old-price .price {
    font-weight: 400;
}
.product-item .regular-price .price-label {
    display: none;
}
.product-item .minimal-price .price-container {
    display: block;
}
.product-item .minimal-price-link {
    margin-top: 5px;
}
.product-item .minimal-price-link .price-label {
    color: #1979c3;
}
.product-item .minimal-price-link .price {
    font-weight: 400;
}
.product-item .minimal-price-link,
.product-item .price-excluding-tax,
.product-item .price-including-tax {
    display: block;
    white-space: nowrap;
}
.product-item .price-from,
.product-item .price-to {
    margin: 0;
}
.product-item .tocompare:before {
    content: '\e61f';
}
.product-item .tocart {
    white-space: nowrap;
}
.price-container .price-including-tax + .price-excluding-tax,
.price-container .weee {
    margin-top: 5px;
}
.price-container .price-including-tax + .price-excluding-tax,
.price-container .weee,
.price-container .price-including-tax + .price-excluding-tax .price,
.price-container .weee .price,
.price-container .weee + .price-excluding-tax:before,
.price-container .weee + .price-excluding-tax .price {
    font-size: 1.1rem;
}
.price-container .weee:before {
    content: '(' attr(data-label) ': ';
}
.price-container .weee:after {
    content: ')';
}
.price-container .weee + .price-excluding-tax:before {
    content: attr(data-label) ': ';
}
.products-list .product-item {
    display: table;
    width: 100%;
}
.products-list .product-item-info {
    display: table-row;
}
.products-list .product-item-photo {
    display: table-cell;
    padding: 0 30px 30px 0;
    vertical-align: top;
    width: 1%;
}
.products-list .product-item-details {
    display: table-cell;
    vertical-align: top;
}
.page-products .columns {
    position: relative;
    z-index: 1;
}
.toolbar-amount {
    display: block;
    line-height: 26px;
    margin: 0;
    vertical-align: middle;
}

.toolbar-products .pages {
    display: none;
}
.products.wrapper ~ .toolbar-products .pages {
    display: block;
}
.toolbar-products .limiter .control {
    display: inline-block;
}
.products.wrapper ~ .toolbar .sorter {
    display: none;
}
.sorter .sorter-action {
    position: relative;
    top: -2px;
}
.sorter-options {
    margin: 0 0 0 7px;
    width: auto;
}
.sorter-action {
    vertical-align: top;
    display: inline-block;
    text-decoration: none;
}

.modes {
    display: none;
}
.limiter-options {
    margin: 0 5px 0 7px;
    width: auto;
}
.limiter-label {
    font-weight: 400;
}
.page-products .toolbar .limiter {
    display: none;
}
.old-price,
.old.price {
    text-decoration: line-through;
}
.prices-tier .price-container .price-including-tax + .price-excluding-tax:before {
    content: '(' attr(data-label) ': ';
}
.prices-tier .price-container .price-including-tax + .price-excluding-tax:last-child:after {
    content: ')';
}
.prices-tier .price-container .weee[data-label] {
    display: inline;
}
.prices-tier .price-container .weee[data-label] .price {
    font-size: 1.1rem;
}
.prices-tier .price-container .weee[data-label]:before {
    content: ' +' attr(data-label) ': ';
}
.actual-price {
    font-weight: 700;
}
.category-image .image {
    display: block;
    height: auto;
    max-width: 100%;
}
.product-image-wrapper {
    display: block;
    height: 0;
    overflow: hidden;
    position: relative;
    z-index: 1;
}
.product.media .placeholder .photo.container {
    max-width: 100%;
}
.product.media .notice {
    color: #7d7d7d;
    font-size: 1.2rem;
    margin: 10px 0;
}
.product.media .product.thumbs {
    margin: 20px 0 30px;
}
.product.media .items.thumbs {
    margin: 0;
    padding: 0;
    list-style: none none;
}
.product.media .items.thumbs > li {
    display: inline-block;
    vertical-align: top;
}
.product.media .items.thumbs .active {
    display: block;
    line-height: 1;
}
.product.info.detailed {
    clear: both;
    margin-bottom: 30px;
}
.product.info.detailed .additional-attributes {
    width: auto;
}
.product.info.detailed .additional-attributes > thead > tr > td,
.product.info.detailed .additional-attributes > tbody > tr > td,
.product.info.detailed .additional-attributes > tfoot > tr > td {
    padding: 4px 5px 10px 5px;
}
.product.info.detailed .additional-attributes > thead > tr > th,
.product.info.detailed .additional-attributes > tbody > tr > th,
.product.info.detailed .additional-attributes > tfoot > tr > th {
    padding: 4px 30px 10px 0;
}
.product-info-main .page-title-wrapper .page-title {
    line-height: 1.42857143;
    margin-bottom: 10px;
}
.product-info-main .stock.available,
.product-info-main .stock.unavailable {
    display: inline-block;
    font-weight: 700;
    margin-right: 20px;
    text-transform: uppercase;
    vertical-align: top;
}
.product-info-main .product.attribute.sku {
    display: inline-block;
    vertical-align: top;
    color: #7d7d7d;
}
.product-info-main .product.attribute.sku > .value {
    display: inline-block;
    vertical-align: top;
    word-break: break-all;
}
.product-info-main .product.attribute.sku .type {
    margin-right: 5px;
}
.product-info-main .product.attribute.overview {
    margin: 20px 0;
}
.product-info-main .product.alert {
    margin: 10px 0;
}
.product-info-main .product-reviews-summary .reviews-actions {
    font-size: 1.4rem;
}
.product-options-wrapper .fieldset-product-options-inner .legend {
    font-weight: 700;
    margin: 0 0 5px;
    font-size: 1.4rem;
    border: none;
    display: inline-block;
    float: none;
    padding: 0;
}
.product-options-wrapper
    .fieldset-product-options-inner
    input.datetime-picker
    ~ select.datetime-picker {
    margin-top: 10px;
}
.product-options-wrapper .fieldset-product-options-inner.required .legend:after,
.product-options-wrapper .fieldset-product-options-inner._required .legend:after {
    content: '*';
    color: #e02b27;
    font-size: 1.2rem;
    margin: 0 0 0 5px;
}
.product-options-wrapper .field .note {
    display: block;
}
.product-info-main .price-box .price-including-tax + .price-excluding-tax,
.product-options-bottom .price-box .price-including-tax + .price-excluding-tax,
.product-info-main .price-box .weee + .price-excluding-tax,
.product-options-bottom .price-box .weee + .price-excluding-tax,
.product-info-main .price-box .weee,
.product-options-bottom .price-box .weee {
    font-size: 1.2rem;
    line-height: 14px;
    margin-bottom: 5px;
}

.product-info-main .price-box .price,
.product-options-bottom .price-box .price {
    white-space: nowrap;
}
.product-info-main .special-price,
.product-options-bottom .special-price {
    display: block;
    margin: 10px 0;
}
.product-info-main .special-price .price-container,
.product-options-bottom .special-price .price-container {
    font-size: 1.4rem;
}
.product-info-main .special-price .price-label + .price-wrapper,
.product-options-bottom .special-price .price-label + .price-wrapper {
    display: inline-block;
}
.product-info-main .old-price .price-label:after,
.product-options-bottom .old-price .price-label:after,
.product-info-main .special-price .price-label:after,
.product-options-bottom .special-price .price-label:after {
    content: ': ';
}
.product-info-main .box-tocart,
.product-options-bottom .box-tocart {
    margin: 20px 0;
}
.product-info-main .box-tocart .field.qty,
.product-options-bottom .box-tocart .field.qty {
    padding-right: 15px;
}
.product-info-main .box-tocart .input-text.qty,
.product-options-bottom .box-tocart .input-text.qty {
    height: 52px;
    text-align: center;
    width: 52px;
}
.product-info-main .box-tocart .actions,
.product-options-bottom .box-tocart .actions {
    text-align: center;
}
.product-info-main .product-addto-links,
.product-options-bottom .product-addto-links {
    margin: 20px 0;
}
.product-info-main .action.tocompare,
.product-options-bottom .action.tocompare {
    vertical-align: top;
}
.prices-tier {
    background: #f5f5f5;
    margin: 10px 0;
    padding: 10px 15px;
}
.prices-tier .price-container {
    display: inline-block;
}
.prices-tier .price-including-tax,
.prices-tier .price-excluding-tax,
.prices-tier .weee {
    display: inline-block;
}
.prices-tier .price-including-tax .price,
.prices-tier .price-excluding-tax .price,
.prices-tier .weee .price {
    font-size: 1.4rem;
    font-weight: 700;
}
.ui-dialog-titlebar-close {
    line-height: 1.42857143;
    margin: 0;
    padding: 0;
    color: #1979c3;
    text-decoration: none;
    background: none;
    border: 0;
    display: inline;
}
.ui-dialog-titlebar-close:visited {
    color: #1979c3;
    text-decoration: none;
}
.ui-dialog-titlebar-close:hover {
    color: #006bb4;
    text-decoration: underline;
}
.ui-dialog-titlebar-close:active {
    color: #ff5501;
    text-decoration: underline;
}
.ui-dialog-titlebar-close:hover,
.ui-dialog-titlebar-close:active,
.ui-dialog-titlebar-close:focus {
    background: none;
    border: 0;
}
.ui-dialog-titlebar-close.disabled,
.ui-dialog-titlebar-close[disabled],
fieldset[disabled] .ui-dialog-titlebar-close {
    color: #1979c3;
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline;
}
.block.related .action.select {
    margin: 0 5px;
}
.sidebar .product-items .product-item {
    margin-bottom: 20px;
    position: relative;
}
.sidebar .product-items .product-item-info {
    position: relative;
    width: auto;
}
.sidebar .product-items .product-item-info .product-item-photo {
    left: 0;
    position: absolute;
    top: 0;
}

.sidebar .product-items .product-item-details {
    margin: 0 0 0 85px;
}
.sidebar {
    display: block;
    margin-top: 10px;
}
.sidebar .product-items .price-box {
    display: block;
    margin: 7px 0;
}
.sidebar .product-items .text {
    margin-right: 8px;
}
.sidebar .product-items .counter {
    color: #7d7d7d;
    font-size: 1.2rem;
    white-space: nowrap;
}
.sidebar .product-items .minilist .price {
    display: inline;
    padding: 0;
}
.sidebar .product-items .minilist .weee:before {
    display: inline-block;
}
.sidebar .action.delete {
    position: absolute;
    right: 0;
    top: -1px;
}
.sidebar .block-wishlist .action.delete {
    line-height: unset;
    width: auto;
}
.sidebar .block-compare .action.delete {
    right: initial;
}
.sidebar .product-items-images {
    margin-left: -5px;
}
.sidebar .product-items-images .product-item {
    float: left;
    padding-left: 5px;
}
.sidebar .product-items-names .product-item {
    display: flex;
    margin-bottom: 10px;
}

.catalog-category-view.page-layout-1column .column.main {
    min-height: inherit;
}
body.catalog-product-compare-index .action.print {
    float: right;
    margin: 15px 0;
}
.table-wrapper.comparison {
    clear: both;
    max-width: 100%;
    overflow-x: auto;
}
.table-comparison {
    table-layout: fixed;
}
.table-comparison .cell.label,
.table-comparison td:last-child {
    border-right: 1px solid #d1d1d1;
}
.table-comparison .cell {
    padding: 15px;
    width: 140px;
}
.table-comparison .cell .attribute.value {
    overflow: hidden;
    width: 100%;
}
.table-comparison .cell.product.info,
.table-comparison .cell.product.label {
    border-bottom: 1px solid #d1d1d1;
}
.table-comparison .cell.label .attribute.label {
    display: block;
    width: 100%;
    word-wrap: break-word;
}
.table-comparison .cell.attribute {
    font-size: 1.3rem;
}
.table-comparison .cell.attribute img {
    height: auto;
    max-width: 100%;
}
.table-comparison .product-item-photo {
    display: block;
    margin: 0 auto 15px;
}
.table-comparison .product-item-actions,
.table-comparison .price-box,
.table-comparison .product.rating,
.table-comparison .product-item-name {
    display: block;
    margin: 15px 0;
}
.table-comparison .product-addto-links {
    margin-top: 15px;
}
.table-comparison .product-addto-links .action.split,
.table-comparison .product-addto-links .action.toggle {
    line-height: 1.2rem;
    padding: 5px 8px;
    font-size: 1.1rem;
}
.table-comparison .product-addto-links .action.toggle {
    padding: 0;
}
.table-comparison .cell.remove {
    padding-bottom: 0;
    padding-top: 0;
    text-align: right;
}
.table-comparison .product-item-actions > .actions-primary + .actions-secondary {
    margin-top: 10px;
}
.table-comparison .action.tocart {
    white-space: nowrap;
}
.comparison.headings {
    background: #ffffff;
    left: 0;
    position: absolute;
    top: 0;
    width: auto;
    z-index: 2;
}
.block-compare .product-item .product-item-name {
    margin-left: 22px;
}
.block-compare .action.delete {
    left: -6px;
    position: absolute;
    right: 0;
    top: 0;
}
.block-compare .actions-toolbar {
    margin: 17px 0 0;
}
.cart-summary {
    background: #f5f5f5;
    margin-bottom: 25px;
    padding: 1px 15px 25px;
}
.cart-summary > .title {
    display: block;
    font-weight: 300;
    line-height: 1.1;
    font-size: 1.8rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}
.cart-summary .block {
    margin-bottom: 0;
}
.cart-summary .block .item-options {
    margin-left: 0;
}
.cart-summary .block .fieldset {
    margin: 15px 0 25px 5px;
}
.cart-summary .block .fieldset .field {
    margin: 0 0 10px;
}
.cart-summary .block .fieldset .field.note {
    font-size: 12px;
}
.cart-summary .block .fieldset .methods .field > .label {
    display: inline;
}
.cart-totals {
    padding-top: 10px;
}
.cart-totals .table-wrapper {
    margin-bottom: 0;
    overflow: inherit;
}
.cart-totals .discount.coupon {
    display: none;
}
.cart.table-wrapper .items thead + .item {
    border-top: 1px solid #d1d1d1;
}
.cart.table-wrapper .items > .item {
    border-bottom: 1px solid #d1d1d1;
    position: relative;
}
.cart.table-wrapper .col {
    padding-top: 20px;
}
.cart.table-wrapper .col.qty .input-text {
    margin-top: -5px;
}
.cart.table-wrapper .item-actions td {
    padding-bottom: 10px;
    text-align: center;
    white-space: normal;
}
.cart.table-wrapper .item .col.item {
    display: block;
    min-height: 75px;
    padding: 25px 0 10px 75px;
    position: relative;
}
.cart.table-wrapper .actions-toolbar > .action {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    margin-right: 10px;
}
.cart.table-wrapper .actions-toolbar > .action:hover,
.cart.table-wrapper .actions-toolbar > .action:active,
.cart.table-wrapper .actions-toolbar > .action:focus {
    text-decoration: none;
}
.cart.table-wrapper .actions-toolbar > .action:last-child {
    margin-right: 0;
}
.cart.table-wrapper .action.help.map {
    font-weight: 400;
}
.cart.table-wrapper .product-item-photo {
    display: block;
    left: 0;
    max-width: 60px;
    padding: 0;
    position: absolute;
    top: 15px;
    width: 100%;
}
.cart.table-wrapper .product-item-details {
    white-space: normal;
}
.cart.table-wrapper .product-item-name {
    display: inline-block;
    font-weight: 400;
    margin-top: -6px;
}
.cart.table-wrapper .gift-registry-name-label:after {
    content: ':';
}
.cart.table-wrapper .item-options {
    font-size: 12px;
    margin-bottom: 10px;
}
.cart.table-wrapper .product-item-name + .item-options {
    margin-top: 10px;
}
.cart.table-wrapper .action.configure {
    display: inline-block;
    margin: 0 0 20px;
}
.cart-container .checkout-methods-items {
    margin-top: 20px;
    text-align: center;
}
.cart-container .checkout-methods-items .action.primary {
    width: 100%;
}
.cart-container .checkout-methods-items .item + .item {
    margin-top: 20px;
}
.cart-products-toolbar .toolbar-amount {
    margin: 25px 0 15px;
    padding: 0;
    text-align: center;
}
.cart-products-toolbar .pages {
    margin: 0 0 25px;
    text-align: center;
}
.cart-products-toolbar .pages .items > .item {
    border-bottom: 0;
}
.cart-products-toolbar-top {
    border-bottom: 1px solid #d1d1d1;
}
.block.crosssell {
    margin-top: 70px;
}
.cart-tax-info + .cart-tax-total,
.cart .cart-tax-info + .cart-tax-total {
    display: block;
}
.cart.table-wrapper .col.price,
.order-items.table-wrapper .col.price,
.cart.table-wrapper .col.qty,
.order-items.table-wrapper .col.qty,
.cart.table-wrapper .col.subtotal,
.order-items.table-wrapper .col.subtotal,
.cart.table-wrapper .col.msrp,
.order-items.table-wrapper .col.msrp {
    text-align: right;
}
.block-minicart .items-total {
    float: left;
}
.block-minicart .items-total .count {
    font-weight: 700;
}
.block-minicart .subtotal {
    margin: 0 10px 10px;
    text-align: right;
}
.block-minicart .amount .price-wrapper:first-child .price {
    font-size: 18px;
    font-weight: 700;
}
.block-minicart .subtitle {
    display: none;
}
.block-minicart .subtitle.empty {
    display: block;
    font-size: 14px;
    padding: 30px 0 20px;
    text-align: center;
}
.block-minicart .text.empty {
    text-align: center;
}
.block-minicart .block-content > .actions {
    text-align: center;
}

.block-minicart .block-content > .actions > .primary .action.primary {
    display: block;
    margin-bottom: 15px;
    width: 100%;
}
.block-minicart .block-content > .actions > .primary .action.primary:last-child {
    margin-bottom: 0;
}
.block-minicart .block-category-link,
.block-minicart .block-product-link,
.block-minicart .block-cms-link,
.block-minicart .block-banners {
    margin: 15px 0 0;
    text-align: center;
}
.minicart-wrapper {
    display: inline-block;
    position: relative;
    float: right;
}
.minicart-wrapper:before,
.minicart-wrapper:after {
    content: '';
    display: table;
}
.minicart-wrapper:after {
    clear: both;
}
.minicart-wrapper .action.showcart {
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
}
.minicart-wrapper .action.showcart:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 35px;
    line-height: 33px;
    color: #8f8f8f;
    content: '\e611';
    font-family: 'icons-blank-theme';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.minicart-wrapper .action.showcart:hover:before {
    color: #333333;
}
.minicart-wrapper .action.showcart:active:before {
    color: #8f8f8f;
}
.minicart-wrapper .action.showcart.active {
    display: inline-block;
    text-decoration: none;
}
.minicart-wrapper .action.showcart.active:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 35px;
    line-height: 33px;
    color: #8f8f8f;
    content: '\e611';
    font-family: 'icons-blank-theme';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.minicart-wrapper .action.showcart.active:hover:before {
    color: #333333;
}
.minicart-wrapper .action.showcart.active:active:before {
    color: #8f8f8f;
}
.minicart-wrapper .block-minicart {
    padding: 0;
    list-style: none none;
    z-index: 101;
    box-sizing: border-box;
    display: none;
    position: absolute;
}
.minicart-wrapper .block-minicart li {
    margin: 0;
}
.minicart-wrapper .block-minicart li:hover {
    cursor: pointer;
}

.minicart-wrapper .block-minicart:before {
    z-index: 99;
}
.minicart-wrapper .block-minicart:after {
    z-index: 98;
}
.minicart-wrapper .block-minicart:before {
    right: 26px;
    top: -12px;
}
.minicart-wrapper .block-minicart:after {
    right: 25px;
    top: -14px;
}
.minicart-wrapper.active {
    overflow: visible;
}
.minicart-wrapper.active .block-minicart {
    display: block;
}
.minicart-wrapper .block-minicart .block-title {
    display: none;
}
.minicart-wrapper .product .actions {
    float: right;
    margin: -28px 0 0;
    text-align: right;
}
.minicart-wrapper .product .actions > .primary,
.minicart-wrapper .product .actions > .secondary {
    display: inline;
}
.minicart-wrapper .action.close {
    display: inline-block;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    height: 40px;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
}
.minicart-wrapper .action.close > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.minicart-wrapper .action.close:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 32px;
    line-height: 32px;
    color: inherit;
    content: '\e616';
    font-family: 'icons-blank-theme';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.minicart-wrapper .action.close:hover:before {
    color: inherit;
}
.minicart-wrapper .action.close:active:before {
    color: inherit;
}
.minicart-wrapper .action.close:focus,
.minicart-wrapper .action.close:active {
    background: none;
    border: none;
}
.minicart-wrapper .action.close:hover {
    background: none;
    border: none;
}
.minicart-wrapper .action.close.disabled,
.minicart-wrapper .action.close[disabled],
fieldset[disabled] .minicart-wrapper .action.close {
    pointer-events: none;
    opacity: 0.5;
}
.minicart-wrapper .action.showcart {
    white-space: nowrap;
}

.minicart-wrapper .action.showcart .counter.qty.empty {
    display: none;
}
.minicart-wrapper .action.wishlist .counter.qty.empty {
    display: none;
}
.minicart-wrapper .action.showcart .counter.qty .loader > img {
    max-width: 24px;
}
.minicart-wrapper .minicart-widgets {
    margin-top: 15px;
}
.minicart-items-wrapper {
    margin: 0 -20px;
    border-left: 0;
    border-right: 0;
    overflow-x: auto;
}
.minicart-items {
    margin: 0;
    padding: 0;
    list-style: none none;
}

.minicart-items .product-item:first-child {
    padding-top: 0;
}
.minicart-items .product-item-pricing .label {
    display: inline-block;
}
.minicart-items .price-minicart {
    margin-bottom: 5px;
}
.minicart-items .message {
    margin-bottom: 0;
    margin-top: 10px;
}
.minicart-items .product > .product-item-photo,
.minicart-items .product > .product-image-container {
    float: left;
}
.minicart-items .product .toggle {
    display: block;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    white-space: nowrap;
}
.minicart-items .product .toggle:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 16px;
    color: inherit;
    content: '\e622';
    font-family: 'icons-blank-theme';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.minicart-items .product .toggle:after {
    position: static;
    right: 20px;
    top: 0;
}
.minicart-items .product.active > .toggle:after {
    content: '\e621';
}
.minicart-items .product-item-details {
    padding-left: 88px;
}
.minicart-items .product-item-details .price {
    font-weight: 700;
}
.minicart-items .product-item-details .price-including-tax,
.minicart-items .product-item-details .price-excluding-tax {
    margin: 5px 0 0;
}
.minicart-items .product-item-details .weee[data-label] {
    font-size: 1.1rem;
}
.minicart-items .product-item-details .details-qty {
    margin-top: 10px;
}
.minicart-items .product.options .tooltip.toggle {
    display: inline-block;
    text-decoration: none;
}
.minicart-items .product.options .tooltip.toggle > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.minicart-items .product.options .tooltip.toggle:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: inherit;
    content: '\e622';
    font-family: 'icons-blank-theme';
    margin: -3px 0 0 7px;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.minicart-items .product.options .tooltip.toggle .details {
    display: none;
}
.minicart-items .item-qty {
    text-align: center;
    width: 45px;
}
.minicart-items .update-cart-item {
    font-size: 1.1rem;
    margin-left: 5px;
    vertical-align: top;
}
.minicart-items .subtitle {
    display: none;
}
.minicart-items .action.edit,
.minicart-items .action.delete {
    display: inline-block;
    text-decoration: none;
}
.minicart-items .action.edit > span,
.minicart-items .action.delete > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.minicart-items .action.edit:before,
.minicart-items .action.delete:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: #303030;
    content: '\e606';
    font-family: 'icons-blank-theme';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.minicart-items .action.edit:hover:before,
.minicart-items .action.delete:hover:before {
    color: #303030;
}
.minicart-items .action.edit:active:before,
.minicart-items .action.delete:active:before {
    color: #303030;
}
.minicart-items .action.delete:before {
    content: '\e604';
}
.checkout-container {
    margin: 0 0 20px;
}
.opc-wrapper {
    margin: 0 0 20px;
}
.opc-wrapper .step-content {
    margin: 0 0 40px;
}
.checkout-index-index .nav-sections,
.checkout-index-index .nav-toggle {
    display: none;
}
.checkout-index-index .logo {
    margin-left: 0;
}
.checkout-onepage-success .print {
    display: none;
}
.opc-estimated-wrapper {
    border-bottom: 1px solid $light-dark;
    margin: 0 0 15px;
    padding: 18px 15px;
}
.opc-estimated-wrapper .estimated-block {
    font-size: 18px;
    font-weight: 700;
    float: left;
}
.opc-estimated-wrapper .estimated-block .estimated-label {
    display: block;
    margin: 0 0 5px;
}
.opc-estimated-wrapper .minicart-wrapper .action.showcart:before {
    color: #333333;
}
.opc-estimated-wrapper .minicart-wrapper .action.showcart:hover:before {
    color: #555555;
}
.field.choice .field-tooltip {
    display: inline-block;
    margin-left: 10px;
    position: relative;
    top: -3px;
}
.field.choice .field-tooltip-action {
    line-height: 24px;
}
// .field._error .control input,
// .field._error .control select,
// .field._error .control textarea {
//     border-color: #ed8380;
// }
.opc-wrapper .fieldset > .field > .label {
    font-weight: 400;
}
.field-tooltip {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 1px;
}
.field-tooltip._active {
    z-index: 100;
}
.field-tooltip._active .field-tooltip-content {
    display: block;
}
.field-tooltip._active .field-tooltip-action:before {
    color: #333333;
}
.field-tooltip .label {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.field-tooltip .field-tooltip-action {
    display: inline-block;
    text-decoration: none;
}
.field-tooltip .field-tooltip-action > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.field-tooltip .field-tooltip-action:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 24px;
    line-height: inherit;
    color: #bbbbbb;
    content: '\e623';
    font-family: 'icons-blank-theme';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.field-tooltip .field-tooltip-action:hover:before {
    color: #333333;
}
.field-tooltip .field-tooltip-action:before {
    padding-left: 1px;
}
._keyfocus .field-tooltip .field-tooltip-action:focus {
    z-index: 100;
}
._keyfocus .field-tooltip .field-tooltip-action:focus + .field-tooltip-content {
    display: block;
}
._keyfocus .field-tooltip .field-tooltip-action:f ocus:before {
    color: #333333;
}

.field-tooltip .field-tooltip-content:before {
    border-right-color: #666666;
}
.field-tooltip .field-tooltip-content:after {
    border-right-color: #f4f4f4;
    width: 1px;
    z-index: 4;
}
@media only screen and (max-width: 768px) {
    .field-tooltip .field-tooltip-content {
        left: auto;
        right: -10px;
        top: 40px;
    }
    .field-tooltip .field-tooltip-content::before,
    .field-tooltip .field-tooltip-content::after {
        border: 10px solid transparent;
        height: 0;
        left: auto;
        margin-top: -21px;
        right: 10px;
        top: 0;
        width: 0;
    }
    .field-tooltip .field-tooltip-content::before {
        border-bottom-color: #999999;
    }
    .field-tooltip .field-tooltip-content::after {
        border-bottom-color: #f4f4f4;
        top: 1px;
    }
}
.opc-wrapper .form-login,
.opc-wrapper .form-shipping-address {
    margin-top: 28px;
    margin-bottom: 20px;
}
.opc-wrapper .form-login .fieldset .note,
.opc-wrapper .form-shipping-address .fieldset .note {
    font-size: 14px;
    margin-top: 10px;
}
.opc-wrapper .shipping-address-items {
    font-size: 0;
}
.opc-wrapper .shipping-address-item {
    border: 2px solid transparent;
    line-height: 30px;
    margin: 0 0 20px;
    padding: 20px 35px 20px 20px;
    transition: 0.3s border-color;
    width: 50%;
    display: inline-block;
    font-size: 14px;
    position: relative;
    vertical-align: top;
    word-wrap: break-word;
}
//.opc-wrapper .shipping-address-item.selected-item {
//    border-color: #ff5501;
//}
.opc-wrapper .shipping-address-item.selected-item:after {
    //background: #ff5501;
    color: #ffffff;
    content: '\e610';
    font-family: 'icons-blank-theme';
    height: 27px;
    width: 29px;
    font-size: 27px;
    line-height: 21px;
    padding-top: 2px;
    position: absolute;
    right: 0;
    text-align: center;
    //top: 0;
}
.opc-wrapper .action-show-popup {
    margin: 0 0 20px;
}
//.opc-wrapper .action-show-popup > span:before {
//    content: '+';
//    padding-right: 5px;
//}
.opc-wrapper .action-select-shipping-item {
    float: right;
    margin: 20px 0 0;
}
.checkout-shipping-method .step-title {
    margin-bottom: 0;
}
.checkout-shipping-method .no-quotes-block {
    margin: 20px 0;
}
.methods-shipping .actions-toolbar .action.primary {
    margin: 20px 0 0;
}
.table-checkout-shipping-method thead th {
    display: none;
}
.table-checkout-shipping-method tbody td {
    //border-top: 1px solid #cccccc;
    padding-bottom: 20px;
    padding-top: 20px;
}
.table-checkout-shipping-method tbody td:first-child {
    padding-left: 0;
    padding-right: 0;
    width: 20px;
}
.table-checkout-shipping-method tbody tr:first-child td {
    border-top: none;
}
.table-checkout-shipping-method tbody .row-error td {
    border-top: none;
    padding-bottom: 10px;
    padding-top: 0;
}
.checkout-shipping-method {
    position: relative;
}
.shipping-policy-block.field-tooltip {
    top: 12px;
}
.shipping-policy-block.field-tooltip .field-tooltip-action {
    color: #1979c3;
    cursor: pointer;
}
.shipping-policy-block.field-tooltip .field-tooltip-action:before {
    display: none;
}
.shipping-policy-block.field-tooltip .field-tooltip-content {
    width: 420px;
    top: 30px;
}
.opc-block-shipping-information {
    padding: 0 30px;
}
.opc-block-shipping-information .shipping-information-title {
    border-bottom: 1px solid $light-dark;
    margin: 0 0 20px;
    position: relative;
}
.opc-block-shipping-information .shipping-information-title .action-edit {
    top: 2px;
    display: inline-block;
    text-decoration: none;
    margin: 0;
    position: absolute;
    right: 0;
}
.opc-block-shipping-information .shipping-information-title .action-edit > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.opc-block-shipping-information .shipping-information-title .action-edit:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: #8f8f8f;
    content: '\e606';
    font-family: 'icons-blank-theme';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.opc-block-shipping-information .shipping-information-title .action-edit:hover:before {
    color: #333333;
}
.opc-block-shipping-information .shipping-information-title .action-edit:active:before {
    color: #8f8f8f;
}
.opc-block-shipping-information .shipping-information-content {
    line-height: 27px;
}
.opc-block-shipping-information .shipping-information-content .actions-toolbar {
    margin-left: 0;
}
//.opc-block-shipping-information .ship-to,
//.opc-block-shipping-information .ship-via {
//    margin: 0 0 20px;
//}
.opc-block-summary {
    background: #f5f5f5;
    padding: 22px 30px;
    margin: 0 0 20px;
}
.opc-block-summary > .title {
    display: block;
}
.opc-block-summary .mark .value {
    color: #999999;
    display: block;
}
.opc-block-summary .discount.coupon {
    display: none;
}
.opc-block-summary .grand.incl + .grand.excl .mark,
.opc-block-summary .grand.incl + .grand.excl .amount {
    border-top: 0;
    font-size: 1.4rem;
    padding-top: 0;
}
.opc-block-summary .grand.incl + .grand.excl .mark strong,
.opc-block-summary .grand.incl + .grand.excl .amount strong {
    font-weight: 400;
}
.opc-block-summary .not-calculated {
    font-style: italic;
}
.opc-block-summary .items-in-cart > .title {
    //border-bottom: 1px solid #d1d1d1;
    padding: 10px 40px 10px 0;
    cursor: pointer;
    display: block;
    text-decoration: none;
    margin-bottom: 0;
    position: relative;
}
.opc-block-summary .items-in-cart > .title > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.opc-block-summary .items-in-cart > .title:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 30px;
    line-height: 12px;
    color: inherit;
    content: '\e622';
    font-family: 'icons-blank-theme';
    //margin: 3px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    //overflow: hidden;
    speak: none;
    text-align: center;
}
.opc-block-summary .items-in-cart > .title:after {
    //position: absolute;
    //right: 0;
    top: 10px;
}
.opc-block-summary .items-in-cart > .title strong {
    //font-size: 1.8rem;
    font-weight: 300;
    margin: 0;
}
//.opc-block-summary .items-in-cart.active > .title:after {
//    content: '\e621';
//}
.opc-block-summary .items-in-cart .product {
    position: relative;
}
.opc-block-summary .minicart-items-wrapper {
    margin: 0 -15px 0 0;
    //max-height: 370px;
    border: 0;
}
.column.main .opc-block-summary .product-item {
    margin: 0;
    padding-left: 0;
}
.opc-block-summary .product-item .product-item-inner {
    display: table;
    margin: 0 0 10px;
    width: 100%;
}
.opc-block-summary .product-item .product-item-name-block {
    display: table-cell;
    padding-right: 5px;
    text-align: left;
}
.opc-block-summary .product-item .subtotal {
    display: table-cell;
    text-align: right;
}
.opc-block-summary .product-item .price {
    font-weight: 400;
}
.opc-block-summary .product-item .price-including-tax + .price-excluding-tax {
    margin: 0;
}

.opc-block-summary .actions-toolbar {
    position: relative;
    z-index: 1;
}
.opc-block-summary .actions-toolbar .secondary {
    border-top: 1px solid #d1d1d1;
    display: block;
    float: none;
    margin: -1px 0 0;
    padding: 15px 0 0;
    text-align: left;
}
.opc-block-summary .actions-toolbar .secondary .action {
    margin: 0;
}
.authentication-dropdown {
    box-sizing: border-box;
}
.authentication-dropdown .modal-inner-wrap {
    padding: 25px;
}
._has-auth-shown .authentication-wrapper {
    z-index: 900;
}
.block-authentication .block-title {
    font-size: 1.8rem;
    border-bottom: 0;
}
.block-authentication {
    font-weight: 300;
}
.block-authentication .field .label {
    font-weight: 400;
}
.block-authentication .actions-toolbar {
    margin-bottom: 5px;
}
.block-authentication .actions-toolbar > .secondary {
    padding-top: 25px;
    text-align: left;
}
.block-authentication .block[class] {
    margin: 0;
}
.block-authentication .block[class] ul {
    padding-left: 10px;
}
.block-authentication .block[class] .field .control,
.block-authentication .block[class] .field .label {
    float: none;
    width: auto;
}
.block-authentication .block[class] + .block {
    border-top: 1px solid #c1c1c1;
    margin-top: 40px;
    padding-top: 40px;
    position: relative;
}
.block-authentication .block[class] + .block:before {
    height: 36px;
    line-height: 34px;
    margin: -19px 0 0 -18px;
    min-width: 36px;
    background: #ffffff;
    border: 1px solid #c1c1c1;
    border-radius: 50%;
    box-sizing: border-box;
    color: #c1c1c1;
    content: attr(data-label);
    display: inline-block;
    left: 50%;
    letter-spacing: normal;
    padding: 0 0.2rem;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 0;
}
.checkout-payment-method .step-title {
    border-bottom: 0;
    margin-bottom: 0;
}
.checkout-payment-method .payment-method:first-child .payment-method-title {
    border-top: 0;
}
.checkout-payment-method .payment-method._active .payment-method-content {
    display: block;
}

.checkout-payment-method .payment-method-title .payment-icon {
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
}
.checkout-payment-method .payment-method-title .action-help {
    display: inline-block;
    margin-left: 5px;
}

.checkout-payment-method .payment-method-content .fieldset:not(:last-child) {
    margin: 0 0 20px;
}
.checkout-payment-method .payment-group + .payment-group .step-title {
    margin: 20px 0 0;
}
.checkout-payment-method .field-select-billing,
.checkout-payment-method .billing-address-form {
    max-width: 600px;
}
.checkout-payment-method .billing-address-same-as-shipping-block {
    margin: 0 0 10px;
}
.checkout-payment-method .checkout-billing-address {
    margin: 0 0 20px;
}
.checkout-payment-method .checkout-billing-address .primary .action-update {
    margin-right: 0;
}
.checkout-payment-method .checkout-billing-address .billing-address-details {
    line-height: 27px;
    padding: 0 0 0 23px;
}
.checkout-payment-method .payment-method-note + .checkout-billing-address {
    margin-top: 20px;
}
.checkout-payment-method .payment-method-iframe {
    background-color: transparent;
    display: none;
    width: 100%;
}
.checkout-payment-method .no-payments-block {
    margin: 20px 0;
}
//.checkout-payment-method .payment-option._active .payment-option-title .action-toggle:after {
//    content: '\e621';
//}
.checkout-payment-method .payment-option._collapsible .payment-option-title {
    cursor: pointer;
}
.checkout-payment-method .payment-option._collapsible .payment-option-content {
    display: none;
}
//.checkout-payment-method .payment-option-title {
//    border-top: 1px solid #cccccc;
//    padding: 20px 0;
//}
.checkout-payment-method .payment-option-title .action-toggle {
    color: #1979c3;
    display: inline-block;
    text-decoration: none;
}
.checkout-payment-method .payment-option-title .action-toggle:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 32px;
    line-height: 16px;
    color: #8f8f8f;
    content: '\e622';
    font-family: 'icons-blank-theme';
    margin: 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.checkout-payment-method .payment-option-title .action-toggle:hover:after {
    color: #333333;
}
.checkout-payment-method .payment-option-title .action-toggle:active:after {
    color: #8f8f8f;
}
.checkout-payment-method .payment-option-content {
    padding: 0 0 20px 22px;
}
.checkout-payment-method .payment-option-inner {
    margin: 0 0 20px;
}
.checkout-payment-method .credit-card-types {
    padding: 0;
}
.checkout-payment-method .credit-card-types .item {
    display: inline-block;
    list-style: none;
    margin: 0 5px 0 0;
    vertical-align: top;
}
.checkout-payment-method .credit-card-types .item._active {
    font-weight: 700;
}
.checkout-payment-method .credit-card-types .item._active img {
    -webkit-filter: grayscale(0%);
}
.checkout-payment-method .credit-card-types .item._inactive {
    opacity: 0.4;
}
.checkout-payment-method .credit-card-types .item span {
    display: inline-block;
    padding-top: 6px;
    vertical-align: top;
}
.checkout-payment-method .credit-card-types img {
    -webkit-filter: grayscale(100%);
    -webkit-transition: all 0.6s ease;
}
.checkout-payment-method .ccard .fields > .year {
    padding-left: 5px;
}
.checkout-payment-method .ccard .fields .select {
    padding-left: 5px;
    padding-right: 5px;
}
.checkout-payment-method .ccard .month .select {
    width: 140px;
}
.checkout-payment-method .ccard .year .select {
    width: 80px;
}
.checkout-payment-method .ccard .captcha .input-text,
.checkout-payment-method .ccard .number .input-text {
    width: 225px;
}
.checkout-payment-method .ccard > .field.cvv > .control {
    padding-right: 20px;
    width: auto;
}
.checkout-payment-method .ccard .cvv .input-text {
    width: 55px;
}
.checkout-payment-method .ccard.fieldset > .field .fields.group.group-2 .field {
    width: auto !important;
}
.checkout-agreements-block .checkout-agreements {
    margin-bottom: 20px;
}
.checkout-agreements-block .checkout-agreement.field {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}
.checkout-agreements-block .checkout-agreement.field.required label:after {
    content: none;
}
.checkout-agreements-block .checkout-agreement.field.required .action-show:after {
    content: '*';
    color: #e02b27;
    font-size: 1.2rem;
}
.checkout-agreements-block .action-show {
    vertical-align: baseline;
}
.checkout-agreements-items {
    padding-bottom: 30px;
}
.checkout-agreements-items .checkout-agreements-item {
    margin-bottom: 20px;
}
.checkout-agreements-items .checkout-agreements-item-title {
    border-bottom: 0;
}
.checkout-agreement-item-content {
    overflow: auto;
}
/**
 * Copyright Â© Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
*/
.contact-index-index .column:not(.sidebar-main) .form.contact {
    float: none;
    width: 50%;
}
.contact-index-index .column:not(.sidebar-additional) .form.contact {
    float: none;
    width: 50%;
}
.login-container .block-new-customer .actions-toolbar {
    margin-top: 25px;
}
.login-container .block .block-title {
    font-size: 1.8rem;
}
.login-container .fieldset:after {
    margin: 10px 0 0;
    content: attr(data-hasrequired);
    display: block;
    letter-spacing: normal;
    word-spacing: normal;
    font-size: 1.2rem;
}
.login-container .fieldset:after {
    margin-top: 35px;
}
.block-addresses-list .items.addresses > .item {
    margin-bottom: 20px;
}
.block-addresses-list .items.addresses > .item:last-child {
    margin-bottom: 0;
}
.form-address-edit .region_id {
    display: none;
}
.form-edit-account .fieldset.password {
    display: none;
}
.box-billing-address .box-content,
.box-shipping-address .box-content,
.box-information .box-content,
.box-newsletter .box-content {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    line-height: 26px;
}
.fieldset .fullname.field > .label + .control {
    width: 100%;
}
.account .column.main h2 {
    margin-top: 0;
}
.account .column.main .toolbar {
    text-align: center;
}
.account .column.main .toolbar .limiter-options {
    width: auto;
}
.account .sidebar-additional {
    margin-top: 40px;
}
.account .table-wrapper:last-child {
    margin-bottom: 0;
}
.account .table-wrapper .action {
    margin-right: 15px;
}
.account .table-wrapper .action:last-child {
    margin-right: 0;
}
.account-nav .item {
    margin: 3px 0 0;
}
.account-nav .item:first-child {
    margin-top: 0;
}
.account-nav .item a,
.account-nav .item > strong {
    border-left: 3px solid transparent;
    display: block;
    padding: 5px 18px 5px 15px;
}
.account-nav .item a {
    text-decoration: none;
}
.account-nav .item a:hover {
    background: #e8e8e8;
}
.account-nav .item .delimiter {
    border-top: 1px solid #d1d1d1;
    display: block;
    margin: 10px 1.8rem;
}
.column.main .block:last-child {
    margin-bottom: 0;
}
.block .title {
    margin-bottom: 10px;
}
.block .title strong {
    font-weight: 700;
    line-height: 1.1;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.block p:last-child {
    margin: 0;
}
.block .box-actions {
    margin-top: 5px;
}
.field.password .control {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}
.field.password .control .mage-error {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
}
.field.password .control .input-text {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
    z-index: 2;
}
.password-strength-meter {
    height: 32px;
    line-height: 32px;
    padding: 0 9px;
    position: relative;
    z-index: 1;
}
.password-strength-meter:before {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    z-index: -1;
}
.password-none .password-strength-meter:before {
    width: 100%;
}
.password-weak .password-strength-meter:before {
    background-color: #ffafae;
    width: 25%;
}
.password-medium .password-strength-meter:before {
    background-color: #ffd6b3;
    width: 50%;
}
.password-strong .password-strength-meter:before {
    background-color: #c5eeac;
    width: 75%;
}
.password-very-strong .password-strength-meter:before {
    background-color: #81b562;
    width: 100%;
}
.control.captcha-image {
    margin-top: 10px;
}
.control.captcha-image .captcha-img {
    vertical-align: middle;
}
.block.filter {
    margin-bottom: 40px;
}

.block.filter .filter-title strong {
    font-size: 18px;
}
.block-subtitle {
    display: inline-block;
    margin-bottom: 10px;
}
.filter-current .item {
    margin-bottom: 10px;
}
.filter-current .filter-label {
    display: block;
    font-weight: 700;
}
.filter-current .filter-label:after {
    content: ':';
}
.filter-current + .filter-actions {
    margin-bottom: 30px;
}
.filter-options {
    margin: 0;
}
.filter-options .count {
    color: #7d7d7d;
}
.filter-options .count:before {
    content: '(';
}
.filter-options .count:after {
    content: ')';
}
.filtered .items {
    margin: 15px 0;
}
.filtered .item {
    margin-bottom: 6px;
    padding-left: 22px;
    position: relative;
}
.filtered .item .label {
    font-weight: 700;
}
.filtered .item .action.remove {
    left: -6px;
    position: absolute;
    top: 0;
}
.filtered + .actions {
    margin-bottom: 35px;
}
.order-links {
    border-bottom: 1px solid #d1d1d1;
    margin-bottom: 10px;
}
.order-links .item {
    display: inline-block;
    margin-right: 20px;
}
.order-actions-toolbar .action {
    margin: 0 20px 0 0;
}
.order-details-items {
    border-bottom: 1px solid $light-dark;
    margin-bottom: 20px;
    padding-bottom: 10px;
}
.order-details-items .order-title > strong {
    font-weight: 300;
    line-height: 1.1;
    font-size: 1.8rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    display: inline-block;
}
.order-details-items .items-qty .item {
    white-space: nowrap;
}
.order-details-items .items-qty .title:after {
    content: ': ';
}
.order-details-items .table-order-items .product-item-name {
    margin-top: 0;
}
.order-details-items .table-order-items tbody + tbody {
    border-top: 1px solid #373738;
}
.order-details-items .item-options dt {
    margin: 0;
}
.order-details-items .item-options dd {
    margin: 0 0 15px;
}
.order-details-items .item-options.links dt {
    display: inline-block;
}
.order-details-items .item-options.links dt:after {
    content: ': ';
}
.order-details-items .item-options.links dd {
    margin: 0;
}
.order-pager-wrapper .toolbar-amount {
    padding-top: 3px;
}
.order-pager-wrapper .pages-items .item:first-child,
.order-pager-wrapper .pages-items .item:last-child {
    margin-right: 0;
}
.order-actions-toolbar {
    margin-bottom: 25px;
}
.order-actions-toolbar .action {
    margin-right: 30px;
}
.order-actions-toolbar .action.print {
    margin: 0;
}
.page-title-wrapper .order-date {
    margin: 0 0 20px;
}
.account .page-title-wrapper .page-title,
[class^='sales-guest-'] .page-title-wrapper .page-title,
.sales-guest-view .page-title-wrapper .page-title {
    margin-right: 25px;
}
.form-orders-search .field.email,
.form-orders-search .field.zip {
    margin-bottom: 0;
}
.block-reorder .product-item .checkbox {
    position: relative;
    top: 2px;
    vertical-align: baseline;
}
.block-reorder .product-item .field.item {
    display: inline-block;
}
.block-reorder .product-item-name {
    display: inline-block;
}
.block-reorder .actions-toolbar {
    margin: 17px 0;
    max-width: 178px;
}
.block-order-details-comments {
    margin: 0 0 40px;
}
.block-order-details-comments .comment-date {
    font-weight: 600;
}
.block-order-details-comments .comment-content {
    line-height: 1.6;
    margin: 0 0 20px;
}
.block-order-details-view .box-content .payment-method .title {
    font-weight: 400;
}
.block-order-details-view .box-content .payment-method .content {
    margin: 0;
}
.block-order-details-view .box-content .payment-method .content > strong {
    font-weight: 400;
}
.block-order-details-view .box-content .payment-method .content > strong:after {
    content: ': ';
}
.order-tracking {
    border-bottom: 1px solid #d1d1d1;
    margin: 0;
    padding: 20px 0;
}
.order-tracking .tracking-title {
    display: inline-block;
}
.order-tracking .tracking-content {
    display: inline-block;
    margin: 0 0 0 5px;
}
.opc-wrapper .form-discount {
    max-width: 500px;
}
.block-collapsible-nav .content {
    background: #f5f5f5;
    padding: 15px 0;
}
.block-collapsible-nav .item {
    margin: 3px 0 0;
}
.block-collapsible-nav .item:first-child {
    margin-top: 0;
}
.block-collapsible-nav .item a,
.block-collapsible-nav .item > strong {
    border-left: 3px solid transparent;
    color: #575757;
    display: block;
    padding: 5px 18px 5px 15px;
}
.block-collapsible-nav .item a {
    text-decoration: none;
}
.block-collapsible-nav .item a:hover {
    background: #e8e8e8;
}
.block-collapsible-nav .item.current a,
.block-collapsible-nav .item.current > strong {
    border-color: #ff5501;
    color: #000000;
    font-weight: 600;
}
.block-collapsible-nav .item.current a {
    border-color: #ff5501;
}
.block-collapsible-nav .item .delimiter {
    border-top: 1px solid #d1d1d1;
    display: block;
    margin: 10px 1.8rem;
}
body {
    background-color: #ffffff;
}
.page-wrapper {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100vh;
}
.page-main {
    -webkit-flex-grow: 1;
    flex-grow: 1;
}
.logo {
    float: left;
    margin: 0 0 10px 40px;
    max-width: 50%;
    position: relative;
    z-index: 5;
}
.logo img {
    display: block;
    height: auto;
}
.page-print .logo {
    float: none;
}
.page-main > .page-title-wrapper .page-title + .action {
    margin-top: 30px;
}
.action.skip:focus {
    background: #f0f0f0;
    padding: 10px;
    box-sizing: border-box;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 15;
}
.action-skip-wrapper {
    height: 0;
    position: relative;
}
.message.global p {
    margin: 0;
}
.message.global.noscript,
.message.global.cookie {
    margin: 0 0 10px;
    padding: 10px 20px;
    display: block;
    line-height: 1.2em;
    font-size: 1.3rem;
    background: #ffee9c;
    border-color: #d6ca8e;
    color: #333333;
    margin: 0;
}
.message.global.noscript a,
.message.global.cookie a {
    color: #1979c3;
}
.message.global.noscript a:hover,
.message.global.cookie a:hover {
    color: #006bb4;
}
.message.global.noscript a:active,
.message.global.cookie a:active {
    color: #ff5501;
}
.message.global.cookie {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 3;
}
.message.global.cookie .actions {
    margin-top: 10px;
}
.message.global.demo {
    margin: 0 0 10px;
    padding: 10px 20px;
    display: block;
    line-height: 1.2em;
    font-size: 1.3rem;
    background: #ff0101;
    border-color: none;
    color: #ffffff;
    margin-bottom: 0;
    text-align: center;
}
.message.global.demo a {
    color: #1979c3;
}
.message.global.demo a:hover {
    color: #006bb4;
}
.message.global.demo a:active {
    color: #ff5501;
}
.page-footer {
    margin-top: auto;
}
.footer.content {
    margin-top: 25px;
    padding-top: 25px;
}
.footer.content .links > li {
    margin: 0 0 8px;
}
.footer.content .switcher-store {
    margin: 0 0 30px;
}
.footer .copyright,
.footer .bugs {
    display: block;
    margin: 20px 0 0;
}
.page-header .panel.wrapper .switcher,
.page-footer .switcher {
    margin-right: 10px;
}
.page-header .panel.wrapper .switcher .options,
.page-footer .switcher .options {
    display: inline-block;
    position: relative;
}
.page-header .panel.wrapper .switcher .options:before,
.page-footer .switcher .options:before,
.page-header .panel.wrapper .switcher .options:after,
.page-footer .switcher .options:after {
    content: '';
    display: table;
}
.page-header .panel.wrapper .switcher .options:after,
.page-footer .switcher .options:after {
    clear: both;
}
.page-header .panel.wrapper .switcher .options .action.toggle,
.page-footer .switcher .options .action.toggle {
    padding: 0;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
}
.page-header .panel.wrapper .switcher .options .action.toggle > span,
.page-footer .switcher .options .action.toggle > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.page-header .panel.wrapper .switcher .options .action.toggle:after,
.page-footer .switcher .options .action.toggle:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: 22px;
    color: inherit;
    content: '\e622';
    font-family: 'icons-blank-theme';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.page-header .panel.wrapper .switcher .options .action.toggle:hover:after,
.page-footer .switcher .options .action.toggle:hover:after {
    color: inherit;
}
.page-header .panel.wrapper .switcher .options .action.toggle:active:after,
.page-footer .switcher .options .action.toggle:active:after {
    color: inherit;
}
.page-header .panel.wrapper .switcher .options .action.toggle.active,
.page-footer .switcher .options .action.toggle.active {
    display: inline-block;
    text-decoration: none;
}
.page-header .panel.wrapper .switcher .options .action.toggle.active > span,
.page-footer .switcher .options .action.toggle.active > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.page-header .panel.wrapper .switcher .options .action.toggle.active:after,
.page-footer .switcher .options .action.toggle.active:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: 22px;
    color: inherit;
    content: '\e621';
    font-family: 'icons-blank-theme';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}
.page-header .panel.wrapper .switcher .options .action.toggle.active:hover:after,
.page-footer .switcher .options .action.toggle.active:hover:after {
    color: inherit;
}
.page-header .panel.wrapper .switcher .options .action.toggle.active:active:after,
.page-footer .switcher .options .action.toggle.active:active:after {
    color: inherit;
}
.page-header .panel.wrapper .switcher .options ul.dropdown,
.page-footer .switcher .options ul.dropdown {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #ffffff;
    border: 1px solid #bbbbbb;
    margin-top: 4px;
    min-width: 160px;
    z-index: 100;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
}
.page-header .panel.wrapper .switcher .options ul.dropdown li,
.page-footer .switcher .options ul.dropdown li {
    margin: 0;
    padding: 0;
}
.page-header .panel.wrapper .switcher .options ul.dropdown li:hover,
.page-footer .switcher .options ul.dropdown li:hover {
    background: #e8e8e8;
    cursor: pointer;
}
.page-header .panel.wrapper .switcher .options ul.dropdown:before,
.page-footer .switcher .options ul.dropdown:before,
.page-header .panel.wrapper .switcher .options ul.dropdown:after,
.page-footer .switcher .options ul.dropdown:after {
    border-bottom-style: solid;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0;
}
.page-header .panel.wrapper .switcher .options ul.dropdown:before,
.page-footer .switcher .options ul.dropdown:before {
    border: 6px solid;
    border-color: transparent transparent #ffffff transparent;
    z-index: 99;
}
.page-header .panel.wrapper .switcher .options ul.dropdown:after,
.page-footer .switcher .options ul.dropdown:after {
    border: 7px solid;
    border-color: transparent transparent #bbbbbb transparent;
    z-index: 98;
}
.page-header .panel.wrapper .switcher .options ul.dropdown:before,
.page-footer .switcher .options ul.dropdown:before {
    left: 10px;
    top: -12px;
}
.page-header .panel.wrapper .switcher .options ul.dropdown:after,
.page-footer .switcher .options ul.dropdown:after {
    left: 9px;
    top: -14px;
}
.page-header .panel.wrapper .switcher .options.active,
.page-footer .switcher .options.active {
    overflow: visible;
}
.page-header .panel.wrapper .switcher .options.active ul.dropdown,
.page-footer .switcher .options.active ul.dropdown {
    display: block;
}
.page-header .panel.wrapper .switcher .options ul.dropdown a,
.page-footer .switcher .options ul.dropdown a {
    display: block;
    padding: 8px;
}
.page-header .panel.wrapper .switcher .options ul.dropdown a:hover,
.page-footer .switcher .options ul.dropdown a:hover {
    text-decoration: none;
}
.page-header .panel.wrapper .switcher li,
.page-footer .switcher li {
    font-size: 12px;
    margin: 0;
}
.page-header .panel.wrapper .switcher strong,
.page-footer .switcher strong {
    font-weight: 400;
}
.widget {
    clear: both;
}
.page-header .widget.block,
.page-footer .widget.block {
    margin: 20px 0;
}
.ui-datepicker td {
    padding: 0;
}
.toolbar.wishlist-toolbar .limiter {
    float: right;
}
.toolbar.wishlist-toolbar .main .pages {
    display: inline-block;
    position: relative;
    z-index: 0;
}
.toolbar.wishlist-toolbar .toolbar-amount,
.toolbar.wishlist-toolbar .limiter {
    display: inline-block;
    z-index: 1;
}

.account .table-wrapper .data.table.wishlist > thead > tr > th,
.account .table-wrapper .data.table.wishlist > tbody > tr > th,
.account .table-wrapper .data.table.wishlist > tfoot > tr > th,
.account .table-wrapper .data.table.wishlist > thead > tr > td,
.account .table-wrapper .data.table.wishlist > tbody > tr > td,
.account .table-wrapper .data.table.wishlist > tfoot > tr > td {
    border-top: 1px solid $light-dark;
}
.account .table-wrapper .data.table.wishlist > caption + thead > tr:first-child > th,
.account .table-wrapper .data.table.wishlist > colgroup + thead > tr:first-child > th,
.account .table-wrapper .data.table.wishlist > thead:first-child > tr:first-child > th,
.account .table-wrapper .data.table.wishlist > caption + thead > tr:first-child > td,
.account .table-wrapper .data.table.wishlist > colgroup + thead > tr:first-child > td,
.account .table-wrapper .data.table.wishlist > thead:first-child > tr:first-child > td {
    border-top: 0;
}
.account .table-wrapper .data.table.wishlist > tbody + tbody {
    border-top: 1px solid #d1d1d1;
}
.account .table-wrapper .data.table.wishlist thead > tr > th {
    border-bottom: 0;
}
.account .table-wrapper .data.table.wishlist tbody > tr:last-child > td {
    border-bottom: 1px solid #d1d1d1;
}
.account .table-wrapper .data.table.wishlist .product.name {
    display: inline-block;
    margin-bottom: 10px;
}
.account .table-wrapper .data.table.wishlist .box-tocart {
    margin: 10px 0;
}
.account .table-wrapper .data.table.wishlist .box-tocart .qty {
    vertical-align: middle;
}
.account .table-wrapper .data.table.wishlist .col.item {
    width: 50%;
}
.account .table-wrapper .data.table.wishlist .col.photo {
    max-width: 150px;
}
.account .table-wrapper .data.table.wishlist .col.selector {
    max-width: 15px;
}
.account .table-wrapper .data.table.wishlist textarea {
    margin: 10px 0;
}
.account .table-wrapper .data.table.wishlist .input-text.qty {
    margin-bottom: 10px;
}
.account .table-wrapper .data.table.wishlist .action.primary {
    vertical-align: top;
}
.account .table-wrapper .data.table.wishlist .price {
    font-weight: 700;
}
.block-wishlist .product-item-name {
    margin-right: 25px;
}

.products-grid.wishlist .product-item:nth-child(3n + 1) {
    margin-left: 0;
}
.products-grid.wishlist .product-item-photo {
    display: block;
    margin-bottom: 10px;
}
.products-grid.wishlist .product-item-name {
    margin-top: 0;
}

.products-grid.wishlist .product-item-comment {
    display: block;
    height: 42px;
}

.products-grid.wishlist .product-item .box-tocart input.qty {
    height: 32px;
}
.block-product-link.widget,
.block-category-link.widget {
    display: block;
    margin-bottom: 20px;
}
.block-product-link-inline.widget {
    margin: 0;
}
.block.widget .products-grid .product-item {
    width: calc((100% - 2%) / 2);
}
.block.widget .products-grid .product-item:nth-child(2n + 1) {
    margin-left: 0;
}
.block.widget .product-item-info {
    width: auto;
}
.block.widget .pager {
    padding: 0;
}
.block.widget .pager .toolbar-amount {
    float: none;
    font-size: 1.2rem;
}
.block.widget .pager .pages-item-previous {
    padding-left: 0;
}
.block.widget .pager .pages-item-next {
    position: relative;
}
.block.widget .pager .items {
    white-space: nowrap;
}
.block-static-block.widget,
.block-cms-link.widget {
    margin-bottom: 20px;
}
.links .block-static-block.widget,
.links .block-cms-link.widget {
    margin-bottom: 0;
}
.block-cms-link-inline.widget {
    margin: 0;
}
@media only screen and (max-width: 768px) {
    .field-tooltip .field-tooltip-content {
        right: -10px;
        top: 40px;
        left: auto;
    }
}
@media only screen and (max-width: 767px) {
    .abs-button-responsive-smaller,
    .product-info-main .box-tocart .actions .action.tocart {
        width: 100%;
    }
    .abs-visually-hidden-mobile-m {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    .abs-add-clearfix-mobile-m:before,
    .abs-add-clearfix-mobile-m:after {
        content: '';
        display: table;
    }
    .abs-add-clearfix-mobile-m:after {
        clear: both;
    }
    .abs-icon-add-mobile {
        display: block;
        text-decoration: none;
    }
    .abs-icon-add-mobile:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 10px;
        line-height: 10px;
        color: inherit;
        content: '\e61c';
        font-family: 'icons-blank-theme';
        margin: 0 5px 0 0;
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center;
    }
    .abs-toggling-title-mobile,
    .block-collapsible-nav .title {
        border-bottom: 1px solid #d1d1d1;
        border-top: 1px solid #d1d1d1;
        cursor: pointer;
        margin-bottom: 0;
        padding: 10px 40px 10px 15px;
        position: relative;
        display: block;
        text-decoration: none;
    }
    .abs-toggling-title-mobile:after,
    .block-collapsible-nav .title:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 28px;
        line-height: inherit;
        color: inherit;
        content: '\e622';
        font-family: 'icons-blank-theme';
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center;
    }
    .block-collapsible-nav .title:after {
        position: absolute;
        right: 10px;
        top: 0;
    }
    .abs-toggling-title-mobile.active:after,
    .block-collapsible-nav .title.active:after {
        content: '\e621';
    }
    .abs-pager-toolbar-mobile .toolbar-amount,
    .abs-pager-toolbar-mobile .limiter,
    .abs-pager-toolbar-mobile .pages,
    .account .toolbar .toolbar-amount,
    .account .toolbar .limiter,
    .account .toolbar .pages {
        float: none;
        margin-bottom: 25px;
    }
    .abs-shopping-cart-items-mobile .actions,
    .cart-container .form-cart .actions {
        text-align: center;
    }
    .cart-container .form-cart .action.update,
    .cart-container .form-cart .action.continue,
    .cart-container .form-cart .action.clear {
        margin: 0 auto 10px;
    }
    .cart-container .form-cart .action.update,
    .cart-container .form-cart .action.clear {
        display: block;
    }
    .abs-checkout-tooltip-content-position-top-mobile,
    .field-tooltip .field-tooltip-content {
        right: -10px;
        top: 40px;
        left: auto;
    }
    .field-tooltip .field-tooltip-content:before,
    .field-tooltip .field-tooltip-content:after {
        border: 10px solid transparent;
        height: 0;
        width: 0;
        margin-top: -21px;
        right: 10px;
        left: auto;
        top: 0;
    }
    .field-tooltip .field-tooltip-content:before {
        border-bottom-color: #999999;
    }
    .field-tooltip .field-tooltip-content:after {
        border-bottom-color: #f4f4f4;
        top: 1px;
    }
    //.navigation,
    //.breadcrumbs,
    //.page-header .header.panel,
    //.page-main,
    //.page-wrapper > .widget,
    //.page-wrapper > .page-bottom,
    //.block.category.event,
    //.top-container {
    //    padding-left: 15px;
    //    padding-right: 15px;
    //}
    .account .page-main,
    .cms-privacy-policy .page-main {
        padding-top: 41px;
        position: relative;
    }
    .navigation {
        padding: 0;
    }
    .nav-sections {
        -webkit-overflow-scrolling: touch;
        -webkit-transition: left 0.3s;
        -moz-transition: left 0.3s;
        -ms-transition: left 0.3s;
        transition: left 0.3s;
        height: 100%;
        left: calc(-1 * (100% - 54px));
        overflow: auto;
        position: fixed;
        top: 0;
        width: calc(100% - 54px);
    }
    .nav-sections .switcher {
        border-top: 1px solid #d1d1d1;
        font-size: 1.6rem;
        font-weight: 700;
        margin: 0;
        padding: 0.8rem 3.5rem 0.8rem 2rem;
    }
    .nav-sections .switcher .label {
        display: block;
        margin-bottom: 5px;
    }
    .nav-sections .switcher:last-child {
        border-bottom: 1px solid #d1d1d1;
    }
    .nav-sections .switcher-trigger strong {
        display: block;
        text-decoration: none;
        position: relative;
    }
    .nav-sections .switcher-trigger strong:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 42px;
        line-height: inherit;
        color: inherit;
        content: '\e622';
        font-family: 'icons-blank-theme';
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center;
    }
    .nav-sections .switcher-trigger strong:after {
        position: absolute;
        right: -40px;
        top: -16px;
    }
    .nav-sections .switcher-trigger.active strong:after {
        content: '\e621';
    }
    .nav-sections .switcher-dropdown {
        margin: 0;
        padding: 0;
        list-style: none none;
        display: none;
        padding: 10px 0;
    }
    .nav-sections .switcher-options.active .switcher-dropdown {
        display: block;
    }
    .nav-sections .header.links {
        margin: 0;
        padding: 0;
        list-style: none none;
        border-bottom: 1px solid #d1d1d1;
    }
    .nav-sections .header.links li {
        font-size: 1.6rem;
        margin: 0;
    }
    .nav-sections .header.links li.greet.welcome {
        border-top: 1px solid #d1d1d1;
        font-weight: 700;
        padding: 0.8rem 15px;
    }
    .nav-sections .header.links li > a {
        border-top: 1px solid #d1d1d1;
    }
    .nav-sections .header.links a,
    .nav-sections .header.links a:hover {
        color: #575757;
        text-decoration: none;
        display: block;
        font-weight: 700;
        padding: 0.8rem 15px;
    }
    .nav-sections .header.links .header.links {
        border: 0;
    }
    .nav-before-open {
        height: 100%;
        overflow-x: hidden;
        width: 100%;
    }
    .nav-before-open .page-wrapper {
        -webkit-transition: left 0.3s;
        -moz-transition: left 0.3s;
        -ms-transition: left 0.3s;
        transition: left 0.3s;
        height: 100%;
        left: 0;
        overflow: hidden;
        position: relative;
    }
    .nav-before-open body {
        height: 100%;
        overflow: hidden;
        position: relative;
        width: 100%;
    }
    .nav-open .page-wrapper {
        left: calc(100% - 54px);
    }
    .nav-open .nav-sections {
        -webkit-box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
        -moz-box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
        -ms-box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
        box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
        left: 0;
        z-index: 99;
    }
    .nav-open .nav-toggle:after {
        background: rgba(0, 0, 0, 0.5);
        content: '';
        display: block;
        height: 100%;
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 1;
    }
    .nav-sections-items {
        position: relative;
        z-index: 1;
    }
    .nav-sections-items:before,
    .nav-sections-items:after {
        content: '';
        display: table;
    }
    .nav-sections-items:after {
        clear: both;
    }
    .nav-sections-item-title {
        background: #e8e8e8;
        border: solid #dbdbdb;
        border-width: 0 0 1px 1px;
        box-sizing: border-box;
        float: left;
        height: 71px;
        padding-top: 24px;
        text-align: center;
        width: 33.33%;
    }
    .nav-sections-item-title.active {
        background: transparent;
        border-bottom: 0;
    }
    .nav-sections-item-title .nav-sections-item-switch:hover {
        text-decoration: none;
    }
    .nav-sections-item-content {
        box-sizing: border-box;
        float: right;
        margin-left: -100%;
        margin-top: 71px;
        width: 100%;
        padding: 25px 0;
    }
    .nav-sections-item-content:before,
    .nav-sections-item-content:after {
        content: '';
        display: table;
    }
    .nav-sections-item-content:after {
        clear: both;
    }
    .nav-sections-item-content.active {
        display: block;
    }
    .navigation ul {
        margin: 0;
        padding: 0;
    }
    .navigation li {
        margin: 0;
    }
    .product.data.items {
        margin: 0;
        padding: 0;
    }
    .product.data.items > .item.title {
        box-sizing: border-box;
        float: none;
        width: 100%;
    }
    .product.data.items > .item.title > .switch {
        display: block;
    }
    .product.data.items > .item.content {
        box-sizing: border-box;
        display: block;
        float: none;
        margin: 0;
    }
    .product.data.items > .item.content:before,
    .product.data.items > .item.content:after {
        content: '';
        display: table;
    }
    .product.data.items > .item.content:after {
        clear: both;
    }
    .product.data.items > .item.content.active {
        display: block;
    }
    .product.data.items > .item.title {
        margin: 0 0 5px;
    }
    .product.data.items > .item.title > .switch {
        background: #f0f0f0;
        border-bottom: 1px solid #d1d1d1;
        border-left: 1px solid #d1d1d1;
        border-right: 1px solid #d1d1d1;
        border-top: 1px solid #d1d1d1;
        height: 40px;
        padding: 5px 20px 5px 20px;
        font-weight: 600;
        line-height: 40px;
        font-size: 1.8rem;
        color: #7d7d7d;
        text-decoration: none;
    }
    .product.data.items > .item.title > .switch:visited {
        color: #7d7d7d;
        text-decoration: none;
    }
    .product.data.items > .item.title > .switch:hover {
        color: #7d7d7d;
        text-decoration: none;
    }
    .product.data.items > .item.title > .switch:active {
        color: #333333;
        text-decoration: none;
    }
    .product.data.items > .item.title:not(.disabled) > .switch:focus,
    .product.data.items > .item.title:not(.disabled) > .switch:hover {
        background: #fcfcfc;
    }
    .product.data.items > .item.title:not(.disabled) > .switch:active,
    .product.data.items > .item.title.active > .switch,
    .product.data.items > .item.title.active > .switch:focus,
    .product.data.items > .item.title.active > .switch:hover {
        background: #ffffff;
        color: #333333;
        text-decoration: none;
        padding-bottom: 5px;
    }
    .product.data.items > .item.content {
        background: #ffffff;
        border: 1px solid #d1d1d1;
        margin: 0 0 5px;
        padding: 20px 20px 20px 20px;
    }
    .product.data.items .data.item {
        display: block;
    }
    .product.data.items .item.title > .switch {
        padding: 1px 15px 1px;
    }
    .product.data.items > .item.content {
        padding: 10px 15px 30px;
    }
    .modal-popup.modal-slide._show .modal-inner-wrap {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    .modal-popup.modal-slide .modal-inner-wrap {
        overflow-y: auto;
        position: static;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        -webkit-transition: -webkit-transform 0.3s ease-in-out;
        transition: transform 0.3s ease-in-out;
        // width: auto;
    }
    .custom-slide {
        bottom: 0;
        left: 0;
        min-width: 0;
        position: fixed;
        right: 0;
        top: 0;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: visibility 0s 0.3s, opacity 0.3s ease;
        transition: visibility 0s 0.3s, opacity 0.3s ease;
        left: 44px;
        z-index: 900;
    }
    .custom-slide._show {
        visibility: visible;
        opacity: 1;
        -webkit-transition: opacity 0.3s ease;
        transition: opacity 0.3s ease;
    }
    .custom-slide._show .modal-inner-wrap {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    .custom-slide .modal-inner-wrap {
        //background-color: #ffffff;
        //box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.35);
        opacity: 1;
        pointer-events: auto;
    }
    .custom-slide._show .modal-inner-wrap {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    .custom-slide .modal-inner-wrap {
        overflow-y: auto;
        position: static;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        -webkit-transition: -webkit-transform 0.3s ease-in-out;
        transition: transform 0.3s ease-in-out;
        width: auto;
    }
    .custom-slide._show {
        -webkit-overflow-scrolling: touch;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .custom-slide .modal-inner-wrap {
        box-sizing: border-box;
    }
    body._has-modal-custom {
        height: 100vh;
        overflow: hidden;
        width: 100vw;
    }
    body._has-modal-custom .modal-custom-overlay {
        background-color: rgba(51, 51, 51, 0.55);
    }
    .modal-popup {
        pointer-events: auto;
    }

    .modal-popup.modal-slide._inner-scroll._show {
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
    }
    .modal-popup.modal-slide._inner-scroll .modal-inner-wrap {
        height: auto;
    }

    .lac-notification {
        padding: 5px 0;
    }
    .lac-notification .lac-notification-icon {
        display: none;
    }
    .lac-notification .lac-notification-text,
    .lac-notification .lac-notification-links {
        float: none;
        padding: 5px 0;
        text-align: center;
    }
    .catalog-product-view .column.main {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .block.related .action.select {
        display: block;
        margin: 5px 0;
    }
    .compare,
    .product-addto-links .action.tocompare,
    .product-item-actions .actions-secondary > .action.tocompare {
        display: none;
    }
    .cart.table-wrapper {
        overflow: inherit;
    }
    .cart.table-wrapper thead .col:not(.item) {
        display: none;
    }
    .cart.table-wrapper .col.qty,
    .cart.table-wrapper .col.price,
    .cart.table-wrapper .col.subtotal,
    .cart.table-wrapper .col.msrp {
        box-sizing: border-box;
        display: block;
        float: left;
        text-align: center;
        white-space: nowrap;
        width: 33%;
    }
    .cart.table-wrapper .col.qty[data-th]:before,
    .cart.table-wrapper .col.price[data-th]:before,
    .cart.table-wrapper .col.subtotal[data-th]:before,
    .cart.table-wrapper .col.msrp[data-th]:before {
        content: attr(data-th) ':';
        display: block;
        font-weight: 700;
        padding-bottom: 10px;
    }
    .cart.table-wrapper .col.msrp {
        white-space: normal;
    }
    .cart.table-wrapper .item .col.item {
        padding-bottom: 0;
    }
    .field-tooltip .field-tooltip-content {
        right: -10px;
        top: 40px;
        left: auto;
    }
    //.checkout-payment-method .payment-methods {
    //    margin: 0 -15px;
    //}
    .checkout-payment-method .step-title {
        padding-left: 15px;
        padding-right: 15px;
    }

    .checkout-payment-method .checkout-billing-address .action-cancel {
        margin-top: 10px;
    }
    .checkout-payment-method .payment-option .payment-option-title {
        padding: 15px;
    }
    .checkout-payment-method .payment-option .payment-option-content {
        padding: 0 15px 20px;
    }
    .contact-index-index .column:not(.sidebar-main) .form.contact {
        float: none;
        width: 100%;
    }
    .contact-index-index .column:not(.sidebar-additional) .form.contact {
        float: none;
        width: 100%;
    }
    .login-container .fieldset:after {
        text-align: center;
    }
    .account .messages {
        margin-bottom: 0;
    }
    .control.captcha-image .captcha-img {
        margin-bottom: 10px;
        display: block;
    }
    .customer-account-index .page-title-wrapper {
        position: relative;
    }
    .form.search.advanced .field.price .with-addon .input-text {
        flex-basis: auto;
        width: 100%;
    }
    .page-with-filter .columns .sidebar-main {
        -ms-flex-order: 0;
        -webkit-order: 0;
        order: 0;
    }
    .order-pager-wrapper .toolbar-amount {
        left: inherit;
        position: relative;
        text-align: center;
        top: inherit;
    }
    .order-pager-wrapper .pages {
        text-align: center;
    }
    .order-pager-wrapper .action.previous,
    .order-pager-wrapper .action.next {
        margin: 0;
    }
    .block-collapsible-nav {
        left: 0;
        position: absolute;
        top: -21px;
        width: 100%;
        z-index: 5;
    }
    .block-collapsible-nav .content {
        border-bottom: 1px solid #d1d1d1;
        display: none;
    }
    .block-collapsible-nav .content.active {
        display: block;
    }
    .products-grid.wishlist {
        margin-bottom: 30px;
        margin-right: 0;
        width: 100%;
    }
    .products-grid.wishlist .product-item {
        padding: 20px 0 20px 0;
        position: relative;
    }
    .products-grid.wishlist .product-item-photo {
        margin-right: 20px;
    }

    .products-grid.wishlist {
        display: block;
        float: left;
    }
    .products-grid.wishlist .product-item-actions .action {
        margin-right: 15px;
    }
    .products-grid.wishlist .product-item-actions .action:last-child {
        margin-right: 0;
    }
    .products-grid.wishlist .product-item-actions .action.edit {
        float: left;
    }
    .products-grid.wishlist .product-item-actions .action.delete {
        float: right;
    }
    .products-grid.wishlist .product-item-actions .action.edit,
    .products-grid.wishlist .product-item-actions .action.delete {
        margin-top: 7px;
    }

    .products-grid.wishlist .product-item .box-tocart {
        float: left;
        margin-right: 20px;
    }
    .products-grid.wishlist .product-item .box-tocart .stock {
        margin-top: 7px;
    }
    .products-grid.wishlist .product-item .giftregisty-dropdown,
    .products-grid.wishlist .product-item .field.qty {
        display: none;
    }

    .sidebar .block-viewed-products-grid .products-grid .product-item {
        width: calc((100% - 2%) / 2);
    }
    .sidebar .block-viewed-products-grid .products-grid .product-item:nth-child(2n + 1) {
        margin-left: 0;
    }
    .sidebar .block-viewed-products-grid .products-grid .product-item-photo {
        display: block;
        position: relative;
    }
    .sidebar .block-viewed-products-grid .products-grid .product-item-details {
        margin: 0;
    }
    .sidebar .block-viewed-products-grid .products-grid .product-item-info {
        text-align: center;
    }
}
@media only screen and (max-width: 639px) {
    .abs-visually-hidden-mobile {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    .abs-add-clearfix-mobile:before,
    .abs-add-clearfix-mobile:after,
    .abs-checkout-order-review tbody tr:before,
    .abs-checkout-order-review tbody tr:after,
    .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:before,
    .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:after,
    .account .toolbar:before,
    .account .toolbar:after {
        content: '';
        display: table;
    }
    .abs-add-clearfix-mobile:after,
    .abs-checkout-order-review tbody tr:after,
    .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:after,
    .account .toolbar:after {
        clear: both;
    }
    .abs-checkout-order-review tbody tr .product-item-name {
        margin: 0;
    }
    .abs-no-display-s,
    .table-wrapper
        .table:not(.cart):not(.totals):not(.table-comparison).additional-attributes
        tbody
        th {
        display: none;
    }
    .abs-pager-toolbar-mobile-s .toolbar-amount,
    .abs-pager-toolbar-mobile-s .limiter,
    .abs-pager-toolbar-mobile-s .pages {
        margin-bottom: 25px;
    }
    .table-wrapper {
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;
        position: relative;
    }
    .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) {
        border: none;
        display: block;
    }
    .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > thead > tr > th {
        display: none;
    }
    .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody {
        display: block;
    }
    .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr {
        display: block;
    }
    .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr td,
    .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr th {
        border-bottom: none;
        display: block;
        padding: 5px 0;
    }
    .table-wrapper
        .table:not(.cart):not(.totals):not(.table-comparison)
        > tbody
        > tr
        td[data-th]:before,
    .table-wrapper
        .table:not(.cart):not(.totals):not(.table-comparison)
        > tbody
        > tr
        th[data-th]:before {
        padding-right: 10px;
        content: attr(data-th) ': ';
        display: inline-block;
        font-weight: 700;
    }
    .table-wrapper
        .table:not(.cart):not(.totals):not(.table-comparison)
        tbody
        > tr
        > td:first-child {
        padding-top: 20px;
    }
    .table-wrapper
        .table:not(.cart):not(.totals):not(.table-comparison)
        tbody
        > tr
        > td:last-child {
        padding-bottom: 20px;
    }
    .table-wrapper
        .table:not(.cart):not(.totals):not(.table-comparison).additional-attributes
        tbody
        td:last-child {
        border: none;
        padding: 0 0 5px;
    }
    .table-wrapper .table:not(.totals):not(.table-comparison) tfoot {
        display: block;
    }
    .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr {
        display: block;
    }
    .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:first-child th,
    .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:first-child td {
        padding-top: 20px;
    }
    .table-wrapper .table:not(.totals):not(.table-comparison) tfoot th {
        box-sizing: border-box;
        float: left;
        padding-left: 0;
        padding-right: 0;
        text-align: left;
        width: 70%;
    }
    .table-wrapper .table:not(.totals):not(.table-comparison) tfoot td {
        box-sizing: border-box;
        float: left;
        padding-left: 0;
        padding-right: 0;
        text-align: right;
        width: 30%;
    }
    .data-table-definition-list thead {
        display: none;
    }
    .data-table-definition-list tbody th {
        padding-bottom: 0;
    }
    .data-table-definition-list tbody th,
    .data-table-definition-list tbody td {
        display: block;
        padding-left: 0;
        padding-right: 0;
    }
    /* Overwriting Magento's padding for the method title to make
       sure our KP methods have the same height as the core methods */
    .klarna-payments-method > .payment-method-title {
        padding: 6.5px 0 6.5px 15px;
    }
    .products-list .product-item {
        table-layout: fixed;
    }
    .products-list .product-item-photo {
        padding: 0 10px 10px 0;
        width: 30%;
    }
    .minicart-wrapper {
        margin-top: 10px;
    }
    .minicart-wrapper:before,
    .minicart-wrapper:after {
        content: '';
        display: table;
    }
    .minicart-wrapper:after {
        clear: both;
    }
    .minicart-wrapper .product .actions {
        float: left;
        margin: 10px 0 0 0;
    }
    .opc-wrapper .step-title {
        font-size: 18px;
        border-bottom: 0;
        padding-bottom: 0;
    }
    .opc-wrapper .form-login {
        border-bottom: 1px solid $light-dark;
        margin: 0 0 15px;
        padding: 0 0 15px;
    }
    .opc-wrapper .shipping-address-item {
        border-bottom: 1px solid $light-dark;
        margin: 0 0 15px;
        padding: 0 0 15px;
        width: 100%;
    }
    .opc-wrapper .shipping-address-item.selected-item {
        padding: 15px 35px 15px 18px;
        border-bottom-width: 2px;
    }
    .opc-wrapper .shipping-address-item.selected-item .edit-address-link {
        right: 39px;
    }
    .opc-wrapper .form-login,
    .opc-wrapper .form-shipping-address {
        margin-top: 28px;
    }
    .opc-wrapper .action-select-shipping-item {
        float: none;
        margin-top: 10px;
        width: 100%;
    }
    .opc-wrapper .action-show-popup {
        width: 100%;
    }
    .opc-wrapper .edit-address-link {
        display: inline-block;
        text-decoration: none;
        margin: 0;
        position: absolute;
        right: 0;
        top: 1px;
    }
    .opc-wrapper .edit-address-link > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    .opc-wrapper .edit-address-link:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 28px;
        line-height: 28px;
        color: #303030;
        content: '\e606';
        font-family: 'icons-blank-theme';
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center;
    }
    .opc-wrapper .edit-address-link:hover:before {
        color: #303030;
    }
    .opc-wrapper .edit-address-link:active:before {
        color: #303030;
    }
    .shipping-policy-block.field-tooltip {
        margin-bottom: 20px;
        position: relative;
        right: auto;
        top: auto;
    }
    .shipping-policy-block.field-tooltip .field-tooltip-content {
        width: 300px;
        right: auto;
    }
    .shipping-policy-block.field-tooltip .field-tooltip-content:before,
    .shipping-policy-block.field-tooltip .field-tooltip-content:after {
        right: auto;
    }
    .opc-block-shipping-information .shipping-information-title {
        font-size: 2.3rem;
    }
    .account .column.main,
    .account .sidebar-additional {
        margin: 0;
    }
    .account .order-details-items .table-order-items .product-item-name,
    .account .order-details-items .table-order-items .price-including-tax,
    .account .order-details-items .table-order-items .price-excluding-tax,
    .account .order-details-items .table-order-items .items-qty {
        display: inline-block;
    }
    .account .toolbar .pages {
        float: right;
    }
    .account .toolbar .limiter {
        clear: both;
    }
    .order-details-items thead {
        display: block;
    }
    .order-details-items thead tr,
    .order-details-items thead td {
        display: block;
    }
    .table-wrapper .table.table-order-items tfoot td.order-pager-wrapper {
        width: 100%;
    }
    .order-items.table-wrapper .col.price,
    .order-items.table-wrapper .col.qty,
    .order-items.table-wrapper .col.subtotal,
    .order-items.table-wrapper .col.msrp {
        text-align: left;
    }
    .products-grid.wishlist .product-item {
        margin: 0;
        width: 100%;
    }
}
@media only screen and (max-width: 479px) {
    .tooltip.wrapper .tooltip.content {
        min-width: 10rem;
    }
    .minicart-wrapper .block-minicart {
        width: 290px;
    }
    .modal-popup .field-tooltip .field-tooltip-content {
        width: 200px;
    }
}
@media all and (min-width: 640px) {
    .abs-blocks-2columns-s {
        width: 48.8%;
    }
    .abs-reset-left-margin-desktop-s {
        margin-left: 0;
    }
    .abs-margin-for-forms-desktop-s {
        margin-left: 25.8%;
    }
    .abs-visually-hidden-desktop-s {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    .abs-add-clearfix-desktop-s:before,
    .abs-add-clearfix-desktop-s:after {
        content: '';
        display: table;
    }
    .abs-add-clearfix-desktop-s:after {
        clear: both;
    }
    .abs-add-box-sizing-desktop-s {
        box-sizing: border-box;
    }
    .ui-dialog.popup {
        width: 420px;
    }
    .products-grid .product-item {
        margin-bottom: 30px;
    }

    .product-item-actions .actions-primary + .actions-secondary > * {
        white-space: normal;
    }

    .page-products .products-grid .product-item:nth-child(3n + 1),
    .page-layout-1column .products-grid .product-item:nth-child(3n + 1),
    .page-layout-3columns .products-grid .product-item:nth-child(3n + 1),
    .page-products.page-layout-1column .products-grid .product-item:nth-child(3n + 1),
    .page-products.page-layout-3columns .products-grid .product-item:nth-child(3n + 1) {
        margin-left: 0;
    }
    .wishlist-index-index .products-grid .product-item {
        margin-bottom: 20px;
    }

    .page-layout-1column .block.widget .products-grid .product-item:nth-child(3n + 1) {
        margin-left: 0;
    }
}

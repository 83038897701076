@import 'common/var';
@import 'common/fonts';
@import 'common/mixin';
@import 'common/fa';
@import 'common/blank';
@import 'common/transition';
@import 'common/common';
@import 'common/generic';
@import 'common/print';
@import 'common/fa-gradient';

@import 'lib/bootstrap-grid.min';
@import 'lib/swiper-bundle.min';
@import 'lib/lite-yt-embed';
@import 'lib/select2';
@import 'lib/splide.min.css';

@import 'xxs/components/breadcrumbs';
@import 'xxs/components/buttons';
@import 'xxs/components/tabs';
@import 'xxs/components/message';
@import 'xxs/components/forms';
@import 'xxs/components/coming-soon';
@import 'xxs/components/ribbons';
@import 'xxs/components/store-switcher';
@import 'xxs/components/product-item';
@import 'xxs/components/product-item-small';
@import 'xxs/components/available-product-item-small';
@import 'xxs/components/product-slider';
@import 'xxs/components/pager';
@import 'xxs/components/tags';
@import 'xxs/components/accordion';
@import 'xxs/components/prices';
@import 'xxs/components/tooltips';
// @TODO Remove swiper after transition to splide
@import 'xxs/components/swiper';
@import 'xxs/components/slider';
@import 'xxs/components/menu';
@import 'xxs/components/platforms';
@import 'xxs/components/modal';
@import 'xxs/components/exclusive-deals';
@import 'xxs/components/minicart';
@import 'xxs/components/trustpilot';
@import 'xxs/components/autosuggest';

@import 'xxs/pages/checkout';
@import 'xxs/pages/core';
@import 'xxs/pages/homepage';
@import 'xxs/pages/product-page';
@import 'xxs/pages/newsletter';
@import 'xxs/pages/footer';
@import 'xxs/pages/header';
@import 'xxs/pages/account';
@import 'xxs/pages/wishlist';
@import 'xxs/pages/listing';
@import 'xxs/pages/faq';
@import 'xxs/pages/page-cms';
@import 'xxs/pages/cart';
@import 'xxs/pages/cookies';
@import 'xxs/pages/success-page';
@import 'xxs/pages/rma';
@import 'xxs/pages/tracking';

// Country Selector

.country-selector {
    &__wrapper {
        position: relative;
        display: inline-block;
        align-self: center;
        vertical-align: baseline;

        &::before,
        &::after {
            @include font-awesome;
            content: none;
            font-weight: 900;
            width: 0;
            height: 0;
            font-size: 2px;
            margin: 14px;
            position: relative;
            top: -10px;
        }
    }

    &__list {
        display: none;
        position: absolute;
        width: 100%;
        top: 26px;
        z-index: 1;
        padding: 0 3px 3px 3px;
        background-color: $dark-1;
        border-top: 1px solid transparent;
        @include pink-purple-border-gradient-lr;

        &-head {
            font-weight: 700;
            text-transform: uppercase;
            font-size: 0.875em;
            text-align: center;
            padding-bottom: 1.8rem;
            letter-spacing: 0.15em;
        }
        &-back {
            display: none;
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            left: 0;
            cursor: pointer;
        }
    }

    &__items {
        margin: 0;
        list-style-type: none;
        max-height: 38rem;
        overflow: auto;
        background-color: $very-dark-1;
        padding: 0.85rem;
    }

    &__title {
        margin-top: 1.8rem;
        margin-bottom: 1.8rem;
        padding: 0 0.8rem;
        text-align: left;
        font-size: 0.875em;
        color: $grey;
    }

    &__modal {
        display: block;
        position: static;
        opacity: 1;
        visibility: visible;
        transform: none;
        box-shadow: none;
        padding: 0;
        border-top: 0;
        background-color: $very-dark-1;
        width: 240px;
        z-index: 10;
        left: 50%;
        border-image-slice: 1;
        @include pink-purple-border-gradient-lr;
        @include transition(visibility 0s 0.3s, opacity 0.3s ease);

        @media (hover: hover) {
            &.active {
                @include transition(opacity 0.3s ease);
                opacity: 1;
                visibility: visible;
            }
        }

        &.active {
            .country-selector__list-back {
                display: block;
            }
            &--lang {
                .country-selector__tab--lang {
                    .country-selector__tab-head {
                        & .current:after {
                            transform: rotate(180deg);
                            @include fa-linear-gradient-pink;
                        }
                    }
                }
                .country-selector__tab--country {
                    & .country-selector__list {
                        display: none;
                    }
                }
            }

            &--country {
                .country-selector__tab--country {
                    .country-selector__tab-head {
                        & .current:after {
                            transform: rotate(180deg);
                            @include fa-linear-gradient-pink;
                        }
                    }
                }
                .country-selector__tab--lang {
                    & .country-selector__list {
                        display: none;
                    }
                }
            }

            .country-selector__list {
                display: block;
            }
        }
    }

    &__current {
        position: relative;
        z-index: 6;
        display: none;
        align-items: center;
        padding: 1.45rem 0;

        @media (hover: hover) {
            &:hover {
                cursor: pointer;
            }
            &.active {
                box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);

                & + .country-selector__modal {
                    @include transition(opacity 0.3s ease);
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        img {
            position: relative;
        }

        & > span {
            text-transform: uppercase;
            font-size: 1rem;
            line-height: 1;
            letter-spacing: 0.2em;
        }
    }

    &__tab {
        margin: 0;
        padding: 0;
        line-height: 1.2;
        text-align: left;
        background-color: $very-dark-1;
        display: flex;

        &:nth-child(2) {
            padding: 1.7rem 0 1.4rem;
        }

        &-head {
            display: flex;
            align-items: center;
            width: fit-content;
            position: relative;

            .label {
                font-size: 1rem;
                color: $grey;
                display: flex;
                align-items: center;
                letter-spacing: 0.36px;
                transition: all 0.3s ease;
                cursor: pointer;
                margin-top: 2px;
            }
            .current {
                color: $white;
                font-size: 1.2rem;
                letter-spacing: 0.28px;
                display: flex;
                align-items: center;
                transition: all 0.3s ease;
                cursor: pointer;

                &:after {
                    @include font-awesome;
                    @include transition(transform 0.3s ease);
                    @include fa-linear-gradient-blue-turquoise;
                    content: '\f078';
                    margin-left: 11px;
                    transition: all 0.3s ease;
                }
                & > span {
                    margin: 2px 4px 0;
                }
                & > img {
                    margin-right: 4px;
                }
            }
        }

        &--country,
        &--lang {
            position: relative;
            @media (hover: hover) {
                &:hover {
                    .label {
                        color: $grey-light;
                    }

                    .current {
                        &:after {
                            @include turquoise-hover-shadow;
                        }

                        span {
                            @include turquoise-hover-shadow;
                        }
                    }
                }
            }
        }

        &--lang {
            .country-selector__list {
                .country-selector__items {
                    .country-selector__item {
                        &:not(:last-child) {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }

    &__item {
        margin-bottom: 0;

        &.current-item {
            span {
                color: $cyan-turquoise;
            }
        }

        a,
        button {
            width: 100%;
            display: flex;
            align-items: center;
            text-decoration: none;
            font-size: 1rem;
            font-weight: 400;
            background-color: $very-dark-1;
            color: $light;
            cursor: pointer;
            padding: 0;
            border: 0 none;
            height: 40px;
            line-height: 40px;
            letter-spacing: 0.3px;

            & > * {
                pointer-events: none;
            }
        }

        button {
        }

        img {
            margin-right: 0.5rem;
        }
    }
}

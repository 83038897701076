.available-product-item-small {
    position: relative;
    @include background-color;
    border: 2px solid $dark-1;
    width: 70%;

    @media (max-width: 1024px) {
        border: 2px solid $dark-1;
    }

    .product-item-info {
        @include availablenow-product-item-small;
    }

    .product-page-button {
        position: relative;
        display: none;
    }

    .product-link-btn {
        display: none;
    }

    .availablenow-btn {
        display: none;
    }

    .product-more-information {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        vertical-align: baseline;
        display: none;

        .button-more-information {
            background: transparent;
            border: none;
            font-size: 16px;
        }
    }

    //Hover Action buttons

    &.hover-actions {
        .product-item-info .product-item-inner,
        .product-item-info .wishlist-button {
            display: none;
        }

        .product-item-info {
            .product.details {
                .price-cart-button {
                    .product-item-inner {
                        .product-item-actions {
                            .actions-primary {
                                .stock.unavailable.product-link-btn {
                                    display: none;
                                }

                                .stock.unavailable.disabled-btn {
                                    display: block;

                                    & button {
                                        background: $grey-dark;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .product-item-info .product-item-inner-small,
        .product-item-info .wishlist-button,
        .product-page-button {
            display: none;
            position: absolute;
            z-index: 100;
            margin-top: 14px;
        }

        .product.details
            .price-cart-button
            .product-item-inner
            .product-item-actions
            .actions-primary
            button,
        .wishlist-button a.towishlist,
        .product-page-button {
            height: 20px;
            width: 20px;
            @media (hover: hover) {
                &:hover {
                    background: $very-dark-2;
                }
            }
        }

        .product-item-info .product.details,
        .product-item-info .product.details .price-cart-button {
            position: initial;
        }

        .product.details .price-cart-button .product-item-inner {
            left: 0;

            button {
                background: $pink;

                & > span {
                    display: none;
                }
            }

            button::before {
                content: '\f290';
                @include font-awesome;
                @include absolute-centring;
                font-size: 1rem;
            }
        }
        .links-buttons {
            position: absolute;
        }
        .wishlist-button {
            left: 20px;
            top: 45px;

            a.towishlist {
                @include purple-pink-gradient;
            }

            a.towishlist > i:before {
                fill: $white;
                margin: 0;
                @include absolute-centring;
            }
        }

        .product-page-button {
            top: calc(85px - 40px);
            @include cyan-blue-gradient-317deg;

            a.product-link {
                @include absolute-centring;
                color: $white;
                font-size: 1rem;
            }
        }
    }
}

.breadcrumbs {
    display: block;

    .items {
        white-space: nowrap;
        overflow: hidden;

        // Don't work, it replaces all last li with "..."
        // Todo, find a way to cut last li and add "..." at the end of its content
        // text-overflow: ellipsis;
    }

    .item {
        font-size: 1rem;
        a {
            font-size: $small-font-size;
            color: $grey-light;
            transition: color ease-in-out 0.3s;

            &:hover,
            &:hover:before {
                color: $light;
                text-shadow: none;
            }
        }

        &:not(:last-child):after {
            content: '/';
            color: $grey;
            margin: -2px 4px 0 2px;
            font-size: 1.1rem;
        }

        &:last-child,
        strong {
            color: $grey;
            line-height: 1rem;
            font-weight: 300;
            font-size: $small-font-size;
        }
    }
}

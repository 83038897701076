html,
body {
    font-family: $font-light;
    font-size: 12px;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-size-adjust: initial;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $grey;
    background-color: $very-dark-1;
    font-variant-ligatures: none;

    main.page-main {
        margin: 198px auto 0;
    }
}

// Container size
@include container-sizing($container-xs);

.sales-guest-form,
.sales-guest-view {
    .breadcrumbs {
        margin: 0 20px;
    }

    .page-title-wrapper {
        margin: 1rem 20px 0.5rem 20px;
        .page-title {
            margin-bottom: 5px;
        }
    }
}
.sales-guest-form {
    .form-orders-search {
        .control {
            margin-top: 8px;
        }
        .actions-toolbar {
            margin-top: 20px;
            .action.submit.primary {
                font-size: 16px;
                line-height: 16px;
                padding: 12px 0;
            }
        }
    }
    .columns {
        margin: 0 20px;
    }
}

.sales-guest-view {

    font-size: $normal-font-size;

    .columns {
        margin: 0 20px;
    }

    .price-including-tax {
        font-size: unset;
        line-height: 1.5rem;
    }

    address {
        line-height: 1.5rem;
    }

    .block-title {
        margin: 20px 0 8px;
    }

    .block-content {
        .box {
            margin-bottom: 15px;
        }

        .box-content a {
            font-size: $normal-font-size;
        }
    }

    .actions-toolbar,
    .field.control.checkbox {
        margin-top: 10px;
    }

    .logo {
        height: fit-content;
        margin: 0;
        display: flex;
        align-items: center;
        align-self: center;
        padding: 0 0 1rem 0;
        @media (hover: hover) {
            &:hover {
                .logo-o-portal {
                    transform: rotateZ(180deg);
                }
            }
        }
        .logo-game {
            width: 180px;
            content: url('../images/Logo-Full.svg');
        }
        .logo-o-portal {
            display: none;
            width: 25.6px;
            height: auto;
            @include transition(transform 0.4s ease);
        }
    }

    .amgdpr-settings-container {
        .amgdpr-checkbox-container {
            margin-top: 20px;
        }
    }
}

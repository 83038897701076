.slider-products {
    margin: 0 0 0 8px;

    .splide {
        &__list {
            margin: 12px 0;
        }
    }

    &-header {
        position: relative;
        .title {
            margin-bottom: 15px;
            display: inline-block;
            h2 {
                font-size: 1.3rem;
                line-height: 1.3rem;
                letter-spacing: 0.48px;
                color: $grey;
                opacity: 0.8;
            }
        }
        .see-more {
            position: absolute;
            top: 0;
            right: 20px;
            @media (hover: hover) {
                &:hover {
                    a {
                        @include white-hover-shadow;
                        i,
                        svg {
                            @include cyan-hover-shadow;
                        }
                    }
                }
            }
            a {
                font-size: 1rem;
                line-height: 1rem;
                letter-spacing: 0.36px;
                color: $light;
                transition: filter 0.3s ease;
                i,
                svg {
                    vertical-align: top;
                    transition: none;
                    font-size: 0.9rem;
                    line-height: 1rem;
                    position: relative;
                    top: 2px;
                    margin-left: 3px;
                    &:before {
                        display: inline;
                    }
                }
            }
        }
    }

    .slider-filters {
        position: absolute;
        top: -5px;
        padding: 5px 25px 2px 15px;
        right: calc(50% - 25px);
        text-align: right;
        .slider-filter {
            font-size: 1rem;
            line-height: 1.3rem;
            letter-spacing: 0.48px;
            color: $grey;
            opacity: 0;
            pointer-events: none;
            margin-bottom: 3px;
            @include transition(opacity 100ms linear);
            &.slider-filter-header {
                opacity: 0.8;
                pointer-events: auto;
                cursor: pointer;
                &:after {
                    content: '\f078';
                    @include font-awesome;
                    color: $turquoise;
                    font-size: 1rem;
                    line-height: 1.3rem;
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    @include transition(transform 100ms linear);
                }
            }
            a {
                font-size: 1rem;
                line-height: 1.3rem;
                letter-spacing: 0.48px;
                color: $grey;
            }
            i {
                font-size: 0.9rem;
                padding-right: 2px;
            }
        }
        &.active {
            z-index: 10;
            @include background-color;
            border: 3px solid $dark-1;
            top: -8px;
            right: calc(50% - 25px);
            .slider-filter {
                opacity: 0.8;
                pointer-events: auto;
                &.slider-filter-header {
                    display: none;
                }
            }
            &:after {
                color: $pink;
                transform: rotate(180deg);
                content: '\f078';
                @include font-awesome;
                font-size: 1rem;
                line-height: 1.3rem;
                position: absolute;
                top: 5px;
                right: 5px;
                @include transition(transform 100ms linear);
            }
        }
    }
}

.menu-xbox,
.menu-playstation,
.menu-nintendo,
.menu-vr,
.menu-creator,
.menu-merchandise,
.menu-pc-mac,
.menu-others,
.menu-deals {
    &:before {
        @include font-awesome;
        margin-right: 0.5rem;
        font-size: 1em;
        transition: all 0.3s ease;
        display: inline-block;
    }
}

a.menu-xbox {
    &:before {
        content: '\f412';
    }
}
a.menu-merchandise {
    &:before {
        content: '\f553';
    }
}
a.menu-playstation {
    &:before {
        content: '\f3df';
    }
}
a.menu-nintendo {
    &:before {
        content: '\e000';
    }
}
a.menu-vr {
    &:before {
        content: '\f6ea';
    }
}
a.menu-creator {
    &:before {
        content: '\f3c9';
    }
}
a.menu-pc-mac {
    &:before {
        content: '\f8cd';
        transform: rotateZ(45deg);
    }
}
a.menu-others {
    &:before {
        content: '\f067';
    }
}
a.menu-deals {
    color: $pink;
    font-weight: bold;
    &:before {
        content: '\f06d';
        @include fa-linear-gradient-pink;
    }
}

.product-items {
    display: flex;
    flex-wrap: wrap;
}
.product-item {
    height: min-content;
    //margin-bottom: 2rem;
    width: 100%;
    position: relative;
    .product-item-info {
        @include border-block;
        width: 100%;
        padding-top: 0;
        .product-item-photo {
            width: 100%;
            display: block;
            max-height: 155px;
            min-height: 155px;
            height: 155px;
            .image-ribbon {
                margin-left: 2px;
                margin-top: 2px;
            }
            .product-image-container {
                display: inline-block;
                width: 100% !important;
                height: 100%;
                .product-image-wrapper {
                    padding-bottom: 0% !important;
                    height: 100%;
                    img {
                        width: 100%;
                        aspect-ratio: 616/353;
                        object-fit: cover;
                        max-height: 155px;
                        height: 100%;
                    }

                    &.packshot-white-background {
                        @include white-background-img;
                    }
                    &.packshot-dark-background {
                        @include dark-background-img;
                    }
                }
            }
        }
        .product-item-name {
            overflow: hidden;
            height: 42px;
            line-height: 42px;
            overflow: hidden;
            display: block;

            @media (min-width: 1440px) {
                margin-top: 0;
            }
            a {
                font-size: 1.3rem;
                line-height: 1.8rem;
                text-align: left;
                letter-spacing: 0.7px;
                color: $light !important;
                text-decoration: none;
            }
        }

        .price-box {
            text-align: end;
            height: 36px;
            margin: 10px 0px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .promo-percent {
                padding: 0px 9px;
                height: 36px;
                display: flex;
                align-items: center;
            }
        }

        .product-item-inner {
            .product-item-actions {
                display: flex;
                justify-content: space-between;
                .actions-primary {
                    width: 100%;
                    max-width: calc(100% - 2.5rem);

                    .product-link-btn {
                        & > * {
                            width: 100%;
                        }
                    }

                    button {
                        min-height: 40px;
                        width: 190px;
                        float: right;
                        padding: 0.5rem 1.5rem;
                    }
                    .stock.unavailable {
                        font-size: 1.1rem;
                        height: 100%;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                    }
                }
            }
        }
    }
    .product-item-details {
        padding: 11px;

        .action.tocompare {
            display: none;
        }
    }
    /*a */
    // img.product-image-zoomed,
    .product-image-zoomed-wrapper,
    .image-zoomed-overlay {
        display: none;
    }
}

button.action.primary,
a.action.primary,
.action-primary {
    @include pink-purple-gradient;
    cursor: pointer;
    border: 0;
    box-shadow: 0px 0px 12px $black;
    font-family: $font-light;
    font-size: 0.95rem;
    @media (max-width: 992px) {
        font-size: 1.1rem;
    }
    line-height: 0.95rem;
    padding: 0.75rem 1.5rem;
    max-width: 100%;
    letter-spacing: 0.4px;
    color: $light;
    opacity: 1;
    text-align: center;
    @include transition(all 100ms linear);
    @media (hover: hover) {
        &:hover,
        &:active,
        &:focus {
            text-shadow: 0px 0px 8px $light;
        }
    }
    &[disabled],
    &[disabled='true'],
    &.disabled {
        cursor: default;
        pointer-events: none;
        background: transparent;
        color: $grey-dark;
        box-shadow: initial;
        text-shadow: initial;
        border: 0.5px solid $grey-dark;
    }

    &:active {
        @include active-button;
    }
}

button.action.secondary,
a.action.secondary,
.action-secondary {
    @include cyan-gradient;
    cursor: pointer;
    border: 0;
    box-shadow: 0px 0px 12px $black;
    font-family: $font-light;
    font-size: 0.95rem;
    line-height: 0.95rem;
    padding: 0.75rem 2.5rem;
    max-width: 100%;
    letter-spacing: 0.4px;
    color: $light;
    opacity: 1;
    text-align: center;
    transition: all 100ms linear;
    @media (hover: hover) {
        &:hover,
        &:active,
        &:focus {
            text-shadow: 0px 0px 8px $light;
        }
    }
    &[disabled],
    &[disabled='true'],
    &.disabled {
        cursor: default;
        pointer-events: none;
        background: $very-dark-2;
        color: $grey;
    }
    a {
        text-decoration: none;
        span {
            color: $light;
        }
    }
}

.btn-dark {
    background: $btn-dark 0% 0% no-repeat padding-box;
    box-shadow: 0 0 5px #80000000;
    border-radius: 5px;
    border: none;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    color: $light;
    cursor: pointer;
    span {
        color: $light;
    }

    @media (hover: hover) {
        &:active {
            background-color: $very-dark-2;

            span {
                color: $grey-medium;
            }
        }
    }
    &[disabled],
    &[disabled='true'],
    &.disabled,
    &:disabled {
        pointer-events: none;
        background: initial;
        color: $grey;
        border: 0.5px solid #646465;
        span {
            color: $grey-dark;
        }
    }
}

@mixin shadow-button-link {
    background-color: $very-dark-1;
    box-shadow: 1px 1px 8px $black, -1px -1px 8px $grey-dark;

    text-align: center;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    svg,
    i:before {
        font-size: 1.5rem;
        margin: 0.95rem 0;
        @include transition(all 100ms linear);
        filter: drop-shadow(0px 0px 0px transparent);
    }
    &.pink {
        svg,
        i:before {
            color: $pink;
        }
        @media (hover: hover) {
            &:hover {
                svg,
                i:before {
                    color: $pink-light;
                    filter: drop-shadow(0px 0px 8px $pink-light);
                }
            }
        }
    }
    &.cyan {
        svg,
        i:before {
            color: $cyan;
        }
        @media (hover: hover) {
            &:hover {
                svg,
                i:before {
                    color: $turquoise;
                    filter: drop-shadow(0px 0px 8px $turquoise);
                }
            }
        }
    }
    &.light {
        svg,
        i:before {
            color: $light;
        }
    }

    &:active,
    &.active {
        svg,
        i:before {
            color: $grey;
        }
    }
}

.btn-shadow {
    @include shadow-button-link;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    box-shadow: 1px 1px 15px #000, -1px -1px 6px rgba(248, 247, 242, 0.21);
    border: 1px solid #232323;
    border-radius: 6px;
    transition: all 0.3s ease-in-out;

    &.pink,
    &.light,
    &.cyan {
        &:active,
        &:active .pink, // If there is sub element inside
        &:active .light,
        &:active .cyan {
            @include active-button;
            svg,
            i:before {
                background-image: none;
                background-color: $grey;
            }
        }
    }

    &.pink:hover {
        text-shadow: 0 0 8px $pink;
    }

    &.light:hover {
        text-shadow: 0 0 8px $light;
    }
    &.cyan:hover {
        text-shadow: 0 0 8px $green-dark;
    }

    &:hover:active {
        text-shadow: none;
        i:before {
            filter: none;
        }
    }
}

.btn-round {
    @include shadow-button-link;
    width: 3.4rem;
    height: 3.4rem;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    &.big {
        width: 4rem;
        height: 4rem;
        & > i:before {
            font-size: 1.85rem;
            margin: 1.1rem 0;
        }
    }
    &.cyan,
    &.light,
    &.pink {
        &:hover:active {
            text-shadow: none;

            i:before {
                filter: none;
            }
        }
    }
}

.btn-sqr {
    text-align: center;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    width: 30px;
    height: 30px;
    transition: background 0s;
    padding: 0;
    box-shadow: unset;

    svg,
    i:before {
        font-size: 1rem;
        margin: 0.65rem 0;
        @include transition(all 100ms linear);
        filter: drop-shadow(0px 0px 0px transparent);
    }

    &.light {
        svg,
        i:before {
            color: $light;
        }
    }

    @media (hover: hover) {
        &:hover {
            background: $very-dark-2;
        }
    }
}

.btn-arrow {
    &.cyan {
        svg,
        i:before {
            color: $cyan;
            filter: drop-shadow(0px 0px 0px transparent);
        }
        @media (hover: hover) {
            &:hover {
                svg,
                i:before {
                    color: $turquoise;
                    filter: drop-shadow(0px 0px 8px $turquoise);
                }
            }
        }
    }
}

button.action.primary,
a.action.primary,
.action-primary,
button.action.secondary,
a.action.secondary,
.action-secondary,
.btn-shadow {
    &.important {
        height: 48px;
        font-size: 1.6rem;
        i:before {
            font-size: 1.8rem;
        }
    }
}

.tags_list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .tag {
        padding: 5px 10px;
        width: fit-content;
        color: $grey-light;
        flex-grow: 2;
        text-align: center;

        &.gaming_experience {
            background-color: #8228fe4d;
        }
        &.game_type {
            background-color: #00f1ff33;
        }
        &.player_type {
            background-color: #ff009c33;
        }
    }
}

.availablenow-items {
    .tags_list {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        margin-top: 20px;
        .tag {
            padding: 5px 10px;
            width: fit-content;
            color: $grey-light;
            flex-grow: 2;
            text-align: center;
            font-size: 10px;
            &.gaming_experience {
                background-color: #8228fe4d;
            }
            &.game_type {
                background-color: #00f1ff33;
            }
            &.player_type {
                background-color: #ff009c33;
            }
        }
    }
}

.tab-section__description .tags_list .tag {
    flex-grow: initial;
    text-align: initial;
}

.video-thumbnail {
    object-fit: cover;
}

.swiper-progress {
    display: block;
    position: relative;
    width: 100%;
    height: 3px;
    border-radius: 1.5px;
    @include pink-blue-green-purple-gradient();

    .swiper-progress-bar {
        position: absolute;
        right: 0;
        top: 0;
        width: 0;
        height: 3px;
        z-index: 5;
        background-color: $dark-2;
        border-radius: 0 1.5px 1.5px;
        @include transition(width 100ms linear);
    }

    .swiper-progress-bar-revert {
        @include transition(width 100ms linear);
        display: block;
        flex: 1;
        position: absolute;
        height: 3px;
        width: 0;
        border-radius: 1.5px 0 0 1.5px;
        background: inherit;
        background-position: 0;
        &.active {
            &:after {
                opacity: 1;
                @include transition(opacity 500ms linear);
            }
        }
        &:after {
            @include transition(opacity 0ms);
            content: '';
            position: absolute;
            bottom: -25%;
            height: 140%;
            left: 0;
            width: 100%;
            opacity: 0;
            border-radius: 0;
            background: inherit;
            filter: blur(5px);
        }
    }
}
.slide-thumbs {
    display: block;
    transition: opacity 0.2s ease;
    width: 100%;
    height: auto;
    max-height: 100px;
    overflow: hidden;
    opacity: 0.3;
    cursor: pointer;
    img {
        width: 100%;
        height: auto;
        display: block;
    }
    &.swiper-slide-thumb-active {
        opacity: 1;
    }
}
.swiper-bullets {
    margin: 1rem 0 2rem 0;
    min-width: calc(100% - 20px);
    height: 10px;
    overflow: hidden;
    position: relative;
    text-align: center;
    .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $light-dark;
        opacity: 0;
        margin: 0 0.3rem;
        @include transition(opacity 100ms linear, background 100ms linear);
        left: 0 !important;
        position: absolute;
        &.swiper-pagination-bullet-active {
            background: $light;
        }
        &.swiper-pagination-bullet-active-prev-prev,
        &.swiper-pagination-bullet-active-prev,
        &.swiper-pagination-bullet-active-main,
        &.swiper-pagination-bullet-active-next,
        &.swiper-pagination-bullet-active-next-next {
            opacity: 1;
            position: relative;
        }
    }
}
.swiper-preloader {
    width: 50px;
    height: 50px;
    @include absolute-centring;
    img {
        width: 100%;
        height: 100%;
    }
}

.swiper-products-preloader {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 100;
    background-color: $very-dark-1;

    & > img {
        @include absolute-centring;
        width: 50px;
        height: 50px;
    }
}

.swiper-button-disabled {
    pointer-events: none;
    opacity: 0;
}
.swiper-products-button-prev,
.swiper-products-button-next {
    @include transition(opacity 100ms linear);
}

.image-ribbon {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    border-bottom: 2px solid $dark-1;
    border-right: 2px solid $dark-1;
    overflow: hidden;
    span,
    strong {
        font-size: 1.1rem;
        line-height: 1.1rem;
        letter-spacing: 0.28px;
        color: $light;
    }
    strong::before {
        content: '> ';
        font-weight: 400;
    }
    .ribbon-inner {
        @include green-blue-gradient();
        display: inline-block;
        padding: 6px 12px;
        position: relative;
    }
    &.is-dlc {
        .ribbon-inner {
            background: none;
            &:before {
                content: '';
                @include green-blue-gradient();
                transform: skew(40deg);
                position: absolute;
                top: 0;
                left: -12px;
                width: calc(100% + 12px);
                height: 100%;
                z-index: 1;
            }
            span,
            strong {
                position: relative;
                z-index: 1;
            }
        }
    }

    &.out-of-stock .ribbon-inner {
        @include transparent-pink-purple-gradient();
    }

    .ribbon-dlc {
        display: inline-block;
        padding: 2px 0.5rem 2px 1rem;
        position: relative;
        float: right;
        &:before {
            content: '';
            @include green-blue-gradient();
            transform: skew(40deg);
            position: absolute;
            top: 0;
            left: -2px;
            width: calc(100% + 14px);
            height: 100%;
            z-index: 1;
            border-left: 2px solid $dark-1;
        }
        span {
            position: relative;
            z-index: 1;
        }
    }
}
.product-item-small {
    .image-ribbon {
        strong {
            display: none;
        }
        .ribbon-dlc {
            padding: 2px 0.5rem 2px 0.7rem;
        }
    }
}

.page-footer {
    .footer-part {
        background: transparent linear-gradient(0deg, $very-dark-1 0, $very-dark-1 0) 0 0 no-repeat padding-box;
        box-shadow: 0px 0px 12px $black;
        border: 2px solid $dark-1;
        opacity: 1;
        position: relative;
        padding-top: 120px;
        .footer-first-part {
            .footer-nav-button {
                a {
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin-top: 2rem;
                    margin-right: 2rem;
                }
            }

            .footer-options-links,
            .main-menu-footer,
            .second-menu-footer,
            .reassurance {
                padding: 0;
                margin: 0 auto;
                color: $light;

                & > ul > li,
                & > li {
                    text-align: left;
                    list-style: none;
                }
            }

            .footer-options-links {
                display: flex;
                flex-wrap: wrap;

                .footer-option {
                    display: flex;
                    width: 50%;
                    padding: 10px 0;
                    letter-spacing: 0.36px;

                    &.hide-mobile {
                        display: none
                    }

                    a {
                        display: flex;
                        align-items: center;
                        color: $light;
                        font-size: 18px;
                        line-height: 18px;
                        transition: all 0.5s ease-out;
                        .fa-shopping-bag,
                        .fa-box-open,
                        .fa-user,
                        .fa-heart {
                            transition: filter 0.5s ease;
                        }
                        @media (hover: hover) {
                            &:hover {
                                text-decoration: none;
                                @include cyan-shadow;

                                & .fa-shopping-bag,
                                & .fa-box-open,
                                & .fa-user {
                                    @include cyan-turquoise-hover-shadow;
                                }

                                & .fa-heart {
                                    @include pink-hover-shadow;
                                }
                            }
                        }

                        .footer-option-icon {
                            display: block;
                            width: 18px;
                            height: 18px;
                            text-align: center;
                            font-size: 18px;
                            line-height: 18px;
                            &.fa-box-full:before {
                                margin-left: -3px;
                            }
                        }

                        span {
                            display: block;
                            margin-left: 8px;
                        }
                    }
                }
            }

            .main-menu-footer {
                display: flex;
                flex-wrap: wrap;
                padding-top: 60px;

                .level0 {
                    width: 50%;
                    padding: 8px 0;
                    opacity: 1;
                    text-align: left;
                    text-transform: uppercase;
                    font-size: 14px;
                    letter-spacing: 0.28px;

                    &:before {
                        width: 16px;
                        margin-right: 8px;
                        text-align: center;
                    }

                    a.menu-item-active {
                        span {
                            text-decoration: none;
                        }
                    }
                }
            }

            .second-menu-footer {
                display: flex;
                flex-wrap: wrap;
                padding-top: 84px;
                white-space: initial;

                .level0 {
                    flex: 1 1 auto;
                    padding: 4px 20px 4px 0;
                    opacity: 1;
                    color: $light;
                    font-size: 14px;
                    letter-spacing: 0.28px;
                    width: 50%;

                    @media(min-width: 768px) {
                        width: initial;
                    }

                    &:last-child {
                        padding-right: 0;
                    }
                    @media (hover: hover) {
                        a:hover {
                            text-decoration: none;
                            @include cyan-shadow;
                            transition: all 0.5s ease-out;
                        }
                    }
                    a.level0 {
                        padding: 0;
                    }
                    a.menu-item-active {
                        span {
                            text-decoration: none;
                        }
                    }
                }
            }

            .reassurance .second-menu-footer {
                display: flex;
                flex-wrap: wrap;
                padding-top: 22px;
                font-size: 14px;

                .level0 {
                    padding-top: 8px;
                    padding-bottom: 8px;
                }

                .footer_second_menu_terms {
                    .footer_second_menu_terms_label {
                        position: absolute;
                        color: $grey;
                        font-size: 12px;
                        line-height: 12px;
                        white-space: nowrap;
                    }
                    a {
                        display: block;
                        margin-top: 16px;
                        font-size: 14px;
                        line-height: 16px;
                    }
                }
            }
        }

        .footer-separation {
            border-color: $light-dark;
            margin-top: 30px;
            @media (min-width: 768px) {
                display: block;
            }
        }

        .footer-second-part {
            .trustpilot-logo {
                margin: 32px auto 0;
            }

            .footer-reassurance {
                display: flex;
                width: 90%;
                padding: 0;
                margin: 30px auto 0;

                .reassurance {
                    width: 33.33%;
                    text-align: center;

                    p {
                        margin-top: 12px;
                        padding: 0 8px;
                        font-size: 12px;
                        line-height: 16px;
                    }

                    i:before {
                        font-size: 30px;
                    }
                }

                .reassurance-licence {
                    line-height: 16px;
                }
            }

            .trustpilot-logo .trustpilot-widget {
                height: 47px;
            }

            .footer-payments-type {
                display: flex;
                width: 90%;
                margin-right: auto;
                margin-left: auto;
                margin-top: 20px;

                .payment {
                    text-align: center;
                    width: 33.3%;

                    i:before {
                        font-size: 35px;
                        margin: auto;
                    }
                }
            }

            .footer-copyright {
                display: flex;
                flex-direction: column;
                text-align: center;
                letter-spacing: 0.36px;
                line-height: 16px;
                opacity: 1;
                color: $grey;
                padding: 0;
                margin-top: 36px;
                background: $very-dark-1 0% 0% no-repeat padding-box;
                box-shadow: 0px 0px 12px $black inset;
                padding-bottom: 24px;
                border-top: 2px solid $dark-1;

                .logo {
                    flex: 1;
                    height: fit-content;
                    display: flex;
                    align-items: center;
                    align-self: center;
                    margin: 38px 0 20px;
                    @media (hover: hover) {
                        &:hover {
                            .logo-o-portal {
                                transform: rotateZ(180deg);
                            }
                        }
                    }
                    .logo-game {
                        width: 76px;
                    }
                    .logo-o-portal {
                        width: 25.6px;
                        height: auto;
                        @include transition(transform 0.4s ease);
                    }
                }
                p {
                    @media (max-width: 767px) {
                        margin: auto;
                        width: 80%;
                    }
                }
            }
        }
    }
}

body.has-similar-product {
    .block.related {
        position: relative;
        z-index: 10;
    }
    .block.newsletter {
        padding-top: 60px;
    }
}

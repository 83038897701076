.price-box,
.price-container,
.price-div {
    .price-wording {
        display: none;
    }

    .price {
        letter-spacing: 0.4px;
        color: $light;
        font-size: 1.1rem;
        line-height: 1.1rem;
    }
    .old-price {
        display: inline-block;
        .price {
            color: $grey;
            text-decoration: line-through;
        }
    }
    .special-price {
        display: inline-block;
    }
    .old-price,
    .special-price {
        padding: 6px 3px 3px 3px;
    }
    .special-price {
        padding-right: 6px;
    }
    .weee {
        .price {
            font-size: 1.7rem;
            @media (min-width: 769px) {
                font-size: 1.1rem;
            }
            margin-left: 5px;
        }
    }
    .promo-percent {
        display: inline-block;
        position: relative;
        padding: 0.66rem 0.5rem;
        @media (min-width: 769px) {
            padding: 4px 10px 7px;
        }
        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            @include pink-purple-gradient;
            box-shadow: 0px 0px 4px $pink-dark;
        }
        &:after {
            content: '';
            position: absolute;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            top: 1px;
            left: 1px;
            background-color: $black;
        }
        .percent {
            letter-spacing: 0.4px;
            color: $light;
            font-size: 1.1rem;
            @media (max-width: 769px) {
                font-size: 1.7rem;
                line-height: 1.7rem;
            }
            line-height: 1.1rem;
            position: relative;
            z-index: 1;
        }
    }
}

.price-container,
.price-div {
    .price-including-tax {
        display: flex;
        justify-content: flex-end;
    }
    .minicart-old-price {
        display: none;
    }
    .minicart-price {
        display: flex;
        align-items: center;
        margin-right: 0.5rem;
        letter-spacing: 0.32px;
        @media (min-width: 768px) {
            font-size: 1rem;
        }
    }
    .minicart-promo-percent,
    .promo-percent {
        display: flex;
        align-items: center;
        position: relative;
        padding: 8px 10px 7px;
        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            @include pink-purple-gradient;
            box-shadow: 0px 0px 4px $pink-dark;
        }
        &:after {
            content: '';
            position: absolute;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            top: 1px;
            left: 1px;
            background-color: $black;
        }
        .percent {
            display: table-cell;
            z-index: 1;
            position: relative;
            letter-spacing: 0.32px;
            color: $light;
            font-size: 1.1rem;
            line-height: 1.1rem;
            @media (min-width: 768px) {
                font-size: 1rem;
            }
        }
    }
}

input[type='text'],
input[type='password'],
input[type='url'],
input[type='tel'],
input[type='search'],
input[type='number'],
input[type='datetime'],
input[type='email'],
textarea {
    color: $grey;
    letter-spacing: 0.36px;
    opacity: 1;
    width: 100%;
    font-size: 1rem;
    @media (min-width: 768px) {
        font-size: 0.85rem;
    }
    padding: 0.75rem 1rem;
    background-color: $very-dark-2;
    border: 1px solid $grey-dark;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        //-moz-box-shadow: 0 0 0px 1000px $very-dark-2 inset;
        //-webkit-box-shadow: 0 0 0px 1000px $very-dark-2 inset;
        //box-shadow: 0 0 0px 1000px $very-dark-2 inset, 0 0 0px 1000px $very-dark-2 inset;
        //-webkit-text-fill-color: $grey;
        //border-top: 0.5px solid $black;
        //border-left: 0.5px solid $black;
        //border-right: 0.5px solid $black;
        //border-bottom: 0.5px solid $cyan;
        //caret-color: $grey;
        &::first-line {
            font-family: $font-light;
            font-size: 0.85rem;
        }
    }

    &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #9b9b9b;
    }
    &::-moz-placeholder {
        /* Firefox 19+ */
        color: #9b9b9b;
    }
    &:-ms-input-placeholder {
        /* IE 10+ */
        color: #9b9b9b;
    }
    &:-moz-placeholder {
        /* Firefox 18- */
        color: #9b9b9b;
    }
    &[readonly] {
        background-color: rgba(0, 0, 0, 0.1);
    }
    &.mage-error,
    &:invalid {
        @include pink-purple-border-gradient-tlbr;
    }
}

select {
    background: $very-dark-2 0% 0% no-repeat padding-box;
    color: $grey;
}

.fieldset .field,
.field.choice {
    position: relative;
}

.minisearch {
    .control {
        display: flex;
        input[type='text'] {
            padding: 0.75rem 3rem;
            font-size: 1rem;
            transition: all 0.3s ease;
            @media (hover: hover) {
                &:hover {
                    & + .fa-search:before {
                        @include turquoise-hover-shadow;
                    }
                }
            }
            &:not(:placeholder-shown) {
                & + .search-cross {
                    opacity: 1;
                }
            }
        }
        & > i:before {
            @include fa-linear-gradient-turquoise-blue;
            position: absolute;
            margin-left: 1rem;
            margin-right: 1rem;
            margin-top: 0.8rem;
            font-size: 1rem;
            left: 0;
        }
        .search-cross {
            margin-left: -2rem;
            opacity: 0;
            height: 20px;
            width: 20px;
            & i:before {
                @include pink-hover-shadow;
                font-size: 1.5rem;
                margin-top: 0.6rem;
            }
        }
    }
}

.am-search-box-platforms {
    @include turquoise-cyan-border-gradient-tlbr;
}

.minisearch #search {
    @include turquoise-cyan-border-gradient-tlbr;
    @media (hover: hover) {
        &:hover {
            box-shadow: 0 0 14px $turquoise-opacity;
        }
    }
}

input[type='checkbox'],
input[type='radio'] {
    opacity: 0;
    position: absolute;
    pointer-events: none;
    &:checked + label {
        &::before {
            content: '\f14a';
            color: $cyan-turquoise;
        }
    }

    + label {
        @media (hover: hover) {
            &:hover {
                text-shadow: 0px 0px 8px $cyan-turquoise;
            }
        }
        &:before {
            @include font-awesome;
            font-size: 1.2em;
            content: '\f0c8';
        }
        span {
            margin-left: 5px;
            letter-spacing: 0.5px;
        }
    }
}

.fieldset {
    .label {
        display: block;
        font-family: $font-regular;
        font-weight: unset;
        margin-bottom: 9px;

        span {
            font-family: inherit;
            font-weight: inherit;
        }
    }
}

//Password meter

#password-strength-meter-container {
    .password-strength-meter {
        color: $light;
        background: $light-dark;
        font-weight: 600;
        margin-top: 0.8rem;
    }
    &.password-none {
        .password-strength-meter {
            color: $light;
            font-weight: 600;
            background: $light-dark;
        }
    }
    &.password-medium,
    &.password-strong,
    &.password-very-strong {
        .password-strength-meter {
            color: $light;
            font-weight: 600;
        }
    }
    &.password-weak .password-strength-meter::before {
        background: $pink-opacity;
    }
    &.password-medium .password-strength-meter::before {
        background: $pink-dark;
    }
    &.password-strong .password-strength-meter::before {
        background: $emerald-light;
    }
    &.password-very-strong .password-strength-meter::before {
        background: $emerald-opacity;
    }
}

.message.error > *:first-child:before {
    display: none;
}

// Select with Select2
.select2-container {
    .select2-selection {
        display: flex;
        background: $very-dark-1;
        border: none;
        .select2-selection__rendered {
            padding-left: 0;
            color: $grey;
            font-size: $normal-font-size;
            @include transition(color 100ms linear);
        }

        &:after {
            @include transition(color 100ms linear);
            @include font-awesome;
            display: flex;
            align-items: center;
            color: $turquoise;
            content: '\f078';
        }

        &:hover,
        &:focus {
            .select2-selection__rendered {
                color: $light;
            }

            &:after {
                filter: drop-shadow(0 0 8px $turquoise);
            }
        }
    }
    .select2-selection__arrow {
        b {
            display: none;
        }
    }
    .select2-dropdown {
        width: min-content !important;
        border-left: none;
        border-bottom: none;
        border-right: none;
        border-image-slice: 1;
        z-index: 1050;
        @include pink-purple-border-gradient-lr;
        border-top: 1px solid transparent;

        .select2 {
            &-search {
                &--dropdown {
                    min-width: 200px;
                    padding: 0;
                }

                &__field {
                    border: none;
                }
            }

            &-results {
                background: $very-dark-1;
                border: 2px solid $dark-1;
                border-top: none;
                padding: 10px 4px;
                .select2-results__options {
                    .select2-results__option {
                        font-size: $normal-font-size;
                        line-height: 24px;
                        background: $very-dark-1;
                        display: flex;
                        white-space: nowrap;
                        padding: 0;
                        &--selectable {
                            color: $light;
                        }

                        &--highlighted {
                            color: $turquoise;
                        }
                    }
                }
            }
        }
    }
    &.select2-container--open {
        .select2-selection {
            &:after {
                color: $pink;
                content: '\f077';
            }

            &:hover,
            &:focus {
                &:after {
                    filter: drop-shadow(0 0 8px $pink-light);
                }
            }
        }
    }
}
.select2-container--disabled {
    display: none;
}
.select2-results__options::-webkit-scrollbar {
    width: 6px;
    top: 2px;
}

.select2-results__options::-webkit-scrollbar-track {
    background: $very-dark-2;
}

.select2-results__options::-webkit-scrollbar-thumb {
    background-color: $grey-dark;
    border-radius: 20px; /* roundness of the scroll thumb */
}

//Listing Select
.catalog-category-view,
.catalogsearch-result-index,
.ambrand-index-index {
    .select2-container {
        .select2-dropdown {
            .select2-results {
                .select2-results__options {
                    .select2-results__option {
                        &:after {
                            @include font-awesome;
                            color: $light;
                            margin-left: 0.5rem;
                        }

                        &:nth-child(odd) {
                            &:after {
                                transform: rotate(-45deg);
                                content: '\f063';
                            }
                        }

                        &:nth-child(even) {
                            &:after {
                                transform: rotate(45deg);
                                content: '\f062';
                            }
                        }
                    }
                }
            }
        }
    }
}

// Adyen Payments IDEAL
.adyen-checkout__dropdown__button {
    background: $very-dark-1 !important;
    border: none !important;
    display: flex !important;
    color: $light !important;
    font-size: 1rem !important;
    padding-left: 0 !important;
    width: fit-content !important;
    &:after {
        @include font-awesome;
        content: '\f078' !important;
        color: $turquoise !important;
        display: flex !important;
        align-items: center !important;
        background-image: none !important;
        margin-left: 0.5rem !important;
    }
    &:focus {
        border: none !important;
        box-shadow: none !important;
    }
    &--active {
        &:after {
            color: $pink !important;
        }
    }
    input {
        margin-right: 0.5rem !important;
    }
    .adyen-checkout__dropdown__button__icon {
        margin-left: 8px !important;
    }
}
.adyen-checkout__dropdown__button--active,
.adyen-checkout__dropdown__button--active:hover,
.adyen-checkout__dropdown__button:active,
.adyen-checkout__dropdown__button:focus {
    box-shadow: none !important;
}
.adyen-checkout__dropdown__list {
    background: $very-dark-1 !important;
    border-left: none !important;
    border-bottom: none !important;
    border-right: none !important;
    border-image-slice: 1 !important;
    z-index: 1050 !important;
    @include pink-purple-border-gradient-lr;
    border-top: 1px solid transparent !important;

    .adyen-checkout__dropdown__element {
        color: $light !important;
    }
}

#encryptedCardNumber:placeholder-shown {
    background: $very-dark-1 !important;
}
.adyen-checkout__card__form {
    .input-field:placeholder-shown,
    .chckt-input-field--error,
    .chckt-input-field--validated {
        background: $very-dark-1 !important;
    }
}
.adyen-checkout__dropdown__list::-webkit-scrollbar {
    width: 6px;
    top: 2px;
}

.adyen-checkout__dropdown__list::-webkit-scrollbar-track {
    background: $very-dark-2;
}

.adyen-checkout__dropdown__list::-webkit-scrollbar-thumb {
    background-color: $grey-dark;
    border-radius: 20px; /* roundness of the scroll thumb */
}

.adyen-checkout__card-input {
    &--credit {
        max-width: 250px;
    }
}

.amgdpr-text a {
    font-size: initial;
}
